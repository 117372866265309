import { React, useState, useContext, useEffect } from 'react';
import "./PaymentMethod.css"
import axios from "axios";
import logger from "../../lib/util";
import AppContext from '../Data/AppContext';
import {NotificationManager} from 'react-notifications';
const PaymentMethod = (props) => {
const glContext = useContext(AppContext);
let lcash = false;
let lcard = false;
let lpaid = 'unpaid';
let lpaidBool = false;
const saveButton = props.saveButton;
const { setPaymentFlag } = props;
const paymentFlag = props.paymentFlag;
const activeOrder = props.activeOrder;
const {setActiveOrder} = props;
const onlineOrder = (activeOrder.origin === 'online');

if (activeOrder && activeOrder.payment_method === 'cash') {
    lcash = true;
    lcard = false;
    lpaid = 'paid';
    lpaidBool = true;
}
else if (activeOrder && activeOrder.payment_method === 'card') {
    lcash = false;
    lcard = true;
    lpaid = 'paid';
    lpaidBool = true;
}

const [paid, setPaid] = useState(lpaid);
const [paidBool, setPaidBool] = useState(lpaidBool);
const [card, setCard] = useState(lcard);
const [cash, setCash] = useState(lcash);

useEffect(() => {    
    if (activeOrder && activeOrder.payment_method === 'cash') {
        setCash(true);
        setCard(false);
        setPaid('paid');
        setPaidBool(true);
    }
    else if (activeOrder && activeOrder.payment_method === 'card') {
        setCash(false);
        setCard(true);
        setPaid('paid');
        setPaidBool(true);
    } else {
        setCash(false);
        setCard(false);
        setPaid('unpaid');
        setPaidBool(false);
    }
}, paymentFlag);

const clickCard = () => {
    handleSave('card');
};
const clickCash = () => {
    handleSave('cash');
};
const clickPaid = () => {
    handleSave('payment');
};

const handleSave = (val) => {
    if (onlineOrder){
        NotificationManager.warning('Sorry, can not update online orders payment');
        return;
    }
    if (saveButton === 'Print') {
        savePaymenttoAPI(val);
    } else {
        saveOrdersBeforePayment(val);
    }
}

const  saveOrdersBeforePayment=async (val)=>{
    if (onlineOrder){
        NotificationManager.warning('Sorry, can not update online orders payment');
        return;
    }
    let url = process.env.REACT_APP_SAVE_ORDER+ glContext.RestaurantId+ '&id='+ glContext.ActiveOrder.id;
    try {
        const response =  await axios.patch(url,JSON.stringify(glContext.ActiveOrder.order_lines),{
            headers: {
            'Access-Control-Allow-Origin': true,
            "Content-Type": "multipart/form-data",
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
            },
            }).catch(err => {
            if (err.response.status === 404) {
            throw new Error(`${err.config.url} not found`);
            }
            throw err;
        });
    
        if(response.status===200)
        {
            logger.info('Save Success');
            logger.info(response);
            glContext.setActiveOrder(response.data.result.order);
            setActiveOrder(response.data.result.order);
            glContext.setDirty(false);
            savePaymenttoAPI(val);
        }else{
            
        }
    } catch (error) {
        logger.info('error');
        logger.info(error);
    
    }
}

const savePaymenttoAPI = async (event) => {
    if (onlineOrder){
        NotificationManager.warning('Sorry, can not update online orders payment');
        return;
    }
    let url = process.env.REACT_APP_DELETE_ORDER + glContext.RestaurantId + '&id=' + glContext.ActiveOrder.id;
    let error;
    try {
        let method = '';
        let status = 'paid';
        if (event === 'card') {
            if (card) {
                method = '';
                status = 'unpaid';
            }
            else {
                method = 'card';
                status = 'paid';
            }
        }
        if (event === 'cash') {
            if (cash) {
                method = '';
                status = 'unpaid';
            }
            else {
                method = 'cash';
                status = 'paid';
            }
        }
        if (event === 'payment') {
            method = '';
            status = 'unpaid';
        }
        let data = { payment_method: method, payment_status: status };
        const response = await axios.patch(url, JSON.stringify(data), {
            headers: {
                'Access-Control-Allow-Origin': true,
                "Content-Type": "multipart/form-data",
                'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
            },
        }).catch(err => {
            if (err.response.status === 404) {
                throw new Error(`${err.config.url} not found`);
            }
            throw err;
        });

        if (response.status === 200) {
            glContext.setActiveOrder(response.data.result.order);
            setActiveOrder(response.data.result.order);
            if (data.payment_method === 'cash') {
                setCash(true);
                setCard(false);
                setPaid('paid');
                setPaidBool(true);
            }
            else if (data.payment_method === 'card') {
                setCash(false);
                setCard(true);
                setPaid('paid');
                setPaidBool(true);
            }
            else {
                setCash(false);
                setCard(false);
                setPaid('unPaid');
                setPaidBool(false);
            }
            if (paymentFlag) {
                setPaymentFlag(false);
            } else {
                setPaymentFlag(true);
            }
        } else {
            alert(JSON.stringify(response));
        }
    } catch (err) {
        error = err;
        logger.info('error');
        logger.info(error);
    }
}

return (
    <div className="payment-method-panel">
        {!onlineOrder && 
        <div>
        <label className="switch">
            <input type="checkbox" checked={card} onChange={clickCard} />
            <span className="slider round"></span>
        </label><label>Card</label>
        <label className="switch">
            <input type="checkbox" checked={cash} onChange={clickCash} />
            <span className="slider round"></span>
        </label><label>Cash</label>
        <label className="switch">
            <input type="checkbox" checked={paidBool} onChange={clickPaid} />
            <span className="slider round"></span>
        </label><label >{paid}</label>
        </div>}
    </div>
);
}

export default PaymentMethod;
