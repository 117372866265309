import React from "react";
import { tutorialData } from "./tutorialData";
import Tutorials from "./Tutorials";
// import AppContext from '../Data/AppContext';
require('dotenv').config();

export default function Instructions() {   
    // const glContext = useContext(AppContext);
    // const {setOpen, setInfo} = glContext;

    // const handleClick = (tutorial) => {
    //     setInfo(tutorial);
    //     setOpen(true);
    // }

    return (
        <>
            <div className="col-md-12 text-left">
                <div className="py-4">
                    <h5>What to do if my Caller ID is not working?</h5>
                    <div className="margin-bottom-10">
                        <ol>
                            {
                                tutorialData?.map((tutorial, i) => 
                                    <li 
                                        key={tutorial.id} 
                                        // onClick={() => handleClick(tutorial)}
                                        style={{margin: "15px 0"}} 
                                    >
                                        {tutorial.title}
                                    </li> 
                                )
                            }
                        </ol>
                    </div>
                </div>
            </div>

            <Tutorials />
        </>
    );
}