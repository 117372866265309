import React from 'react';
import * as IoIcons from 'react-icons/io';
import { Link } from 'react-router-dom';
import './Navbar.css';

import { IconContext } from 'react-icons';

function Navbar(props) {
  const restId = props.restId;
  const loggedIn = props.loggedin;  
  var data = [];
  

  if ((restId === null || restId === '') && loggedIn) {
    data = [{
      title: 'Home',
      path: '/Home',
      icon: <IoIcons.IoMdHome />,
      cName: 'nav-text'
    },
    {
      title: 'Manage',
      path: '/manage',
      icon: <IoIcons.IoMdSettings />,
      cName: 'nav-text'
    }];
  } else if (restId !== null && restId !== '' && loggedIn) {
    data = [{
      title: 'Home',
      path: '/Home',
      icon: <IoIcons.IoMdHome />,
      cName: 'nav-text'
    },
    {
      title: 'NewOrder',
      path: '/neworder',
      icon: <IoIcons.IoMdBasket />,
      cName: 'nav-text d-none d-md-block'
    },
    {
      title: 'Bookings',
      path: '/bookings',
      icon: <IoIcons.IoMdPeople />,
      cName: 'nav-text',
      noOfBookings: Number(props.numOfNewBookingToday) > 0 ? Number(props.numOfNewBookingToday) : null,
    },
    {
      title: 'Manage',
      path: '/manage',
      icon: <IoIcons.IoMdSettings />,
      cName: 'nav-text'
    }];
  } else {
    data = [{
      title: 'Home',
      path: '/Home',
      icon: <IoIcons.IoMdHome />,
      cName: 'nav-text'
    }];
  }
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={data ? 'nav-menu active row' : 'nav-menu row'}>
          <div className='nav-menu-item' /*onClick={showSidebar}*/>
            {data.map((item, index) => {
              return (
                <div key={index} className={item.cName} title={item.title}>
                  <Link to={item.path} className='nav-item'>
                    {item.icon}
                    {item.noOfBookings && <span className='badge badge-pill badge-danger nav-feature'>{item.noOfBookings}</span>}
                  </Link>
                </div>
              );
            })}
           {props.newCall && 
            <Link to='/neworder' className='nav-call-item' >
               <IoIcons.IoMdCall />
                  </Link>
           } 
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
