import React from 'react';
import styles from './ServiceCharge.module.css'

const ServiceChargeCard = ({ heading, title, symbol, message, handleChange, data, name, min, max, step }) => {
  return (
    <>
        {
            heading ? (
                <div className="col-md-4 pb-3 border">
                    <h5 className="mt-2 text-center">{heading}</h5>
                    <hr />
                    <div className="row align-items-center py-2">
                        <div className="col-md-12 col-lg-8">
                            <span>{title} ({symbol})</span>
                        </div>
                        <div className="col-md-12 col-lg-4">
                        <input 
                            name={name}
                            type="number"
                            min={min}
                            max={max}
                            step={step}
                            className={message ? `${styles.form_control}` : 'form-control'}
                            defaultValue={data}
                            onChange={handleChange}
                        />
                        </div>
                    </div>
                    {message && <p style={{fontSize: "14px"}} className='text-danger text-right font-weight-bold'>{message}</p>}
                </div>
            ) : (
                <>
                    <div className="row align-items-center py-2">
                        <div className="col-md-12 col-lg-8">
                            <span>{title} ({symbol})</span>
                        </div>
                        <div className="col-md-12 col-lg-4">
                        <input 
                            name={name}
                            type="number"
                            min={min}
                            max={max}
                            step={step}
                            className={message ? `${styles.form_control}` : 'form-control'}
                            defaultValue={data}
                            onChange={handleChange}
                        />
                        </div>
                    </div>
                    {message && <p style={{fontSize: "14px"}} className='text-danger text-right font-weight-bold'>{message}</p>}
                </>
            )
        }
    </>
  );
};

export default React.memo(ServiceChargeCard);
