import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import OrderForm from '../Forms/OrderForm'
import PropTypes from 'prop-types';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import './FormDialog.css'

export default function FormDialog(props) {
  FormDialog.propTypes = {
    showDetailsDialog: PropTypes.bool
  };
  // const showDetailsDialog= props;
  const {setShowDetailsDialog} = props;
  const [open] = React.useState(true);
  // const {  formState: { errors } } = useForm();
  const activeOrder = props.activeOrder;
  const {setActiveOrder} = props;
  
  const handleClose = () => {
    setShowDetailsDialog(false);
  };

  return (
      <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
        <div className="row ">
                <span className="col-10"></span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
        </div>
        <DialogContent >
        <PaymentMethod  activeOrder={activeOrder} setActiveOrder={setActiveOrder} />
        <OrderForm newOrder={false} handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
  );
}
