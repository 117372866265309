import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import './FormDialog.css'

export default function ConfirmDialog(props) {
    ConfirmDialog.propTypes = {
        confirmText: PropTypes.string
      };
   
    const confirmText = props.confirmText;
    const { setShowConfirmDialog } = props;
    const { handleDeleteClick } = props;
    const [open] = React.useState(true);
    const {handleSubmit } = useForm();
    const onSubmit = async () => {
        handleDeleteClick();
    }
    const handleClose = () => {
        setShowConfirmDialog(false);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Confirmation</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent >
                <p>{confirmText}</p>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 btn-group">
                        <button type="reset" onClick={handleClose} className="btn btn-light btn-lg form-control">
                            No
                        </button>
                        <button type="submit" className="submitButton btn btn-primary btn-lg form-control">
                            Yes
                        </button>
                        </div>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}
