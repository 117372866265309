import React, {useContext } from "react";
import "bootstrap/dist/css/bootstrap.css"
import { useHistory } from "react-router-dom";
import AppContext from "../Data/AppContext";
require('dotenv').config();

export default function LogoutButton(props) {
  const glContext = useContext(AppContext);
  const history = useHistory();
  var user = localStorage.getItem('user');
  const handleLogoutButtonClick=() => {
    localStorage.removeItem("allRestaurants");
    localStorage.removeItem("restaurant");
    localStorage.removeItem("restaurantId");
    localStorage.removeItem("numOfNewBooking");
    localStorage.removeItem("user");
    glContext.setLoggedin(false);
    glContext.setRestaurantId('');
    glContext.setRestaurantId('');
    glContext.setUser({});
    glContext.setRestaurant({});
    glContext.setOrders([]);
    glContext.setActiveOrder({});
     props.refreshRestaurant();
    /* TODO - should this be something like this ?

    localStorage.removeItem("allRestaurants");
    localStorage.removeItem("restaurantId");
    localStorage.removeItem("restaurant");
    localStorage.removeItem("user");
    glContext.setLoggedin(false);
    glContext.setRestaurantId('');
    glContext.setUser({});
    glContext.setRestaurant({});
    glContext.setAllRestaurants([]]);
    */
    history.push("/manage");
  }
  return (
    <span>{(user) &&  <button className="btn btn-lg btn-warning settingsBtn" onClick={handleLogoutButtonClick}> Logout</button>}</span>
  );
}