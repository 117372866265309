import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import useGetUsers from "../../services/useGetUsers";
import AppContext from '../Data/AppContext'
import axios from "axios";
import Dropdown from 'react-dropdown';
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
import DatePicker from "react-datepicker";
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css';
import useHelperService from "../../services/useHelperService";
import 'react-clock/dist/Clock.css';
import './FormDialog.css'
import "react-datepicker/dist/react-datepicker.css";

export default function BookingDialog(props) {
  const glContext = useContext(AppContext);
  const timeoptions = useHelperService('booking');
  var activeName = '';
  var activeMobile = '';
  var activeTime = '';
  var activeDate = '';
  var activeTable = '';
  var activeNoOfPeople = '';
  var activeInstr = '';
  var activeDatetime = new Date();
  var bookingId = 0;
  var minDate = new Date();
  var maxDate = new Date();
  var found = null;
  maxDate.setDate(minDate.getDate() + 30);
  if (props.activeBooking) {
    bookingId = props.activeBooking.id;
    activeName = props.activeBooking.customer_name;
    activeMobile = props.activeBooking.customer_mobile.replace('+44', "0");;
    activeTime = props.activeBooking.time;
    activeDate = props.activeBooking.date;
    activeTable = props.activeBooking.table_no;
    activeInstr = props.activeBooking.instruction;
    activeNoOfPeople = props.activeBooking.no_of_people;
    activeDatetime = new Date(Date.parse(activeDate + ' ' + activeTime));
  }

  let defaultOption='';
  if(timeoptions && timeoptions.length>0)
  {
    defaultOption = timeoptions[0].value;
   
  }
  
  let tempTime = new Date(activeDatetime);
  if(activeTime!==''){   
    tempTime = (tempTime.getHours() <10 ? ('0'+tempTime.getHours()) : tempTime.getHours())
      + ":" + (tempTime.getMinutes() <10 ? ('0'+tempTime.getMinutes()) : tempTime.getMinutes());

    found = timeoptions.filter(function(e) {
      return e.value === tempTime;
    });

    if (found.length>0) {
      defaultOption = found[0].value;
    }else{
      timeoptions.unshift(tempTime);
      defaultOption = tempTime;
    }
  }

  if(activeTime!==''){
    activeTime =tempTime;
  }else{
    activeTime =timeoptions[0].value;    
  }
  
  const { postData } = useLogErrortoAPI();
  const [id] = useState(bookingId);
  const { setShowBookingsDialog } = props;
  const [open] = useState(true);
  const [name, setName] = useState(activeName);
  const [mobile, setMobile] = useState(activeMobile);
  const [table, setTable] = useState(activeTable);
  const [date, setDate] = useState(activeDate);
  const [time, setTime] = useState(activeTime);
  const [validationMessage, setValidationMessage] = useState('');
  const [noofPeople, setNoofPeople] = useState(activeNoOfPeople);
  const [instructions, setInstructions] = useState(activeInstr);
  const [selectedDate, setSelectedDate] = useState(activeDatetime);
  // const {  } = useForm();
  const { usersList, doFetch } = useGetUsers([]);
  var rx = new RegExp(/^\d+$/);
  const [showUsers, setShowUsers] = useState();
  const onTimeSelect = (e) => {
    setTime(e.value);
  }
  useEffect(() => {
    if (!props.activeBooking) {
    handleDate();
     //handleTime();
    }    
  });

  const handleOnSelect = (item) => {
    setMobile(item.mobile);
    setName(item.name);
    setShowUsers(false);
  }

  const handleMobileChange = (value) => {
    if ((value.length <= 11 && rx.test(value) && !value.indexOf('.') > -1) || value === '') {
      setMobile(value);
      if (value.length > 4) {
        doFetch(value);
        setShowUsers(true);
      }
    }
  }

  function validate() {
    if (!name) {
      setValidationMessage('Customer Name is required');
      return;
    }
    if (!mobile) {
      setValidationMessage('Customer Phone is required');
      return;
    }
    if (!noofPeople) {
      setValidationMessage('Please enter the number of guests');
      return;
    }
    setValidationMessage('');

    if (!props.activeBooking) {
      saveNewBooking();
    } else {
      updateBooking();
    }
  }

  function handleNameChange(value) {
    setName(value);
  }

  function handleTableChange(value) {
    if ((value.length <= 3 && rx.test(value) && !value.indexOf('.') > -1) || value === '') {
      setTable(value);
    }
  }

  function handleNumberofPeopleChange(value) {
    if ((value.length <= 3 && rx.test(value) && !value.indexOf('.') > -1) || value === '') {
      setNoofPeople(value);
    }
  }

  function handleDate(val) {
    if(val){
      setSelectedDate(val);
      var year = val.getFullYear();
      var month = val.getMonth() + 1;
      var day = val.getDate();
      if (month.toString().length < 2){
        month = '0' + month;
      }
      if (day.toString().length < 2){
        day = '0' + day;
      }
      var d = year + '-' + month + '-' + day;
      setDate(d);
    }
  }

  function handleInstructionChange(value) {
    setInstructions(value);
  }

  const handleClose = () => {
    setShowBookingsDialog(false);
  };

  const handeSubmitClick = async () => {
    //handleDate();
    //handleTime();
    validate();
  }

  const updateBooking = async () => {
    var strJson = { 
      'date': date,
      'time': time, 
      'no_of_people': noofPeople, 
      'instruction': instructions, 
      'table_no': table 
    }
    let url = process.env.REACT_APP_CREATE_BOOKING + glContext.RestaurantId + "&id=" + id;
    await axios.patch(url, strJson, {
      headers: {
        'Access-Control-Allow-Origin': true,
        "Content-Type": "multipart/form-data",
        'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
      },
    }).then((response)=> {
      if (response.status === 200) {
        props.refetchBookings();
        setShowBookingsDialog(false);
      } else {
        postData('Error code #BDJ-105' + response, 'BookingDialog', 'UpdateBooking');
      }
    }).catch(err => {
      if (err.response !== undefined
        && err.response.data !== undefined
        && err.response.data.errors !== undefined) {
        setValidationMessage( err.response.data.errors.join(', '));          
      } else {
        postData('An unknown Error occured Error code #BDJ-107', 'BookingDialog.js', 'handeSubmitClick');
      }
    });
  }

  const saveNewBooking = async () => {
    let url = process.env.REACT_APP_CREATE_BOOKING + glContext.RestaurantId;    
    var bodyFormData = new FormData();
    bodyFormData.append('instruction', instructions);
    bodyFormData.append('customer_mobile', mobile);
    bodyFormData.append('customer_name', name);
    bodyFormData.append('table_no', table);
    bodyFormData.append('date', date);
    bodyFormData.append('time', time);
    bodyFormData.append('no_of_people', noofPeople);
    await axios.post(url, bodyFormData, {
      headers: {
        'Access-Control-Allow-Origin': true,
        "Content-Type": "multipart/form-data",
        'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
      },
    }).then((response)=> {
      if (response.status === 200) {
        props.refetchBookings();
        setShowBookingsDialog(false);
      } else {
        postData('Error code #BDJ-102' + response, 'BookingDialog', 'UpdateBooking');
      }
    }).catch(err => {
      if (err.response !== undefined
        && err.response.data !== undefined
        && err.response.data.errors !== undefined) {
        setValidationMessage( err.response.data.errors.join(', '));          
      } else {
        postData('An unknown Error occured Error code #BDJ-103', 'BookingDialog.js', 'handeSubmitClick');
      }
    });
  }
  
  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
      <div className="row dialogHeader">
          <span className="col-10">Booking Details</span>
          <span className="text-right col-2">
              <button className="btn" onClick={handleClose} >X</button>
          </span>
      </div>
      <DialogContent >
        <div className="eatinFormContainer">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="mobile">Customer Phone Number</label>
              <div>
                <input id="mobile" disabled={props.activeBooking} autoFocus="autofocus" tabIndex="0" className="form-control" value={mobile} onChange={e => handleMobileChange(e.target.value)} autoComplete="off"/>
                <div className="matchedCustomers ">
                  {
                    showUsers && <p>{usersList.length} customers found, tap to select</p>
                  }

                  {(usersList && showUsers) &&
                    Object.entries(usersList)
                      .map(([key, value]) =>
                        <div className="row singleCustomerRowInForm clickable" key={key} onClick={() => handleOnSelect(value)} >
                          <div className="col-md-6">{value.mobile}</div>
                          <div className="col-md-6">{value.name}</div>
                        </div>
                      )
                  }
                </div>

              </div>
              <label htmlFor="name">Customer Name</label>
              <input id="name" disabled={props.activeBooking} className="form-control" tabIndex="0" type="text" value={name} onChange={e => handleNameChange(e.target.value)} autoComplete="off"/>
              <label htmlFor="instructions">Notes <span className="small">(optional)</span></label>
              <textarea className="form-control" tabIndex="0" rows="3" value={instructions} onChange={e => handleInstructionChange(e.target.value)} />
            </div>
            <div className="col-md-6">
            <label htmlFor="date">Date</label>
              <DatePicker className="form-control" 
              minDate={new Date()} 
              dateFormat="dd/MM/yyyy" 
              startDate={new Date()} 
              selected={selectedDate} 
              onChange={(date) => handleDate(date)} />

            <label htmlFor="date">Time</label>
              <Dropdown options={timeoptions} 
              onChange={onTimeSelect} 
              value={defaultOption} 
              placeholder="Select an option" />

            <label htmlFor="numberofpeople">Number of Guests</label>
              <input className="form-control" tabIndex="0" value={noofPeople} onChange={e => handleNumberofPeopleChange(e.target.value)} type="number" min="1" step="1" required/>
            
            <label htmlFor="date">Table Number <span className="small">(optional)</span></label>
              <input className="form-control" tabIndex="0" value={table} onChange={e => handleTableChange(e.target.value)} type="number" min="0" step="1" />
            </div>
          </div>
          <div className="row">
            <div className="formErrors"></div>
          </div>
          <div className="row">
            <p className="text-center col-12 alert-warning">{validationMessage}</p>
            <div className="col-12 btn-group">
              <button tabIndex="0" className="btn btn-light form-control" onClick={handleClose} >Cancel</button>
              <button className="btn btn-primary form-control" onClick={handeSubmitClick}> Save</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
