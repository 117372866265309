import {React}from "react";
import "./../Order/Order.css"

export default function BookingCard(props) {
  let leftBorder ='10px solid violet'; // eatin
  if(props.data.status !== 'queued' && props.data.status !== 'confirmed')
  {
    leftBorder ='10px solid red';
  }

  const handleDetailsOpen = () => {
    props.setActiveBooking(props.data);
    props.openBooking();
  };

  return (
    <div id={props.data.id} style={{borderLeft: leftBorder}}  className="bookingCard" >
      <div className="row orderCardRow" >
        <div className="col-12 text-left" onClick={() => handleDetailsOpen(props.data.id)}>
          <div className="">{props.data.date} @ {props.data.time} || {'Guests ' + props.data.no_of_people + (props.data.table_no ? ' || Table ' + props.data.table_no : '') }</div>
          <div className="small">{props.data.customer_mobile} - {props.data.customer_name}</div>
          <div className="small">{props.data.instruction}</div>
          {(props.data.status !== 'queued') && <div className="small">{props.data.status}</div>}
        </div>
      </div>
    </div>
  );
}