import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import axios from "axios";
import AppContext from '../Data/AppContext'

export default function DiscountSettingDialog(props) {
    const glContext = useContext(AppContext);
    const { setShowDiscountDialog } = props;
    const [open] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    var val =0;
    if(props.discounts.DiscountType ==='collection')
    {
        val = props.discounts.CollectionDiscount;
    }
    if(props.discounts.DiscountType==='cashcollection')
    {
        val = props.discounts.CashCollectionDiscount;
    }
    if(props.discounts.DiscountType==='all')
    {
        val = props.discounts.AllDiscount;
    }
    const [percent, setPercent] = React.useState(val);
    var reg = /^-?\d+\.?\d*$/;
    const handleClose = () => {
        setShowDiscountDialog(false);
    };
    
    const processPercentageChange = (value) => {
        if (value.length === 0 || reg.test(value) === true) {
            if (value < 100) {
                setPercent(value);
            }
        }
    }

    const handleSave = async () => {
        let url = process.env.REACT_APP_UPDATE_RESTAURANT + glContext.RestaurantId;
        if(props.discounts.DiscountType==='all')
        {
            var json = {o_o_discount: percent}
        }
        if(props.discounts.DiscountType==='collection')
        {
            var json = {o_o_collection_discount: percent}
        }
        if(props.discounts.DiscountType==='cashcollection')
        {
            var json = {o_o_cash_collection_discount: percent}
        }
        let error;
        setLoading(true);
        try {
            const response = await axios.patch(url, json, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });

            if (response.status === 200) {
                console.log('Save Success');
                if(props.discounts.DiscountType==='all')
                {
                    props.discounts.setAllDiscount(percent);
                }
                if(props.discounts.DiscountType==='collection')
                {
                    props.discounts.setCollectionDiscount(percent);
                }
                if(props.discounts.DiscountType==='cashcollection')
                {
                    props.discounts.setCashCollectionDiscount(percent);
                }

                localStorage.removeItem("restaurant");
                localStorage.setItem('restaurant', JSON.stringify(response.data.result));
                glContext.setRestaurant(response.data.result);
                setShowDiscountDialog(false);
            } else {
                console.log('Save Failed');
            }
        } catch (err) {
            error = err;
            console.log('error');
            console.log(error);
        }
        setLoading(false);
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Change Discount</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <div className="row">
                    <div className="col-md-12">
                        In percent (% of subtotal amount)<input className="form-control" onChange={e => processPercentageChange(e.target.value)} placeholder="0" value={percent} type="number" />
                    </div>
                </div>
                <p></p>
                <div className="row">
                    <div className="col-md-12 btn-group">
                        <button type="reset" disabled={loading} style={{ cursor: loading ? "not-allowed" : null}}  onClick={handleClose} className="btn btn-light btn-lg form-control">
                            Cancel
                        </button>
                        <button type="submit" disabled={loading} style={{ cursor: loading ? "not-allowed" : null}}  onClick={handleSave} className="btn btn-primary btn-lg form-control submitButton">
                            {loading ? 'Saving' : 'Save'}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
