import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import logger from "../../lib/util";
import axios from "axios";
import AppContext from '../Data/AppContext'

export default function AddMenuDialog(props) {
    const glContext = useContext(AppContext);
    const { setShowRejectReasonDialog } = props;
    const [open] = React.useState(true);
    const rejectId= props.rejectId;
    // const {  } = useForm();
  
    const handleClose = () => {
      props.refreshOrders(!props.refresh);
        setShowRejectReasonDialog(false);
    };


    const rejectOrder = async (reason)=>{
         let url = process.env.REACT_APP_UPDATE_SPECIAL+ glContext.RestaurantId+ '&id='+ rejectId;
         logger.info('url');
         logger.info(url);
         let error;
         try {
           //let data  =glContext.ActiveOrder; 
           const response =  await axios.patch(url,{reject:reason},{
              headers: {
                  'Access-Control-Allow-Origin': true,
                  "Content-Type": "multipart/form-data",
                  'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
              },
             }).catch(err => {
             if (err.response.status === 404) {
               throw new Error(`${err.config.url} not found`);
             }
             throw err;
           });
     
           if(response.status===200)
           {
             logger.info('Reject Success');
             logger.info(response);
             props.refetchOrders();
             setShowRejectReasonDialog(false);
             //history.push("/Home"); 
           }else{
            logger.info('Failed');
            logger.info(response.status);
           }
         } catch (err) {
           error = err;
           logger.info('error');
           logger.info(error);
         }
    }
    
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Reject Reason</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
            <div className="row">
                <div className="col-md-12 btn-group reject-reason-row">
                  <button className="btn btn-secondary" onClick={()=>{rejectOrder('FOOD_UNAVAILABLE')}}  >No Food</button>
                  <button className="btn btn-light"  onClick={()=>{rejectOrder('TIME_UNAVAILABLE')}}  >No Time</button>
                  <button className="btn btn-secondary" onClick={()=>{rejectOrder('TOO_BUSY')}} >Too Busy</button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 btn-group reject-reason-row">
                  <button className="btn btn-light" onClick={()=>{rejectOrder('UNABLE_TO_DELIVER')}} >Can't Deliver</button>
                  <button className="btn btn-secondary" onClick={()=>{rejectOrder('DONT_DELIVER_TO_AREA')}}>Can't Deliver to location</button>
                </div>
              </div>
            </DialogContent>
        </Dialog>
    );
}
