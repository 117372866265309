import { useState, useEffect, React, useContext } from 'react';
import './Orders.css'
import Order from "../Order/Order"
import { Row } from 'react-bootstrap';
import OnlineCard from '../OnlineCard/OnlineCard';
import AppContext from '../Data/AppContext';
import useGetOrders from '../../services/useGetOrders';
import RejectReasonDialig from "../Dialog/RejectReasonDialog";
import { useHistory } from "react-router-dom";
import NewCallDialog from "../Dialog/NewCallDialog";
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Orders = (props) => {
  const history = useHistory();
  const glContext = useContext(AppContext);
  //const [showNewCallDialog, setShowNewCallDialog]=useState(props.newcall);
  const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
  const [rejectId, setRejectId] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [viewHiddenOrders, setViewHiddenOrders] = useState(false);
  const [viewHiddenOrdersText, setViewHiddenOrdersText] = useState('View Hidden Orders');
  var rest = localStorage.getItem("restaurant");
  var currentRestaurant = JSON.parse(rest);
  
  const { ordersList, doFetch } = useGetOrders("");
  const handleVIewHiddenOrders = () => {
    setViewHiddenOrders(!viewHiddenOrders);
    if (viewHiddenOrdersText === 'View Hidden Orders') {
      setViewHiddenOrdersText('Hide Hidden Orders');
    } else {
      setViewHiddenOrdersText('View Hidden Orders');
    }
  }

  const gotoLoginPage=()=>{
    history.push("/manage"); 
  }

  var url = null;
  const refetchOrders=()=>{
    if(glContext.Loggedin && glContext.RestaurantId){
      NotificationManager.success('Synching Orders', null, 500);
      let r = Math.random().toString(36).substring(7);
      url= process.env.REACT_APP_GET_ORDERS + glContext.RestaurantId;
      doFetch(url, r);
      props.refetchOnlineOrders();
    }else{
      var rest = localStorage.getItem("restaurant");
      if(rest){
        var currentRestaurant = JSON.parse(rest);
        let r = Math.random().toString(36).substring(7);
        url= process.env.REACT_APP_GET_ORDERS + currentRestaurant.id;
        glContext.setRestaurantId(currentRestaurant.id);
        doFetch(url, r);
        props.refetchOnlineOrders();
      }
     
    }
  }

  useEffect(() => {
    try {
      refetchOrders();
      const interval = setInterval(() => {
        refetchOrders();
      }, 1200000);

      return () => clearInterval(interval);
    } catch {

    }
  },[glContext.RestaurantId]);

  return (
    <Row>
      <NotificationContainer />
      {props.newCall && <NewCallDialog callingNumber={props.callingNumber}  setCallingNumber={props.setCallingNumber} newCall={props.newCall}  postcode={1234}  setNewCall={props.setNewCall}/>}  
      {showRejectReasonDialog && <RejectReasonDialig rejectId={rejectId} refetchOrders={refetchOrders} refresh={refresh} showRejectReasonDialog={showRejectReasonDialog} setShowRejectReasonDialog={setShowRejectReasonDialog} />}
      <div className="col-md-8 margin-top-5" id="orderList">
      {(!glContext.Loggedin ) && 
          <div>
            <h5>Please click <button className="btn btn-sm btn-primary settingsBtn"  onClick={gotoLoginPage}>Here</button> to login</h5>
          </div>
        }
        {(ordersList.length === 0 && glContext.Loggedin ) && 
          <div>
            <div className="col-md-12 text-left margin-top-10" >
              {currentRestaurant && <h2>{currentRestaurant.name} EPOS</h2>}
              {currentRestaurant && <h5>No pending orders</h5>}
              {!currentRestaurant && <h5>Please select a restaurant <button className="btn btn-sm btn-primary settingsBtn" onClick={gotoLoginPage}>here</button></h5>}
            </div>
          </div>
        }
        { glContext.Loggedin &&
          Object.entries(props.onlineOrdersList)
            .map(([key, value]) =>
              ((value.print_status === 'submitted' && value.origin === 'online') && <OnlineCard  refreshOrders={refetchOrders} setShowRejectReasonDialog={setShowRejectReasonDialog} setRefresh={setRefresh} refresh={refresh} key={value.id} data={value} setRejectId={setRejectId} />))
        }
        { glContext.Loggedin &&
          Object.entries(ordersList)
            .map(([key, value]) =>
            (((value.status !== 'cancelled' && value.origin === 'epos')
              || (value.origin === 'online' && value.print_status !== 'submitted')) && <Order refetchOrders={refetchOrders} viewHiddenOrders={viewHiddenOrders} key={value.id} data={value} />))
        }
      </div>
      
      {(ordersList.length>0 && glContext.Loggedin) &&
      <div className="col-md-12 text-left margin-top-10" >
        <button className="btn btn-light" onClick={handleVIewHiddenOrders} >{viewHiddenOrdersText}</button>
      </div>}
    </Row>

  );
}

export default Orders;
