// process.env.REACT_APP_* variables are 
// defined in .env and can be overridden in .env.local

// Authorization screen base URL
// e.g. https://developer.github.com/apps/building-oauth-apps/authorizing-oauth-apps/#1-request-a-users-github-identity
export const authorizationUrl = process.env.REACT_APP_OAUTH_AUTH_URL;

// To get a client ID, create an app, e.g.
// GitHub (authorization code grant only): https://github.com/settings/developers
// Spotify (implicit grant & auth code): https://developer.spotify.com/dashboard/applications
export const clientId = process.env.REACT_APP_OAUTH_CLIENTID;

// You get to configure this in your OAuth settings
// If you use React Router, the relative path (empty here) can match
// that of a route which displays nothing
export const redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI;
