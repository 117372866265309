import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import useLogErrortoAPI from "../../services/useLogErrortoAPI";
// import { useForm } from "react-hook-form";
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import AppContext from '../Data/AppContext'

export default function DiscountDialog(props) {
    const glContext = useContext(AppContext);
    // const order = props.order;
    const {setActiveOrder} = props;  
    const { setShowDiscountDialog } = props;
    const [open] = React.useState(true);
    const [amount, setAmount] = React.useState(props.discount);
    const [percent, setPercent] = React.useState(0);
    const [subTotal] =  React.useState(props.subtotal);
    var reg = /^-?\d+\.?\d*$/;
    // const {  } = useForm();
    // const {postData}=useLogErrortoAPI();
    
    const handleClose = () => {
        setShowDiscountDialog(false);
    };

    const processAmountChange = (value) => {
        if (value.length === 0 || reg.test(value) === true) {
            setAmount(value)
        }
    }

    const processPercentageChange = (value) => {
        if (value.length === 0 || reg.test(value) === true) {
            if(value<100){
                setPercent(value);
                var total_discount = subTotal * (value / 100);
                var val= total_discount.toFixed(2);
                setAmount(val);
            }
          
        }
    }
    
     const handleSave= async ()=>{
        let url = process.env.REACT_APP_UPDATE_SPECIAL+ glContext.RestaurantId+ '&id='+ glContext.ActiveOrder.id;
        try {
            const response =  await axios.patch(url, {'total_discount': amount}, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
                }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });
            
            if(response.status === 200
                && response.data !== null
                && response.data.result !== null
                && response.data.result.order !== null){
                    var ord = response.data.result.order;
                    glContext.setActiveOrder(ord);
                    setAmount(ord.total_discount);
                    props.setDiscount(ord.total_discount);
                    glContext.setDirty(true);
                    glContext.setDirty(false);
                    setActiveOrder(ord);
                    setShowDiscountDialog(false);
            }else{
                NotificationManager.error('Failed to save discount', 'Failed to save', 3000, () => {});
            }
        } catch (err) {
            NotificationManager.error('Failed to save discount 2', 'Failed to save', 3000, () => {});
        }
     }
    
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Change Discount</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <p>Change the discount amount here. Please note that if you change the order after this the discount amount might change, so it is a good idea to edit this discount after you have completed the order.</p>
                <div className="row">
                    <div className="col-md-12">
                        Amount (£)<input className="form-control" placeholder="0" onChange={e => processAmountChange(e.target.value)} value={amount} type="number" min="0" step=".5" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>OR</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        In percent (% of subtotal amount)<input className="form-control" onChange={e => processPercentageChange(e.target.value)} placeholder="0" value={percent} type="number" min="0" step="1" />
                    </div>
                </div>
                <p></p>
                <div className="row">
                    <div className="col-md-12 btn-group">
                    <button type="reset" onClick={handleClose} className="btn btn-light btn-lg form-control">
                        Cancel
                    </button>
                    <button type="submit" onClick={handleSave} className="submitButton btn btn-primary btn-lg form-control">
                        Save
                    </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
