import React from "react";
import "bootstrap/dist/css/bootstrap.css"

export default function RefreshButton(props) {
  const reloadPage=()=>{
    window.location.reload();
  }
  return (
    <button className="btn btn-lg btn-primary settingsBtn" onClick={reloadPage}>Update EPOS</button>
  );
}