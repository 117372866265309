import { useState,useEffect } from "react";
import logger from "../lib/util";
import useLogErrortoAPI from "./useLogErrortoAPI";
import axios from "axios"
require('dotenv').config();

const useGetOnlineOrders=(initialUrl)=>{
   const [loading,setLoading]=useState(true);
   const [onlineOrdersList,setOrders]=useState({});
   const [ordersUrl,setOrdersUrl]= useState(initialUrl);
   const [random,setRandom]= useState(0);
   const {postData}=useLogErrortoAPI();
    
   useEffect(()=>{
      const fetchData = async()=> {
         try {
            setLoading(true);
            const response = await axios.get(ordersUrl,  {
               headers: {
                     'Access-Control-Allow-Origin': true,
                     'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
               },
               });
            if(response.status===200)
            {
               setOrders(response.data.result.items);
               
            }else{
               console.log('Failed 2');
               postData(response, 'useGetOnlineOrders', 'GetOrders');
               logger.info(response.status);
               setOrders([]);
            }
          } catch (error) {
            console.log('Failed 3');
            postData(error, 'useGetOnlineOrders', 'GetOrders');
            logger.error(`Error: ${error}`);
            setOrders([]);
         }
          finally{
              setLoading(false);
          }
      }
      if(ordersUrl.length>0){
         fetchData();
      }else{
         setOrders([]);
      }     
   
 },[random]);

 const doFetch=(url,r)=>{
    if(url){
      setOrdersUrl(url);
      setRandom(r);
    }else{
      setOrders([]);
    }    
 }
 return {loading,onlineOrdersList,doFetch};
};
export default useGetOnlineOrders;