import React, { useContext, useEffect, useState } from "react";
import './form.css'
import "bootstrap/dist/css/bootstrap.css"
import AppContext from '../Data/AppContext'
import * as AiIcons from 'react-icons/io';
import EatinForm from '../Forms/EatinForm'
import DeliveryForm from '../Forms/DeliveryForm'
import CollectionForm from '../Forms/CollectionForm'
import WaitingForm from '../Forms/WaitingForm'
import { ButtonGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
import { NotificationManager } from 'react-notifications';
import axios from "axios"
import { useHistory } from "react-router-dom";
require('dotenv').config();

export default function OrderForm(props) {
  const glContext = useContext(AppContext);
  const {postData}=useLogErrortoAPI();
  const newOrder = props.newOrder;
  let tNumber = 0;
  let inst = '';
  let dCharge = 0;
  let gst = 0;
  let sCharge = 0;
  let drCharge = 0;
  let tname = '';
  let tdeltime = '';
  let tmobile = '';
  let tline1 = '';
  let tline2 = '';
  let tline3 = '';
  let tline4 = '';
  let tpostcode = '';
  let eatinActive = '';
  let collectionActive = '';
  let waitingActive = '';
  let deliveryActive = 'active';
  if (!glContext.Restaurant.available_delivery) {
    deliveryActive = '';
    collectionActive = 'active';
  }
  const [onlyEatin, setOnlyEatin]=useState(false);
  if(glContext.CallingCustomer && glContext.CallingCustomer.mobile)
  {
    tmobile = glContext.CallingCustomer.mobile;
    tname = glContext.CallingCustomer.name;
    tpostcode = glContext.CallingCustomer.postcode;
    tline1 = glContext.CallingCustomer.line1;
    tline2 = glContext.CallingCustomer.line2;
    tline3 = glContext.CallingCustomer.line3;
    tline4 = glContext.CallingCustomer.line4;
    glContext.setCallingCustomer({});
  }

useEffect(()=>{
  if (glContext.ActiveOrder !== null 
    && !newOrder 
    && glContext.ActiveOrder.order_type==="eat-in") {
    setOnlyEatin(true);
    }else{
      setOnlyEatin(false);
    }
}, [glContext.ActiveOrder, newOrder]);

  const handleClick = (type) => {
   
    
    if (type === 'collection') {
      setCollection('active');
      setDelivery('');
      setWaiting('');
      setEatin('');
    }
    if (type === 'delivery') {
      setCollection('');
      setDelivery('active');
      setWaiting('');
      setEatin('');
    }
    if (type === 'waiting') {
      setCollection('');
      setDelivery('');
      setWaiting('active');
      setEatin('');
    }
    if (type === 'eatin') {
      setCollection('');
      setDelivery('');
      setWaiting('');
      setEatin('active');
    }
  }

  
  if (glContext.ActiveOrder !== null && !newOrder) {
    tname = glContext.ActiveOrder.name;
    if (glContext.ActiveOrder.mobile !== null) {
      tmobile = glContext.ActiveOrder.mobile.replace('+44', '0');
    }
    tline1 = glContext.ActiveOrder.line1;
    tline2 = glContext.ActiveOrder.line2;
    tline3 = glContext.ActiveOrder.line3;
    tline4 = glContext.ActiveOrder.line4;
    tNumber = glContext.ActiveOrder.inhouse_table;
    inst = glContext.ActiveOrder.instructions;
    dCharge = glContext.ActiveOrder.dessert_charge;
    tpostcode = glContext.ActiveOrder.postcode;
    gst = glContext.ActiveOrder.inhouse_guests;
    sCharge = glContext.ActiveOrder.service_charge;
    drCharge = glContext.ActiveOrder.drinks_charge;
    tdeltime = glContext.ActiveOrder.delivery_time;
    if (glContext.ActiveOrder.order_type === 'eat-in') {
      eatinActive = 'active';
      collectionActive = '';
      deliveryActive = '';
      waitingActive = '';
    }
    if (glContext.ActiveOrder.order_type === 'collection') {
      collectionActive = 'active';
      eatinActive = '';
      deliveryActive = '';
      waitingActive = '';
    }
    if (glContext.ActiveOrder.order_type === 'delivery') {
      deliveryActive = 'active';
      collectionActive = '';
      eatinActive = '';
      waitingActive = '';
    }
    if (glContext.ActiveOrder.order_type === 'waiting') {
      waitingActive = 'active';
      deliveryActive = '';
      collectionActive = '';
      eatinActive = '';
    }
  }

  const [eatin, setEatin] = useState(eatinActive);
  const [waiting, setWaiting] = useState(waitingActive);
  const [collection, setCollection] = useState(collectionActive);
  const [delivery, setDelivery] = useState(deliveryActive);
  //const [orderData, setOrderData] = useState({});
  const [mobile, setMobile] = useState(tmobile);
  const [name, setName] = useState(tname);
  const [deliveryTime, setDeliveryTime] = useState(tdeltime);
  const [instructions, setInstructions] = useState(inst);
  const [postcode, setPostcode] = useState(tpostcode);
  const [line1, setLine1] = useState(tline1);
  const [line2, setLine2] = useState(tline2);
  const [line3, setLine3] = useState(tline3);
  const [line4, setLine4] = useState(tline4);
  const [tableNumber, setTableNumber] = useState(tNumber);
  const [dessertCharge, setDessertCharge] = useState(dCharge);
  const [guests, setGuests] = useState(gst);
  const [serviceCharge, setServiceCharge] = useState(sCharge);
  const [drinksCharge, setDrinksCharge] = useState(drCharge);


  const globalOrder = {
    mobile: mobile,
    name: name,
    deliveryTime: deliveryTime,
    instructions: instructions,
    postcode: postcode,
    line1: line1,
    line2: line2,
    line3: line3,
    line4: line4,
    tableNumber: tableNumber,
    dessertCharge: dessertCharge,
    serviceCharge: serviceCharge,
    drinksCharge: drinksCharge,
    guests: guests,
    setMobile,
    setName,
    setDeliveryTime,
    setInstructions,
    setPostcode,
    setLine1,
    setLine2,
    setLine3,
    setLine4,
    setTableNumber,
    setDessertCharge,
    useState,
    setGuests,
    setServiceCharge,
    setDrinksCharge,
  };

  const history = useHistory();
  const { handleClose } = props;
  let url = process.env.REACT_APP_CREATE_ORDER + glContext.RestaurantId;


  const handleSubmitClick = async (type) => {
    if (newOrder === true) {
      var bodyFormData = new FormData();
      bodyFormData.append('restaurant_id', glContext.RestaurantId);
      bodyFormData.append('order_type', type);
      // bodyFormData.append('delivery_time', time);
      bodyFormData.append('instructions', instructions);

      if (type === "eat-in") {
        bodyFormData.append('dessert_charge', dessertCharge);
        bodyFormData.append('drinks_charge', drinksCharge);
        bodyFormData.append('inhouse_guests', guests);
        bodyFormData.append('service_charge', serviceCharge);
        bodyFormData.append('inhouse_table', tableNumber);
      } else if (type === "delivery") {
        bodyFormData.append('name', name);
        bodyFormData.append('mobile', mobile);
        bodyFormData.append('line1', line1);
        bodyFormData.append('line2', line2);
        bodyFormData.append('line3', line3);
        bodyFormData.append('line4', line4);
        bodyFormData.append('postcode', postcode);
        bodyFormData.append('delivery_time', deliveryTime);
      } else if (type === "collection") {
        bodyFormData.append('name', name);
        bodyFormData.append('mobile', mobile);
        bodyFormData.append('postcode', postcode);
        bodyFormData.append('delivery_time', deliveryTime);
      } else { //waiting
        bodyFormData.append('name', name);
        bodyFormData.append('delivery_time', deliveryTime);
      }
      var data = {
        order_type: type,
        instructions: instructions,
        dessert_charge: dessertCharge,
        drinks_charge: drinksCharge,
        inhouse_guests: guests,
        service_charge: serviceCharge,
        inhouse_table: tableNumber,
        name: name,
        mobile: mobile,
        line1: line1,
        line2: line2,
        line3: line3,
        line4: line4,
        postcode: postcode,
        delivery_time: deliveryTime,
      };

      try {
        const response = await axios.post(url, bodyFormData, {
          headers: {
            'Access-Control-Allow-Origin': true,
            "Content-Type": "multipart/form-data",
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
          },
        }).catch(err => {
          if (err.response.status === 404) {
            // TODO - API error log send response. Error code #OFJ-105
            postData(`${err.config.url} not found Error code #OFJ-105`, 'OrderForm', 'handleSubmitClick');
            throw new Error(`${err.config.url} not found`);
          }
          throw err;
        });

        if (response.status === 200 
          && response.data !== undefined 
          && response.data.result !== undefined
          && response.data.result.order !== undefined) {
            NotificationManager.success('New order created');
            setActiveOrder(response.data.result.order);
            NavigatetoOrderDetils();
        } else {
          NotificationManager.error('An unknown error occurred. Error code #OFJ-101', 'Failed to save order', 50000, () => {});
          // TODO - API error log send response. Error code #OFJ-103
          postData('An unknown error occurred. Error code #OFJ-101', 'OrderForm', 'handleSubmitClick');
        }
      } catch (err) {     
        if (err.response !== undefined 
          && err.response.data !== undefined
          && err.response.data.errors !== undefined) {
            postData(err.response.data.errors.join(', ')+' Error code #OFJ-100', 'OrderForm', 'handleSubmitClick');
          NotificationManager.error(err.response.data.errors.join(', '), 'Failed to Save Order', 50000, () => {});
        } else {
          NotificationManager.error('An unknown error occurred. Error code #OFJ-102', 'Failed to save order', 50000, () => {});
          // TODO - API error log send response. Error code #OFJ-102
           postData('An unknown error occurred. Error code #OFJ-102', 'OrderForm', 'handleSubmitClick');
        }
      }
    } else {
      let updateurl = process.env.REACT_APP_DELETE_ORDER + glContext.RestaurantId + '&id=' + glContext.ActiveOrder.id;
      try {
        data = {};
        //data.restaurant_id=  glContext.RestaurantId;
        data.order_type = type;
        data.instructions = instructions;

        // bodyFormData.append('delivery_time', time);
        if (type === "eat-in") {
          data.dessert_charge = dessertCharge;
          data.drinks_charge = drinksCharge;
          data.inhouse_guests = guests;
          data.service_charge = serviceCharge;
          data.inhouse_table = tableNumber;
        } else if (type === "delivery") {
          data.name = name;
          data.mobile = mobile;
          data.postcode = postcode;
          data.delivery_time = deliveryTime;
          data.line1 = line1;
          data.line2 = line2;
          data.line3 = line3;
          data.line4 = line4;
        } else if (type === "collection") {
          data.name = name;
          data.mobile = mobile;
          data.delivery_time = deliveryTime;
        } else {
          data.name = name;
          data.delivery_time = deliveryTime;
        }

        const response = await axios.patch(updateurl, data, {
          headers: {
            'Access-Control-Allow-Origin': true,
            "Content-Type": "multipart/form-data",
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
          },
        }).catch(err => {
          if (err.response.status === 404) {
            // TODO - API error log send response. Error code #OFJ-106
            throw new Error(`${err.config.updateurl} not found`);
          }
          throw err;
        });

        if (response.status === 200
          && response.data !== undefined 
          && response.data.result !== undefined
          && response.data.result.order !== undefined) {
            NotificationManager.success('Order updated sucessfully');
            glContext.setActiveOrder(response.data.result.order);
            handleClose();
        } else {
          NotificationManager.error('An unknown error occurred. Error code #OFJ-103', 'Failed to update Order', 50000, () => {});
          // TODO - API error log send response. Error code #OFJ-103
          // alert(JSON.stringify(response));
        }
      } catch (err) {
        if (err.response !== undefined 
          && err.response.data !== undefined
          && err.response.data.errors !== undefined) {
            NotificationManager.error(err.response.data.errors.join(', '), 'Failed to Save Order', 50000, () => {});
          } else {
            NotificationManager.error('An unknown error occurred. Error code #OFJ-104', 'Failed to update Order', 50000, () => {});
            // TODO - API error log send response - error code #OFJ-104
            postData('An unknown error occurred. Error code #OFJ-104', 'OrderForm', 'handleSubmitClick');
          }
      }
    }
  }

  function setActiveOrder(data) {
    glContext.setActiveOrder(data);
  }

  function NavigatetoOrderDetils() {
    history.push("/orderDetails");
  }

  return (
    <div className="formContainer">
      <div className="col-md-12 margin-bottom-10 margin-top-10">
        <ButtonGroup aria-label="Basic example">
          {glContext.Restaurant.available_eatin &&
          <Button variant="light" disabled={(!newOrder && !onlyEatin)} className={eatin} onClick={() => { handleClick('eatin') }}>
            <AiIcons.IoMdPeople /> Eat-in</Button>}            
          {glContext.Restaurant.available_takeaway && 
          <Button variant="light" disabled={(!newOrder && onlyEatin)} className={collection} onClick={() => { handleClick('collection') }}>
            <AiIcons.IoMdWalk /> Collection</Button>}
          {(glContext.Restaurant.available_takeaway && glContext.Restaurant.available_delivery)  && 
          <Button variant="light" disabled={(!newOrder && onlyEatin)} className={delivery} onClick={() => { handleClick('delivery') }}>
            <AiIcons.IoMdCar /> Delivery</Button>}
          {glContext.Restaurant.available_takeaway &&
          <Button variant="light"disabled={(!newOrder && onlyEatin)} className={waiting} onClick={() => { handleClick('waiting') }}>
            <AiIcons.IoMdClock /> Waiting</Button>}
        </ButtonGroup>
      </div>
      {(eatin === 'active') && <EatinForm globalOrder={globalOrder} handleClick={handleSubmitClick} newOrder={newOrder} handleClose={handleClose} />}
      {(delivery === 'active') && <DeliveryForm handleClick={handleSubmitClick} globalOrder={globalOrder} newOrder={newOrder} handleClose={handleClose} />}
      {(collection === 'active') && <CollectionForm handleClick={handleSubmitClick} globalOrder={globalOrder} newOrder={newOrder} handleClose={handleClose} />}
      {(waiting === 'active') && <WaitingForm handleClick={handleSubmitClick} globalOrder={globalOrder} newOrder={newOrder} handleClose={handleClose} />}
    </div>
  );
}
