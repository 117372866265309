import {  React, useState, useEffect } from 'react';
import "./OrderDetailsHead.css"
import * as AiIcons from 'react-icons/io';
import ChargeDialog from '../../Dialog/ChargeDialog'
import DiscountDialog from '../../Dialog/DiscountDialog'
const OrdersDetailsHeadWaiting = (props) => {
const order = props.activeOrder;
const {setActiveOrder} = props;  
const [showChargeDialog, setShowChargeDialog]=useState(false);
const [showDiscountDialog, setShowDiscountDialog]=useState(false);
const [showDirtyValue, setShowDirtyValue]=useState(false);
const [chargeType, setChargeType]=useState('Drinks');
const [discount,setDiscount] =useState(order.total_discount);
const onlineOrder = (order.origin === 'online');

const handleChargeClick = (type) => {
  if (!onlineOrder) {
    setChargeType(type);
    setShowChargeDialog(true);
  }
};

useEffect(()=>{
  const dirty= props.dirty;
  setShowDirtyValue(!dirty);
});

const handleDiscountClick =()=>{
  if (!onlineOrder) {
    setShowDiscountDialog(true);
  }
}
 let deliveryTime = new Date(order.order_time.replace(" ","T"));
 if (order.delivery_time != null)
 {
     deliveryTime =new Date(order.delivery_time);
 }
 else if (order.delivery_time_est != null)
 {
     deliveryTime =new Date(order.delivery_time_est);
 }
 
 let deliveryTimeStr = deliveryTime.toLocaleString([], { 
  hour: 'numeric', 
  minute: 'numeric', 
  hour12: true 
});
 
let detailsLineClickable = 'detailsLine';
if (!onlineOrder) {
  detailsLineClickable = 'detailsLine clickable';
}
 
let paidOnline = false;
if (onlineOrder && (
  order.payment_status === 'submitted_for_settlement'
  || order.payment_status === 'settling')) {
    paidOnline = true;
}
  return (
      <div className="row orderDetailsHeader">
       {showChargeDialog && <ChargeDialog type={chargeType}  setActiveOrder={setActiveOrder} order={order} setShowChargeDialog={setShowChargeDialog}/>} 
       {showDiscountDialog && <DiscountDialog type={chargeType} setShowDiscountDialog={setShowDiscountDialog}  subtotal={order.subtotal_amount} discount={discount} setDiscount={setDiscount} setActiveOrder={setActiveOrder} order={order}/>}  
          <div className="col-sm-6 text-left ">
            <div className="detailsLine">
              <h5>Waiting - {deliveryTimeStr}</h5>
            </div>
            <div className="detailsLine">Customer: {order.name}</div>
            <div className="detailsLine notesColumn">Notes: {order.instructions}</div>
          </div>
          {showDirtyValue && 
          <div className="col-sm-6 text-right">
            <div className="strong detailsLine">
              <h5>
              {onlineOrder && <small className="text-muted">{(paidOnline ? '[ PAID ]' : '[ UNPAID ]')}</small>}
               Total: {order.currency_symbol}{order.total_amount}</h5>
            </div>
            <div className={detailsLineClickable} name='Drinks' onClick={()=>{handleChargeClick('Drinks')}} >
              <AiIcons.IoIosAddCircleOutline className="float-left"/> Drinks Charge: {order.currency_symbol}{order.drinks_charge} </div>
            <div className={detailsLineClickable}  name='Service' onClick={()=>{handleChargeClick('Service')}}>
              <AiIcons.IoIosAddCircleOutline className="float-left"/> Service Charge: {order.currency_symbol}{order.service_charge}</div>
            <div className={detailsLineClickable} onClick={handleDiscountClick}>
              <AiIcons.IoIosAddCircleOutline className="float-left"/> Discount: {order.currency_symbol}{order.total_discount}</div>
            <div className="detailsLine">Subtotal: {order.currency_symbol}{order.subtotal_amount}</div>
          </div>}
        </div>
  );
}

export default OrdersDetailsHeadWaiting;
