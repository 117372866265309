import React, { useState , useContext} from 'react';
import OAuth2Login from '../src/components/OAuth/oAuth2Login';
import ErrorAlert from './ErrorAlert';
import './index.css';
import LoginInfo from './components/LoginInfo/LoginInfo';
import AppContext from '../src/components/Data/AppContext';
import { useHistory } from "react-router-dom";

import {
  authorizationUrl,
  clientId,
  redirectUri,
} from './settings-code';

export default function AuthProvider(props) {
  const history = useHistory();
  //const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(props.user);
  const [resturants, setAllResturants] = useState(props.restaurantsList);
  const [error, setError] = useState(null);
  const glContext = useContext(AppContext);
  const {refreshRestaurant} = props;
  // You can test this with a GitHub OAuth2 app (provided test server supports GitHub and Spotify)
  const onSuccess = ({ code }) => fetch(process.env.REACT_APP_OAUTH_TOKEN_URI, {
    method: 'POST',
    body: JSON.stringify({
      'code': code, 
      'grant_type':'authorization_code', 
      'client_id':clientId, 
      'redirect_uri': redirectUri, 
      'client_secret':''
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then((data) => {
      //setAccessToken(data.access_token);
      return data.access_token;
    })
    .then(token => fetch(process.env.REACT_APP_OAUTH_USER_URI, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }))
    .then(res => res.json())
    .then(json =>
      {
        return Promise.resolve([json.user, json.restaurants]);
      } )
    .then(([userString, allRestaurants] )=> {   
      // Do all the localstorage setup here   
      localStorage.removeItem("user");
      localStorage.removeItem("restaurant");
      localStorage.removeItem("allRestaurants");
      
      localStorage.setItem('user', userString);
      localStorage.setItem('allRestaurants', allRestaurants);
      var allRestaurantsObj = JSON.parse(allRestaurants);
      if (allRestaurantsObj.length === 1) {
        localStorage.setItem('restaurant', JSON.stringify(allRestaurantsObj[0]));
      }
      return Promise.resolve([JSON.parse(userString), allRestaurantsObj]);  
    })
    .then(([userObj, allRestaurants])=>{
      // Do all the glcontext setup here if possible
      setUser(userObj);
      setAllResturants(allRestaurants);
      
      glContext.setUser(userObj);
      glContext.setLoggedin(true);

      if (allRestaurants.length === 1) {
        glContext.setRestaurant(allRestaurants[0]);
        glContext.setRestaurantId(allRestaurants[0].id);
        history.push("/Home"); 
      }
    })
    .catch(setError);

    const handleRestaurantClick=(value)=>{
      glContext.setRestaurant(value);
      glContext.setRestaurantId(value.id);
      localStorage.removeItem("restaurant");
      localStorage.setItem('restaurant', JSON.stringify(value));
      let r = Math.random().toString(36).substring(7);
      refreshRestaurant(r);
      history.push("/Home"); 
    }
    
    
    return (
    <div className="col-md-12 text-center background-white">
      {
        error && <ErrorAlert error={error} />
      }
      { (!user || !glContext.Loggedin ) &&
        <OAuth2Login
          className="btn btn-lg btn-primary"
          id="auth-code-login-btn"
          authorizationUrl={authorizationUrl}
          clientId={clientId}
          redirectUri={redirectUri}
          responseType="code"
          scope='user'
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={setError}setError
        />
      }
      {
        !glContext.Loggedin  && <p>Failed to login</p>
      }
      { 
        (resturants && resturants.length>0 && glContext.Loggedin )&& <LoginInfo user={user} restaurants={resturants} handleRestaurantClick={handleRestaurantClick}/>
      }
    </div>
  );
}

