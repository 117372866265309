import React, { useContext} from 'react';
import AppContext from '../Data/AppContext';
import { Button } from '@material-ui/core'
import TutorialDialog from '../Dialog/TutorialDialog';
// import { tutorialData } from './tutorialData';
import styles from './Tutorials.module.css'

const Tutorials = () => {
    const glContext = useContext(AppContext);
    const {setOpen, setInfo} = glContext;

    const handleClick = (tutorial) => {
        setInfo(tutorial);
        setOpen(true);
    }

    const info = [
        {
            id: 1,
            title: 'CurrryHunter Advert',
            tutorial_url: '7Ft7cnCGYP0',
            video_title: 'Checkout our video advertisement',
        }
    ]

    return (
        <div className={styles.tutorials}>
            {
                info && info.map(tutorial => (
                    <Button key={tutorial.id} className='bg-primary text-light' variant="contained" size="medium" onClick={() => handleClick(tutorial)}>{tutorial.title}</Button>
                ))
            }
            {/* {
                tutorialData.map(tutorial => {
                    return (
                        <button key={tutorial.id} className='btn btn-primary' onClick={() => handleClick(tutorial)}>{tutorial.video_title}</button>
                    )
                })
            } */}

            {glContext.info && <TutorialDialog />}
        </div>
    )
}

export default React.memo(Tutorials);
