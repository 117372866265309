import React from "react";
export default function LoginInfo(props) {
    
    const {handleRestaurantClick}= props;
    const restaurants = props.restaurants;
    const user = props.user;
   
    
    return (
        <div className="container-fluid">
          {
        user && (
          <div className="margin-top-10">
            <h3>Welcome {user.name}</h3>
            <p>{user.secondary_contact}<br/>{user.mobile}</p>
            <hr/>
          </div>
        )
      }
      {
       (restaurants && Object.entries(restaurants).length>0)&& (
        <div>
        <h5>Select restaurant</h5>{
          restaurants && (Object.entries(restaurants).map( ([key, value]) => 
              (value.i_o_enabled===true) 
                && <button key={key} id={key} className='btn btn-dark settingsBtn' onClick={(e)=>handleRestaurantClick(value)} >{value.name}</button>
          
          ))}
          <hr/>
        </div>
       )
      }
        </div>
    );
}