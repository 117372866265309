import React from "react";
import './page.css'
import "bootstrap/dist/css/bootstrap.css"
import AuthProvider from '../../AuthProvider';
require('dotenv').config();

export default function Login(props) {
    return (
        <div className="container-fluid">
            <div className="row">
                <AuthProvider  refreshRestaurant={props.refreshRestaurant}  user={props.user} restaurantsList={props.JSONRestaurantList} />
            </div>
        </div>
    );
}
/*
import ImplicitGrantExample from '../../ImplicitGrantExample';
<ImplicitGrantExample />
*/
