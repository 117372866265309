import { React, useContext, useEffect, useState } from 'react';
import './OrdersDetailsFooter.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios";
import FormDialog from '../../Dialog/FormDialog';
import AppContext from '../../Data/AppContext';
import 'react-notifications/lib/notifications.css';
import logger from "../../../lib/util";
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import { useHistory } from "react-router-dom";
import PaymentMethod from '../../PaymentMethod/PaymentMethod';
import AddTimeDialog from '../../Dialog/AddTimeDIalog';
const OrdersDetailsFooter = (props) => {
  const history = useHistory();
  const glContext = useContext(AppContext);
  const [confirmDeleteText ]=useState("Are you sure you want to cancel this order?");
  const [showDetailsDialog, setShowDetailsDialog]=useState(false);
  const [showAddTimeDialog, setShowAddTimeDialog]=useState(false);
  const [showConfirmDialog, setShowConfirmDialog]=useState(false);
  const [paymentFlag, setPaymentFlag]=useState(false);
  const [saveButton, setSaveButton]=useState('Print');
  const activeOrder = props.activeOrder;
  const onlineOrder = (activeOrder.origin === 'online');
  const {setActiveOrder} = props;
  useEffect(()=>{
    if(props.dirty){
      setSaveButton('Save');
    }else {
      setSaveButton('Print');
    }
  }, [saveButton , props.dirty]);

  const handleSaveClick = async () =>
  {
    if(saveButton === 'Print'){
      handlePrint();
    } else {
      if (onlineOrder){
          NotificationManager.warning('Sorry, can not update online orders');
          return;
      }
 
      let url = process.env.REACT_APP_SAVE_ORDER+ glContext.RestaurantId+ '&id='+ activeOrder.id;
      try {
       const response =  await axios.patch(url,JSON.stringify(activeOrder.order_lines),{
         headers: {
           'Access-Control-Allow-Origin': true,
           "Content-Type": "multipart/form-data",
           'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
         },
         }).catch(err => {
         if (err.response.status === 404) {
           throw new Error(`${err.config.url} not found`);
         }
         throw err;
       });
 
       if(response.status===200)
       {
         NotificationManager.success('Successfully Saved');
         logger.info('Save Success');
         logger.info(response);
         glContext.setActiveOrder(response.data.result.order);
         setActiveOrder(response.data.result.order);
         glContext.setDirty(false);
        // history.push("/orderDetails"); 
       }else{
         NotificationManager.error(JSON.stringify(response.warning),'Failed to Save Order', 50000, () => {
           history.push("/Home"); 
         });
       }
     } catch (error) {
       logger.info('error');
       logger.info(error);
       NotificationManager.error(JSON.stringify(error),'Failed to Save Order', 50000, () => {
         history.push("/"); 
       });
     }
   }
    
  } 
  
  const handleAddTime =  () => {
    setShowAddTimeDialog(true);
  }

  const handleDeleteClick=  async () => {   
    if (onlineOrder){
       NotificationManager.warning('Sorry, can not delete online orders');
       return;
    }
    let url = process.env.REACT_APP_DELETE_ORDER+ glContext.RestaurantId+ '&id='+ activeOrder.id;
    let error;
    try {
      let data  =activeOrder; 
      logger.info(data.status='cancelled');
      const response =  await axios.patch(url,{status:'cancelled'},{
        headers: {
          'Access-Control-Allow-Origin': true,
          "Content-Type": "multipart/form-data",
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
        },
        }).catch(err => {
        if (err.response.status === 404) {
          throw new Error(`${err.config.url} not found`);
        }
        throw err;
      });

      if(response.status===200)
      {
        NotificationManager.success('Order deleted');
        history.push("/Home"); 
      }else{
        NotificationManager.error(JSON.stringify(response.warning),'Failed to delete order', 10000, () => {
          //history.push("/"); 
        });
      }
    } catch (err) {
      error = err;
      logger.info('error');
      logger.info(error);
      NotificationManager.error(error, 'Go to Home', 10000, () => {
        history.push("/"); 
      });
    }
  }

  const handleDetailsOpen = () => {
    if (!onlineOrder){
      setShowDetailsDialog(true);
    }
  };

  const handleConfirmDelete = () => {
    if (!onlineOrder){
      setShowConfirmDialog(true);
    }
  };

  const handlePrint= async ()=>{
    let url = process.env.REACT_APP_DELETE_ORDER+ glContext.RestaurantId+ '&id='+ glContext.ActiveOrder.id;
    let error;
    try {
      let value  ={
          status: 'confirmed',
          print_status : 'confirmed'
      };

      const response =  await axios.patch(url,value,{
        headers: {
          'Access-Control-Allow-Origin': true,
          "Content-Type": "multipart/form-data",
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
        },
        }).catch(err => {
        if (err.response.status === 404) {
          throw new Error(`${err.config.url} not found`);
        }
        throw err;
      });

      if(response.status===200)
      {
          let content = document.getElementById("printContent");
          let pri = document.getElementById("ifmcontentstoprint").contentWindow;
          pri.document.open();
          pri.document.write(content.innerHTML);
          pri.document.close();             
          pri.focus();
          
          let printTimes = 1;
          if (glContext.Restaurant 
            && glContext.Restaurant.i_o_print_times > 1 
            && activeOrder.print_status !== "confirmed") {
            printTimes = glContext.Restaurant.i_o_print_times;
          }
          (function myLoop(i) {
            setTimeout(function() { 
              pri.print();
              console.log('printing');
              if (--i) myLoop(i);   //  decrement i and call myLoop again if i > 0
            }, 1000)
          })(printTimes);
          
          NotificationManager.success('Succesfully updated the order');
          
      }else{
          NotificationManager.error(JSON.stringify(response.warning),'Failed to Save Order', 50000, () => {
          //history.push("/"); 
          });
      }
    } catch (err) {
        error = err;
        logger.info(error);
    }
  }
 
  return (
    <div>
      <NotificationContainer/>
      <div className="ordersDetailsFooter">  
        <div className="btn-group">
        {!onlineOrder && <button onClick={handleConfirmDelete} className="deleteButton btn btn-lg btn-warning">Delete</button>}
        {(glContext.ActiveOrder.order_type !== "eat-in") &&  <button  className="addTime btn btn-lg btn-secondary" onClick={handleAddTime} >+Time</button>}
        {!onlineOrder && <button  className="details btn btn-lg btn-info d-none d-md-block" onClick={handleDetailsOpen}>Details</button>}
        <button onClick={handleSaveClick} className="savePrint btn-lg btn btn-primary">{saveButton}</button>
        </div>
      </div>
      {showAddTimeDialog && <AddTimeDialog showAddTimeDialog={showAddTimeDialog} setShowAddTimeDialog={setShowAddTimeDialog}/>}  
      {(!onlineOrder && showDetailsDialog) && <FormDialog showDetailsDialog={showDetailsDialog}  activeOrder={activeOrder} setActiveOrder={setActiveOrder}  setPaymentFlag={setPaymentFlag} paymentFlag={paymentFlag} setShowDetailsDialog={setShowDetailsDialog}/>}  
      {(!onlineOrder && showConfirmDialog) && <ConfirmDialog confirmText={confirmDeleteText} showConfirmDialog={showConfirmDialog} handleDeleteClick={handleDeleteClick} setShowConfirmDialog={setShowConfirmDialog} />}  
      <PaymentMethod activeOrder={activeOrder} setActiveOrder={setActiveOrder} saveButton={saveButton} setPaymentFlag={setPaymentFlag}/>
    </div>
  );
}

export default OrdersDetailsFooter;