import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import ClosedDaysDialog from '../../Dialog/ClosedDayDialog/ClosedDaysDialog'
import ClosedDay from './ClosedDay';
import AppContext from '../../Data/AppContext';
import {  MdBorderColor } from 'react-icons/md';
import { Button } from '@material-ui/core';

import { days_of_week, regular_closed_days, special_close_days, closed_date_range } from './closedays_data'

const ClosedDays = () => {
    const glContext = useContext(AppContext);
    const {  Restaurant } = glContext;
    const { o_o_closed_days } = Restaurant;
    // final data
    const [data, setData] = useState(JSON.parse(o_o_closed_days));
    // closed days dummy data
    const [daysOfWeek, setDaysOfWeek] = React.useState(days_of_week);
    const [regularClosedDay, setRegularClosedDay] = React.useState(regular_closed_days);
    const [closedDayRange, setClosedDayRange] = React.useState(closed_date_range);
    const [specialCLosedDay, setSpecialCLosedDay] = React.useState(special_close_days);
    const [isClose, setIsClose] = useState(false);
    
    // copied state
    const [daysOfWeek2, setDaysOfWeek2] = React.useState(days_of_week);
    const [regularClosedDay2, setRegularClosedDay2] = React.useState(regular_closed_days);

    
    const settingData = (data) => {
        setData(JSON.parse(data));
    }
   

    // fetchData from local storage
    // const fetchData = localStorage.getItem('restaurant');
    // const restaurant = JSON.parse(fetchData);
    // const { o_o_closed_days: closed_days } = restaurant;

    // fetchData from local storage
    // const fetchData = localStorage.getItem('restaurant');
    
    // useEffect(() => {
    //     let restaurant = {};
    //     if(fetchData) {
    //         restaurant = JSON.parse(fetchData);
    //     }

    //     if(restaurant?.o_o_closed_days) {
    //         const { o_o_closed_days: closed_days } = restaurant && restaurant;
    //         setData(JSON.parse(closed_days));
    //     }
    // }, [fetchData]);
    
    // let closeDaysData = null;
    // try{
    //     const fetchData = localStorage.getItem('restaurant');
    //     let closeDays = JSON.parse(fetchData);
    //     const { o_o_closed_days } = closeDays;
    //     closeDaysData = JSON.parse(o_o_closed_days);
    // }catch(err){
    //     console.log(err)
    // }
    
    // useEffect(() => {
    //     setData(closeDaysData);
    // }, [closeDaysData]);
    
    const { dates_regular: RegularClosedDates, days_of_week: Days } = data;
    useEffect(() => {
        const DayResult = days_of_week.map((day) => Days.indexOf(day.day) !== -1 ? { ...day, isClose: true } : day );
        const DateResult = regular_closed_days.map((date) => RegularClosedDates.indexOf(date.date) !== -1 ? { ...date, isClose: true } : date );
        
        setRegularClosedDay(DateResult);
        setRegularClosedDay2(DateResult);
        setDaysOfWeek(DayResult);
        setDaysOfWeek2(DayResult);
    }, [Days, RegularClosedDates])

    const handleOpen = useCallback(() => {
        setIsClose(true);
    }, [])

    const handleClose = () => {
        setIsClose(false);
        setDaysOfWeek(daysOfWeek2);
        setRegularClosedDay(regularClosedDay2);
    }

    return (
        <>
            {/* pass data to dialogBox by props  */}
            <ClosedDaysDialog 
                handleClose={handleClose} 
                isClose={isClose} 
                daysOfWeek={daysOfWeek}
                setDaysOfWeek={setDaysOfWeek}
                regularClosedDay={regularClosedDay}
                setRegularClosedDay={setRegularClosedDay}
                specialCLosedDay={specialCLosedDay}
                setSpecialCLosedDay={setSpecialCLosedDay}
                closedDayRange={closedDayRange}
                setClosedDayRange={setClosedDayRange}
                finalData={data}
                settingData={settingData}
                setIsClose={setIsClose}
            />

             <div className="margin-bottom-40">
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <h3>Closed Days 
                        <Button variant="outlined" className="ml-2 font-weight-bold text-center"  size="medium" onClick={handleOpen}>
                            <MdBorderColor fontSize="25px"  className='ml-2' />
                        </Button>
                    </h3>
                </div>

                <div className="row">
                    <div className="col-md-4 col-lg-4 border">
                        <h5 className="mt-2">Days of week</h5>
                        <hr />

                        {data?.dates_regular.length === 0 && <h5>No closed day</h5>}
                        
                        <ClosedDay data={data.days_of_week} />
                    </div>
                    <div className="col-md-4 col-lg-4 border">
                        <h5 className="mt-2">Yearly Regular Days</h5>
                        <hr />

                        {data?.dates_regular.length === 0 && <h5>No closed day</h5>}

                        <ClosedDay data={data.dates_regular} />
                    </div>
                    <div className="col-md-4 col-lg-4 border">
                        <h5 className="mt-2">Special Days</h5>
                        <hr />

                        {data?.dates_special.length === 0 && data.date_ranges.length === 0 && <h5>No special days</h5>}

                        <ClosedDay data={data.dates_special} />

                        {data?.date_ranges.length !== 0 && 
                            data.date_ranges.map((range, i) => (
                                    <React.Fragment key={i}>
                                        {
                                            <div className="my-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="special-days" checked readOnly />
                                                    <label className="form-check-label" htmlFor="special-days">
                                                        {range.start} - {range.end}
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment >
                                )
                            )
                        }
                    </div>
                </div>
            </div> 
        </>
    )
}

export default React.memo(ClosedDays);
