import { useState, useEffect, useContext } from "react";
import logger from "../lib/util";
import AppContext from '../components/Data/AppContext';
import axios from "axios"
require('dotenv').config();
const useGetUsers = () => {
    const [loading, setLoading] = useState(true);
    const [usersList, setUsers] = useState({});
    const [search, setSearch] = useState('');
    const glContext = useContext(AppContext);
    let url = process.env.REACT_APP_SEARCH_USERS + glContext.RestaurantId + '&mobile=' + search;

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                logger.info('Set loading true');
                logger.info('Calling get users function');
                const response = await axios.get(url, {
                    headers: {
                        'Access-Control-Allow-Origin': true,
                        'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                    },
                });
                if (response.status === 200) {
                    var data = [];
                    for (let i = 0; i < response.data.result.items.length; i++) {
                        data.push({
                            id: i,
                            mobile: response.data.result.items[i].mobile.replace("+44", "0"),
                            country: response.data.result.items[i].country,
                            // county:  response.data.result.items[i].county,
                            // door_no: response.data.result.items[i].door_no,
                            // email:  response.data.resultdoor_no.items[i].email,
                            // house_name: response.data.result.items[i].house_name,
                            line1: response.data.result.items[i].line1,
                            line2: response.data.result.items[i].line2,
                            line3: response.data.result.items[i].line3,
                            line4: response.data.result.items[i].line4,
                            name: response.data.result.items[i].name,
                            postcode: response.data.result.items[i].postcode
                        });
                    }
                    setUsers(data);
                } else {
                    logger.info(response.status);
                    setUsers([]);
                }
            } catch (error) {
                logger.error(`Error: ${error}`);
                setUsers([]);
            }
            finally {
                setLoading(false);
            }
        }

        if (search.length > 2) {
            fetchData()
        }
    }, [search, url]);
    const doFetch = (txt) => {
        setSearch(txt);
    }
    return { loading, usersList, doFetch };
};
export default useGetUsers;