import logger from '../lib/util';
import axios from "axios"
const useLogErrortoAPI =  () => {
  let url ="";
  var strrestaurant = localStorage.getItem("restaurant");
  var rest  = JSON.parse(strrestaurant);
  const postData = async(logText,path, action)=> {
    if(logText===''){
      logText= null;
    }
    var success= false;
    if(rest && rest.id){
      url= process.env.REACT_APP_SAVE_ERROR + rest.id;
      try {
        var data ={
          restaurant_id:rest.id,
          date_time: Date.now,
          page:path,
          func:action,
          text:logText
        }
        const response =  await axios.patch(url,data ,{
          headers: {
            'Access-Control-Allow-Origin': true,
            "Content-Type": "multipart/form-data",
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
          },
          }).catch(err => {
            logger.info('error while posting to Error API');
          if (err.response.status === 404) {
            throw new Error(`${err.config.url} not found`);
          }
          throw err;
        });
  
        if(response.status===200)
        {
          logger.info('Posted the log to API, success');
          logger.info(response);
        }else{
          logger.info('Failed sending log to API');
        }
      } catch (err) {
        logger.info('error while posting to Error API');
        logger.info(err);
      }
    }
    
    return success;
  }
  return {postData};  
};

export default useLogErrortoAPI;