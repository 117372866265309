import React,{useContext} from "react";
// import { useForm } from "react-hook-form";
import AppContext from '../Data/AppContext'
import "bootstrap/dist/css/bootstrap.css";
import {NotificationManager, NotificationContainer} from 'react-notifications';
require('dotenv').config();
export default function EatinForm(props) {
  const glContext = useContext(AppContext);
  var rx = new RegExp(/^\d+$/);
  // const {  formState: { errors }, handleSubmit, setValue } = useForm();
  const {handleClick} = props;
  const {handleClose} = props;
  const newOrder = props.newOrder;
  const globalOrder = props.globalOrder;
  

const handeSubmitClick=()=>{
  var required=[];
  if (globalOrder.drinksCharge < 0){
    NotificationManager.error('Drinks charge can not be less than 0','Invalid Information', 3000, () => {});
    return;
  }
  if (globalOrder.serviceCharge < 0){
    NotificationManager.error('Service charge can not be less than 0','Invalid Information', 3000, () => {});
    return;
  }
  if (globalOrder.dessertCharge < 0){
    NotificationManager.error('Dessert charge can not be less than 0','Invalid Information', 3000, () => {});
    return;
  }
  
  if(!globalOrder.tableNumber || parseInt(globalOrder.tableNumber) < 1){
    required.push('Table Number');
  }
  if(!globalOrder.guests || parseInt(globalOrder.guests) < 1){
    required.push('Number of Guests');
  }

  if(required.length>0){
    NotificationManager.error(required.join(', '),'Information required', 5000, () => {});
  }
  else{
    glContext.setActiveOrder(globalOrder);
    handleClick('eat-in');
  }
   

}

const handleGuestsChange=(value)=>{
  globalOrder.setGuests(value);
}

const handleTableNumberChange=(e)=>{
  if((e.target.value.length <=3 && rx.test(e.target.value) && !e.target.value.indexOf('.') > -1 )|| e.target.value===''){
    globalOrder.setTableNumber(e.target.value);
  }
}

const handleinstructionsChange=(value)=>{
  globalOrder.setInstructions(value);
}

const handleDrinksChargeChange=(value)=>{
  globalOrder.setDrinksCharge(value);
}
const handleServiceChargeChange=(value)=>{
  globalOrder.setServiceCharge(value);
}

const handleDessertChargeChange=(value)=>{
  globalOrder.setDessertCharge(value);
}

const handleKeyDown_number = e => {
  if (e.key === ".") {
    e.preventDefault();
  }
};

  return (
    <div className="eatinFormContainer">
      <NotificationContainer/>
        <div className="row">
          <div className="col-sm-6">
            <label htmlFor="inhouse_table">Table Number</label>
            <input id="inhouse_table" autoComplete="off" autoFocus="autofocus" tabIndex="0" pattern='/^\d+$/' min="1" step="1"  className="form-control" type="number" onKeyDown={handleKeyDown_number}  onChange={e=> handleTableNumberChange(e)}   value={globalOrder.tableNumber>0?globalOrder.tableNumber:''} />
            <label htmlFor="inhouse_guests">Number of Guests</label>
            <input id="inhouse_guests" autoComplete="off" className="form-control" tabIndex="0" type="number" min="1" step="1" value={globalOrder.guests>0?globalOrder.guests:''} onKeyDown={handleKeyDown_number} onChange={e=> handleGuestsChange(e.target.value)}   />
            <label htmlFor="instructions">Notes</label>
            <textarea className="form-control" tabIndex="0" rows="2" type='text'  value={globalOrder.instructions} onChange={e=> handleinstructionsChange(e.target.value)}   />
          </div>
          <div className="col-sm-6">
            <label htmlFor="drinks_charge">Drinks Charge</label>
            <input className="form-control" autoComplete="off" tabIndex="0" placeholder="0.00" pattern="^\d*(\.\d{0,2})?$" type="number" min="0" step=".5" value={globalOrder.drinksCharge} onChange={e=>handleDrinksChargeChange(e.target.value)}/>
            <label htmlFor="service_charge">Service Charge</label>
            <input className="form-control" autoComplete="off" tabIndex="0" placeholder="0.00" pattern="^\d*(\.\d{0,2})?$" type="number" min="0" step=".5" value={globalOrder.serviceCharge} onChange={e=>handleServiceChargeChange(e.target.value)}  />
            <label htmlFor="Dessert Charge">Dessert Charge</label>
            <input className="form-control" autoComplete="off" tabIndex="0" placeholder="0.00" pattern="^\d*(\.\d{0,2})?$" type="number" min="0" step=".5"value={globalOrder.dessertCharge} onChange={e=>handleDessertChargeChange(e.target.value)}  />
          </div>
        </div>
        <div className="row">
          <div className="formErrors"></div>
        </div>
        <div className="row">
          <div className="col-md-12 btn-group">
            {(!newOrder) &&
              <button tabIndex="0" className="btn btn-light form-control" onClick={handleClose} >Cancel</button>
            }
            <button className="btn btn-primary form-control" onClick={handeSubmitClick}> Save</button>

          </div>
        </div>
    </div>
  );
}
