import React, { useState, useEffect, useContext, useCallback } from 'react';
import { MdBorderColor } from 'react-icons/md';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DatePicker from "react-multi-date-picker"
import ClosedDayDatePiker from './ClosedDayDatePiker';
import ClosedDay from '../../Manage/ClosedDays/ClosedDay';
import AppContext from '../../Data/AppContext';
import axios from "axios";
import { NotificationManager } from 'react-notifications';

const ClosedDaysDialog = (props) => {
    const glContext = useContext(AppContext);
    const { RestaurantId, Restaurant } = glContext;
    const { handleClose,
            isClose,
            daysOfWeek, 
            setDaysOfWeek, 
            regularClosedDay, 
            setRegularClosedDay,
            specialCLosedDay, 
            setSpecialCLosedDay, 
            closedDayRange, 
            setClosedDayRange, 
            finalData,
            setIsClose,
            settingData
        } = props;
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');

    const { dates_special } = finalData;
    const [ values, setValues ] = useState(dates_special?.map(item => new Date(item)));

    useEffect(() => {
        let existingSpecialDates = [];
        if(dates_special){
            dates_special.forEach((item, i) =>{
                existingSpecialDates.push({id: `dates_special_${i + 1}`, date: item, isClose: true})
            })
        } else {
            existingSpecialDates = []
        }
        
        setSpecialCLosedDay(existingSpecialDates)
        setValues(existingSpecialDates?.map(item => new Date(item.date)));
    }, [dates_special, setSpecialCLosedDay])

    // this useEffect used to update the specialCLosedDay array
    useEffect(() => {
        const interval = setInterval(() => {
            setMessage('');
        }, 6000);

        return () => clearInterval(interval);
    }, [message]);
    
    
    // this useEffect using for multiple date picker and also format date
    useEffect(()=> {
        let dates = [];
        values.forEach((item, index) => {
            if(item.year !== undefined && item.month !== undefined && item.day !== undefined) {
                // formate date
                const date = `${item.year}-${item.month.toString().length < 2 ? "0"+item.month : item.month}-${item.day.toString().length < 2 ? "0"+item.day : item.day}`;
                dates.push({id: `special_day_${index + 1}`, date: date, isClose: true});
            } else{
                // format existing date
                let Year = item?.getFullYear();
                let Month = item?.getMonth() + 1;
                let Day = item?.getDate();

                if (Month.toString().length < 2){
                    Month = '0' + Month;
                }
                if (Day.toString().length < 2){
                    Day = '0' + Day;
                }

                let date = Year + '-' + Month + '-' + Day;
                dates.push({id: `special_day_${index + 1}`, date: date, isClose: true});
            }
        })

        setSpecialCLosedDay([]);
        dates.map(date => setSpecialCLosedDay((preValue) => [...preValue, date]));
    }, [dates_special, setSpecialCLosedDay, values])

    // submit data to api endpoint
    const handleSubmit = async () => {
         // api endpoint
        const url = `${process.env.REACT_APP_UPDATE_RESTAURANT}${RestaurantId}`;
        const config = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`,
            'Access-Control-Allow-Origin': true,
        }

        const o_o_closed_days = JSON.stringify({
        "days_of_week": daysOfWeek.filter(day => day.isClose === true)?.map(day => day.day),
        "dates_regular": regularClosedDay.filter(date => date.isClose === true)?.map(date => date.date),
        "dates_special": specialCLosedDay.filter(date => date.isClose === true)?.map(date => date.date),
        "date_ranges": closedDayRange.filter(range => range.isClose === true)?.map(range => {return {start: range.start, end: range.end}})
        })
        const SendData = `{"o_o_closed_days": ${o_o_closed_days}}`;

        setLoading(true);
        try {
            const res = await axios.patch(url, SendData, {headers: config});
            const { data } = res;

            
            if(data.success){
                settingData(data.result.o_o_closed_days);
                localStorage.removeItem("restaurant");
                localStorage.setItem('restaurant', JSON.stringify(res.data.result));
                glContext.setRestaurant(res.data.result);
               // localStorage.setItem('restaurant', JSON.stringify({...Restaurant, o_o_closed_days: data.result.o_o_closed_days}));
                NotificationManager.success('Successfully updated!');
            }
        } catch (error) {
            NotificationManager.error('Failed to save closed days!');
        }
        setLoading(false);
        setIsClose(false);
    }

    // change the state of the closed day
    const handleChange = useCallback((handleEvent, values, setValues) => {
        const { name, checked } = handleEvent.target;
        let newData = values.map(item => name === item.id ? {...item, isClose: checked} : item);
        setValues(newData);
    }, [])

    return (
        <>
            <Dialog 
                onClose={handleClose} 
                open={isClose} 
                fullWidth 
                maxWidth="lg"  
                id='closed-days-edit'
            >
                <div className="row dialogHeader">
                    <span className="col-10 d-flex align-items-center"> <MdBorderColor fontSize="20px" className='mr-2' />Edit Closed Days</span>
                    <span className="text-right col-2">
                        <button className="btn" onClick={handleClose}>X</button>
                    </span>
                </div>

                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 col-lg-2 border">
                            <h5 className="mt-2 text-center">Days of week</h5>
                            <hr />

                            <ClosedDay data={daysOfWeek} setData={setDaysOfWeek} isEdit={true} onHandler={handleChange} />
                        </div>
                        <div className="col-md-6 col-lg-2 border">
                            <h5 className="mt-2 text-center">Regular Day</h5>
                            <hr />

                            <ClosedDay data={regularClosedDay} setData={setRegularClosedDay}  isEdit={true} onHandler={handleChange} />
                        </div>
                        <div className="col-md-12 col-lg-8 border">
                            <h5 className="mt-2 text-center">Special days</h5>
                            <hr />

                            <div className="d-flex align-item-center flex-wrap justify-content-around">
                                <div>
                                    <h6>Select single or multiple date</h6>
                                        <DatePicker
                                            multiple
                                            value={values} 
                                            placeholderText="Tap to select a date"
                                            onChange={setValues}
                                        />
        
                                    <h6 className={`${message !== '' ? 'mt-3 text-danger text-center' : "mt-3"}`}>{message !== '' ? message :'Select a range of closed day'}</h6>
                                    <ClosedDayDatePiker 
                                        range={true} 
                                        setMessage={setMessage}
                                        setClosedDayRange={setClosedDayRange} 
                                        onHandler={closedDayRange } 
                                        closedDayRange={closedDayRange}
                                        finalData={finalData.date_ranges}
                                    />
                                    <div className='mb-3'></div>
                                </div>

                                <div>
                                    <ClosedDay data={specialCLosedDay} setData={setSpecialCLosedDay}  isEdit={true} onHandler={handleChange} />

                                    {
                                        closedDayRange && closedDayRange.map((range, i) => {
                                            return (
                                                <div className="my-3"  key={range.id}>
                                                    <form className="form-check">
                                                        <input className="form-check-input" type="checkbox" name={range.id} id={range.start} style={{cursor: "pointer"}} checked={range.isClose} onChange={(e) => handleChange(e, closedDayRange, setClosedDayRange)} />
                                                        <label className="form-check-label" htmlFor={range.start} style={{cursor: "pointer"}}>
                                                            {range.start} - {range.end}
                                                        </label>
                                                    </form>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 margin-bottom-10" >
                        <div className="col-md-12 btn-group">
                            <button className='btn btn-light btn-lg form-control' disabled={loading} onClick={handleClose}>Cancel</button>
                            <button className='btn btn-primary btn-lg form-control' disabled={loading}  onClick={handleSubmit}>{loading ? 'saving' : 'save'}</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>   
        </>
    )
}

export default React.memo(ClosedDaysDialog);
