import { useState,useEffect,useContext } from "react";
import logger from "../lib/util";
import AppContext from '../components/Data/AppContext';
import useLogErrortoAPI from "./useLogErrortoAPI";
import axios from "axios"
require('dotenv').config();
const useGetOrders=(initialUrl)=>{
const [loading,setLoading]=useState(true);
const [ordersList,setOrders]=useState({});
const [ordersUrl,setOrdersUrl]= useState(initialUrl);
const [random,setRandom]= useState(0);
const {postData}=useLogErrortoAPI();
const glContext = useContext(AppContext); 
    
 useEffect(()=>{
      const fetchData = async()=> {
          try {
             setLoading(true);
             logger.info('Set loading true');
              logger.info('Calling get function');
              const response = await axios.get(ordersUrl,  {
                 headers: {
                     'Access-Control-Allow-Origin': true,
                     'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                 },
                 });
              if(response.status===200)
              {
                 logger.info('response 200');
                 logger.info(response.data.result.items);
                 setOrders(response.data.result.items);
                 if(glContext){
                  glContext.setOrders(response.data.result.items);
                 }
                 
              }else{
                postData(response, 'useGetOrders', 'GetOrders');
                logger.info(response.status);
                setOrders([]);
              }
          } catch (error) {
            postData(error, 'useGetOrders', 'GetOrders');
            logger.error(`Error: ${error}`);
             setOrders([]);
          }
          finally{
              setLoading(false);
          }
      }
      if(ordersUrl.length>0){
         fetchData();
      }else{
         setOrders([]);
      }
      
   
 },[random]);
 const doFetch=(url,r)=>{
    if(url){
      setOrdersUrl(url);
      setRandom(r);
    }else{
      setOrders([]);
    }
    
 }
 return {loading,ordersList,doFetch};
};
export default useGetOrders;