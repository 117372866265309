import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { MdBorderColor } from 'react-icons/md';
import AppContext from '../../Data/AppContext'
import axios from 'axios';
import styles from './ServiceCharge.module.css';
import ServiceChargeCard from './ServiceChargeCard'
import { NotificationManager } from 'react-notifications';

const ServiceChargeDialog = (props) => {
  const glContext = useContext(AppContext);
  const { RestaurantId, Restaurant } = glContext;
  const { serviceCharges, open, setIsOpen, setServiceCharges, settingData } = props;
  const [ charge, setCharge ] = useState({
    online_orders: serviceCharges.online_orders || 0,
    epos_takeaway: serviceCharges.epos_takeaway || 0,
    epos_eatin_pb: serviceCharges.epos_eatin_pb || 0,
    epos_eatin_pp: serviceCharges.epos_eatin_pp || 0,
    epos_eatin_perc: serviceCharges.epos_eatin_perc || 0
  })

  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ onlineChargeMessage, setOnlineChargeMessage ] = useState('');
  const [ takeawayChargeMessage, setTakeawayChargeMessage ] = useState('');
  const [ perPersonChargeMessage, setPerPersonChargeMessage ] = useState('');
  const [ perOrderChargeMessage, setPerOrderChargeMessage ] = useState('');
  const [ perOrderPercentChargeMessage, setPerOrderPercentChargeMessage ] = useState('');

  // format data as JSON
  const stringifiedData = JSON.stringify({
    online_orders: Number(charge.online_orders),
    epos_takeaway: Number(charge.epos_takeaway),
    epos_eatin_pb: Number(charge.epos_eatin_pb),
    epos_eatin_pp: Number(charge.epos_eatin_pp),
    epos_eatin_perc: Number(charge.epos_eatin_perc)
  })

  const handleSubmit = async () => {
    if(onlineChargeMessage || takeawayChargeMessage || perPersonChargeMessage || perOrderChargeMessage || perOrderPercentChargeMessage) return setError('Invalid entry, please check below.');
    // api endpoint
    const url = `${process.env.REACT_APP_UPDATE_RESTAURANT}${RestaurantId}`;
    const config = {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`,
      'Access-Control-Allow-Origin': true,
    }
    const sendData = `{"service_charges": ${stringifiedData}}`;

    setLoading(true);
    try {
      const res = await axios.patch(url, sendData, { headers: config });
      const { data } = res;
      const { result, success } = data;
      if(success){
        localStorage.removeItem("restaurant");
        localStorage.setItem('restaurant', JSON.stringify(res.data.result));
        glContext.setRestaurant(res.data.result);
       // localStorage.setItem('restaurant', JSON.stringify({...Restaurant, service_charges: result.service_charges}));
        settingData(result.service_charges);
        NotificationManager.success('Successfully updated!');
      }
    } catch (error) {
      return NotificationManager.error('Failed to save Charges!');
    }
    setLoading(false);
    setError('');
    setIsOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setError('');
    }, 10000);

    return () => clearInterval(interval);
  }, [error]);
 
  const handleChange = (e) => {
    const { value: textValue , name } = e.target;
    const check1 =  Number(textValue) >= 0 && Number(textValue) <= 0.5;
    const checkForPerPerson =  Number(textValue) >= 0 &&  Number(textValue) <= 10;
    const check2 =  Number(textValue) >= 0 &&  Number(textValue) <= 100;
    // values 
    const per_person = Number(charge.epos_eatin_pp) ;
    const per_order = Number(charge.epos_eatin_pb);
    const percent = Number(charge.epos_eatin_perc);
    
    if (name === 'online_orders') {
      if(check1){
        setCharge({...charge, online_orders: Number(textValue)});
        setOnlineChargeMessage('')
      }else {
        setCharge({...charge, online_orders: 0});
        setOnlineChargeMessage('value cannot be greater than 0.5 and less than 0');
      }
    }

    if(name === 'takeaway_orders'){
      if(check1){
        setCharge({...charge, epos_takeaway: Number(textValue)});
        setTakeawayChargeMessage('')
      }else {
        setCharge({...charge, epos_takeaway: 0});
        setTakeawayChargeMessage('value cannot be greater than 0.5 and less than 0');
      }
    }

    if(name === 'per_person'){
      if((percent > 0 || per_order > 0) && +textValue > 0){
        setCharge({...charge, epos_eatin_pp: 0});
        setPerPersonChargeMessage('To set this, remove value from per percentage/per order field')
      } else if(checkForPerPerson){
        setCharge({...charge, epos_eatin_pp: Number(textValue)});
        setPerPersonChargeMessage('')
      } else {
        setCharge({...charge, epos_eatin_pp: 0});
        setPerPersonChargeMessage('value cannot be less than 0 and greater than 10');
      }
    }

    if(name === 'per_order'){
      if((per_person > 0 || percent > 0) && +textValue > 0){
        setCharge({...charge, epos_eatin_pb: 0});
        setPerOrderChargeMessage('To set this, remove value from per person/percentage field')
      } else if(check2){
        setCharge({...charge, epos_eatin_pb: Number(textValue)});
        setPerOrderChargeMessage('')
      }else {
        setCharge({...charge, epos_eatin_pb: 0});
        setPerOrderChargeMessage('value cannot be less than 0 and greater than 100');
      }
    }

    if(name === 'percentage'){
      if((per_person > 0 || per_order > 0) && +textValue > 0){
        setCharge({...charge, epos_eatin_perc: 0});
        setPerOrderPercentChargeMessage('To set this, remove value from per person/per order field')
      } else if(check2){
        setCharge({...charge, epos_eatin_perc: Number(textValue)});
        setPerOrderPercentChargeMessage('')
      }else {
        setCharge({...charge, epos_eatin_perc: 0});
        setPerOrderPercentChargeMessage('value cannot be less than 0 and greater than 100');
      }
    }
  }
  
  const handleClose = () => {
    setIsOpen(false);
    setServiceCharges({
      online_orders: serviceCharges.online_orders,
      epos_takeaway: serviceCharges.epos_takeaway,
      epos_eatin_pb: serviceCharges.epos_eatin_pb,
      epos_eatin_pp: serviceCharges.epos_eatin_pp,
      epos_eatin_perc: serviceCharges.epos_eatin_perc
    })
    setCharge({
      online_orders: serviceCharges.online_orders,
      epos_takeaway: serviceCharges.epos_takeaway,
      epos_eatin_pb: serviceCharges.epos_eatin_pb,
      epos_eatin_pp: serviceCharges.epos_eatin_pp,
      epos_eatin_perc: serviceCharges.epos_eatin_perc
    })
    setError('');
    setOnlineChargeMessage('');
    setTakeawayChargeMessage('');
    setPerPersonChargeMessage('');
    setPerOrderChargeMessage('');
    setPerOrderPercentChargeMessage('');
  }
  
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        id="service-charge-dialog"
      >
        <div className="row dialogHeader">
          <span className="col-10 d-flex align-items-center"> <MdBorderColor fontSize="20px" className='mr-1' /> Edit Service Charge</span>
          <span className="text-right col-2">
            <button
              className="btn"
              onClick={handleClose}
            >
              X
            </button>
          </span>
        </div>
        <DialogContent>

          {error && <div className="alert alert-danger">{error}</div>}
          
          <div className="row mt-3">
              <ServiceChargeCard
                heading="All Online Orders"
                title={'Online Orders:'} 
                symbol={Restaurant.currency_symbol} 
                message={onlineChargeMessage} 
                handleChange={handleChange} 
                data={serviceCharges.online_orders}
                name={'online_orders'}
                min={0}
                max={0.5}
                step={0.1}
              />

              <ServiceChargeCard 
                heading="Inhouse Takeaway Orders"
                title={'Takeaway Orders:'} 
                symbol={Restaurant.currency_symbol} 
                message={takeawayChargeMessage} 
                handleChange={handleChange} 
                data={serviceCharges.epos_takeaway}
                name={'takeaway_orders'}
                min={0}
                max={0.5}
                step={0.1}
              />

            <div className="col-md-4 pb-3 border">
              <h5 className="mt-2 text-center">Inhouse Eat-in Orders</h5>
              <hr />

              <ServiceChargeCard
                title={'Per Person:'}
                symbol={Restaurant.currency_symbol} 
                message={perPersonChargeMessage} 
                handleChange={handleChange} 
                data={serviceCharges.epos_eatin_pp}
                name={'per_person'}
                min={0}
                max={10}
                step={1}
              />

              <ServiceChargeCard 
                title={'Per Order:'} 
                symbol={Restaurant.currency_symbol} 
                message={perOrderChargeMessage} 
                handleChange={handleChange} 
                data={serviceCharges.epos_eatin_pb}
                name={'per_order'}
                min={0}
                max={100}
                step={1}
              />

              <ServiceChargeCard 
                title={'Percent of Subtotal:'} 
                symbol={'%'}
                message={perOrderPercentChargeMessage} 
                handleChange={handleChange} 
                data={serviceCharges.epos_eatin_perc}
                name={'percentage'}
                min={0}
                max={100}
                step={1}
              />
            </div>
          </div>

          <div className="row mt-2 margin-bottom-10" >
              <div className="col-md-12 btn-group">
                  <button className={`btn btn-light btn-lg form-control ${styles.disabled}`} disabled={loading} onClick={handleClose}>Cancel</button>
                  <button className={`btn btn-primary btn-lg form-control ${styles.disabled}`} disabled={loading} onClick={handleSubmit}>{loading ? 'saving' : 'save'}</button>
              </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default React.memo(ServiceChargeDialog);
