import React, { useContext, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppContext from '../Data/AppContext'
import axios from "axios";
import logger from '../../lib/util';

export default function SetMealSelectionDialog(props) {
    const glContext = useContext(AppContext);
    const [open] = useState(true);
    const [setMeals, setSetMeals] = useState(glContext.SetMeal);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedSetMeal, setSelectedSetMeal] = useState(setMeals.items[props.id]);
    const [group1Required, setGroup1Required] = useState(setMeals.items[props.id].group1_choice ?? 0);
    const [group2Required, setGroup2Required] = useState(setMeals.items[props.id].group2_choice ?? 0);
    const [group3Required, setGroup3Required] = useState(setMeals.items[props.id].group3_choice ?? 0);
    const [group4Required, setGroup4Required] = useState(setMeals.items[props.id].group4_choice ?? 0);
    const [group5Required, setGroup5Required] = useState(setMeals.items[props.id].group5_choice ?? 0);
    const [totalremaining, setTotalremaining]= useState(parseInt(group1Required)  + parseInt(group3Required) + parseInt(group3Required) + parseInt(group4Required) + parseInt(group5Required));
    const [notes] = useState(props.notes);
    const [group1Selected, setGroup1Selected] = useState([]);
    const [group2Selected, setGroup2Selected] = useState([]);
    const [group3Selected, setGroup3Selected] = useState([]);
    const [group4Selected, setGroup4Selected] = useState([]);
    const [group5Selected, setGroup5Selected] = useState([]);
    const [checkboxRefresh, setCheckboxRefresh] = useState(false);
    const [amount, setAmount] = useState(selectedSetMeal.price);
    
  
    const { refreshNotes } = props;
    const [nullChoices1] = useState((selectedSetMeal.group1_choice == null) ? true : false);
    const [nullChoices2] = useState((selectedSetMeal.group2_choice == null) ? true : false);
    const [nullChoices3] = useState((selectedSetMeal.group3_choice == null) ? true : false);
    const [nullChoices4] = useState((selectedSetMeal.group4_choice == null) ? true : false);
    const [nullChoices5] = useState((selectedSetMeal.group5_choice == null) ? true : false);
    const [submitDisabled, setSubmitDisabled] = useState((nullChoices1&&nullChoices2&&nullChoices3&&nullChoices4&&nullChoices5) ? false : true);
    const [submitButtonText, setSubmitButtonText] = useState((nullChoices1&&nullChoices2&&nullChoices3&&nullChoices4&&nullChoices5) ? "Complete" : "Incomplete Selection");
    useEffect(() => {
        let noteIsObj = '';
        let setMeal = [];
        if (notes) {
            setAmount(props.data.amount ?? 0);
            if (IsJsonString(notes)) {
                try {
                    noteIsObj = JSON.parse(notes);
                
                } catch (e) { }
                if (noteIsObj !== false) {
                    for (const group in noteIsObj) {
                        let items = noteIsObj[group];
                        items.forEach(item => {
                            if (group == "group1_items") {
                                var items = group1Selected;
                                if (group1Selected.length < selectedSetMeal.group1_choice|| (nullChoices1&& group1Selected.length < noteIsObj.group1_items.length)) {
                                    items.push(item);
                                }
                                setGroup1Selected(items);
                                let id = item.name.replace(' ', '_')
                                var element = document.getElementById(id);
                                setCheckboxRefresh(element);
                                if (element) {
                                    element.checked = true;
                                }
                                setGroup1Required(0);
                            }
                            if (group == "group2_items") {
                                var items = group2Selected;
                                if (group2Selected.length < selectedSetMeal.group2_choice||  (nullChoices2&& group2Selected.length < noteIsObj.group2_items.length)) {
                                    items.push(item);
                                }
                                setGroup2Selected(items);
                                let id = item.name.replace(' ', '_')
                                var element = document.getElementById(id);
                                setCheckboxRefresh(element);
                                if (element) {
                                    element.checked = true;
                                }
                                setGroup2Required(0);
                            }
                            if (group == "group3_items") {
                                var items = group3Selected;
                                if (group3Selected.length < selectedSetMeal.group3_choice||  (nullChoices3&& group3Selected.length < noteIsObj.group3_items.length)) {
                                    items.push(item);
                                }
                                setGroup3Selected(items);
                                let id = item.name.replace(' ', '_')
                                var element = document.getElementById(id);
                                setCheckboxRefresh(element);
                                if (element) {
                                    element.checked = true;
                                }
                                setGroup3Required(0);
                            }
                            if (group == "group4_items") {
                                var items = group4Selected;
                                if (group4Selected.length < selectedSetMeal.group4_choice||  (nullChoices4&& group4Selected.length < noteIsObj.group4_items.length)) {
                                    items.push(item);
                                }
                                setGroup4Selected(items);
                                let id = item.name.replace(' ', '_')
                                var element = document.getElementById(id);
                                setCheckboxRefresh(element);
                                if (element) {
                                    element.checked = true;
                                }
                                setGroup4Required(0);
                            }
                            if (group == "group5_items") {
                                var items = group5Selected;
                                if (group5Selected.length < selectedSetMeal.group5_choice|| (nullChoices5&& group5Selected.length < noteIsObj.group5_items.length)) {
                                    items.push(item);
                                }
                                setGroup5Selected(items);
                                let id = item.name.replace(' ', '_')
                                var element = document.getElementById(id);
                                setCheckboxRefresh(element);
                                if (element) {
                                    element.checked = true;
                                }
                                setGroup5Required(0);
                            }
                            
                        });
                    }
                }
            }
        }

    }, [notes, checkboxRefresh]);


    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function handleClose() {
        props.setShowSetMealDialog(false);
    };



    var getSetMeal = function (key) {
        return setMeals.items[key];
    };

    function calculateAmount(value, operation) {
        if (selectedSetMeal && selectedSetMeal.price) {
            if (operation == "Add") {
                var price = parseFloat(amount);
                if(price){
                    price = price + parseFloat(value.extra_price);
                }else{
                    price = parseFloat(value.extra_price);
                }
                setAmount(price.toFixed(2));
            } else {
                var price = parseFloat(amount);
                if(price){
                    price = price - parseFloat(value.extra_price);
                }else{
                    price = parseFloat(value.extra_price);
                }
                setAmount(price.toFixed(2));
            }

        }
    }

    // function handleGroup1Click(e) {
    //     var val = e.target.val;
    //     alert(10);
    //     // var value = selectedSetMeal.group1.find(i => i.name == e.target.value);
    //     // addItem(1, value)

    // }

    const handleGroup1Remove = (e) => {
        removeItem(1, e.target.value);
    }

    const handleGroup2Remove = (e) => {
        removeItem(2, e.target.value);
    }
    const handleGroup3Remove = (e) => {
        removeItem(3, e.target.value);
    }
    const handleGroup4Remove = (e) => {
        removeItem(4, e.target.value);
    }
    const handleGroup5Remove = (e) => {
        removeItem(5, e.target.value);
    }

    function removeItem(group, value) {
        let remaining = 0;
        if (group == 1) {
            var s = group1Selected.find(i => i.name == value);
            if (s) {
                const ItemstoRemove = group1Selected.filter(function (item) {
                    return item.name == s.name;
                });
                const filteredItems = group1Selected.filter(function (item) {
                    return item.name !== s.name;
                })

                for (let i = 1; i < ItemstoRemove.length; i++) {
                    filteredItems.push(ItemstoRemove[i]);
                }

                calculateAmount(s, "Minus");
                setGroup1Selected(filteredItems);
                remaining = group1Required + 1;
                setGroup1Required(remaining)
            }
        }

        if (group == 2 ) {
            var s = group2Selected.find(i => i.name == value);
            if (s) {
                const ItemstoRemove = group2Selected.filter(function (item) {
                    return item.name == s.name;
                });
                const filteredItems = group2Selected.filter(function (item) {
                    return item.name !== s.name;
                })

                for (let i = 1; i < ItemstoRemove.length; i++) {
                    filteredItems.push(ItemstoRemove[i]);
                }

                calculateAmount(s, "Minus");
                setGroup2Selected(filteredItems);
                remaining = group2Required + 1;
                setGroup2Required(remaining)
            }
        }

        if (group == 3) {
            var s = group3Selected.find(i => i.name == value);
            if (s) {
                const ItemstoRemove = group3Selected.filter(function (item) {
                    return item.name == s.name;
                });

                const filteredItems = group3Selected.filter(function (item) {
                    return item.name !== s.name;
                })

                for (let i = 1; i < ItemstoRemove.length; i++) {
                    filteredItems.push(ItemstoRemove[i]);
                }

                calculateAmount(s, "Minus");
                setGroup3Selected(filteredItems);
                remaining = group3Required + 1;
                setGroup3Required(remaining)
            }
        }

        if (group == 4) {           
            var s = group4Selected.find(i => i.name == value);
            if (s) {
                const ItemstoRemove = group4Selected.filter(function (item) {
                    return item.name == s.name;
                });
                const filteredItems = group4Selected.filter(function (item) {
                    return item.name !== s.name;
                });              
                for (let i = 1; i < ItemstoRemove.length; i++) {
                    filteredItems.push(ItemstoRemove[i]);
                }

                calculateAmount(s, "Minus");
                setGroup4Selected(filteredItems);
                remaining = group4Required + 1;
                setGroup4Required(remaining)
            }
        }

        if (group == 5 ) {
            var s = group5Selected.find(i => i.name == value);
            if (s) {
                const ItemstoRemove = group5Selected.filter(function (item) {
                    return item.name == s.name;
                });
                const filteredItems = group5Selected.filter(function (item) {
                    return item.name !== s.name;
                });
                for (let i = 1; i < ItemstoRemove.length; i++) {
                    filteredItems.push(ItemstoRemove[i]);
                }

                calculateAmount(s, "Minus");
                setGroup5Selected(filteredItems);
                remaining = group5Required + 1;
                setGroup5Required(remaining)
            }
        }
        setSubmitDisabled(true);
        setSubmitButtonText("Incomplete Selection");

        if(nullChoices1 && nullChoices2 && nullChoices3&& nullChoices4 && nullChoices5){
            setSubmitDisabled(false);
            setSubmitButtonText("Complete");
        }
        return remaining;
    }

    function addItem(group, value) {
        let remaining = 0;
        if ((group == 1 ) && (group1Required > 0 || nullChoices1 )) {
            remaining = group1Required;
            var selected = group1Selected;
            calculateAmount(value, "Add");
            selected.push({ name: value.name, extra_price: value.extra_price });
            setGroup1Selected(selected);
            remaining = group1Required - 1;
            setGroup1Required(remaining)
        }
        if ((group == 2) && (group2Required > 0 || nullChoices2 )) {
            remaining = group2Required;
            var selected = group2Selected;
            calculateAmount(value, "Add");
            selected.push({ name: value.name, extra_price: value.extra_price });
            setGroup2Selected(selected);
            remaining = group2Required - 1;
            setGroup2Required(remaining)
        }
        if ((group == 3 ) && (group3Required > 0 || nullChoices3 )) {
            remaining = group3Required;
            var selected = group3Selected;
            calculateAmount(value, "Add");
            selected.push({ name: value.name, extra_price: value.extra_price });
            setGroup3Selected(selected);
            remaining = group3Required - 1;
            setGroup3Required(remaining)
        }
        if ((group == 4 ) && (group4Required > 0 || nullChoices4 )) {
            remaining = group5Required;
            var selected = group4Selected;
            calculateAmount(value, "Add");
            selected.push({ name: value.name, extra_price: value.extra_price });
            setGroup4Selected(selected);
            remaining = group4Required - 1;
            setGroup4Required(remaining)
        }
        if ((group == 5 ) && (group5Required > 0 || nullChoices5 )) {
            remaining = group5Required;
            var selected = group5Selected;
            calculateAmount(value, "Add");
            selected.push({ name: value.name, extra_price: value.extra_price });
            setGroup5Selected(selected);
            remaining = group5Required - 1;
            setGroup5Required(remaining)
        }
       
        if (remaining == 0 ) {
            enableSubmitButon();
        } else {
            if (remaining != -1)
                enableSubmitButon(remaining);
        }
        return remaining;
    }

    function handleGroup1Click(e) {
        var r = e.target.value.split('(');
        var val =  r[0].trim();
        var value = selectedSetMeal.group1.find(i => i.name === val);
        addItem(1, value);

    }
    const handleGroup2Click = (e) => {
        var r = e.target.value.split('(');
        var val =  r[0].trim();
        var value = selectedSetMeal.group2.find(i => i.name === val);
        addItem(2, value);
    }
    const handleGroup3Click = (e) => {
        var r = e.target.value.split('(');
        var val =  r[0].trim();
        var value = selectedSetMeal.group3.find(i => i.name === val);
        addItem(3, value);
    }

    const handleGroup4Click = (e) => {
        var r = e.target.value.split('(');
        var val =  r[0].trim();
        var value = selectedSetMeal.group4.find(i => i.name === val);
        addItem(4, value);        
    }

    const handleGroup5Click = (e) => {
        var r = e.target.value.split('(');
        var val =  r[0].trim();
        var value = selectedSetMeal.group5.find(i => i.name === val);
        addItem(5, value)
    }

    const enableSubmitButon = () => {
        var totselected = (parseInt(group1Selected.length)+ parseInt(group2Selected.length)+parseInt(group3Selected.length)+ parseInt(group4Selected.length)+ parseInt(group5Selected.length));
        var rem =totalremaining - totselected;
        
        if (rem < 1 ) {
            setSubmitDisabled(false);
            setSubmitButtonText("Complete");
        } else if (rem <1 || (nullChoices1 && nullChoices2 && nullChoices3&& nullChoices4 && nullChoices5)) {
            setSubmitDisabled(false);
            setSubmitButtonText("Complete");
        } 
        else {
            setSubmitDisabled(true);
            setSubmitButtonText("Incomplete Selection");
        }
    }

    const handlesubmit = () => {
        var data = {
            "default_group_items": selectedSetMeal.default_group,
            "group1_items": group1Selected,
            "group2_items": group2Selected,
            "group3_items": group3Selected,
            "group4_items": group4Selected,
            "group5_items": group5Selected
        }

        if ((nullChoices1 && nullChoices2 && nullChoices3 && nullChoices4 && nullChoices5 ) &&
        group1Selected.length === 0 && group2Selected.length === 0 && group3Selected.length === 0 &&  group4Selected.length === 0
        && group5Selected.length === 0){
                group1Selected.push({ name: "-", extra_price: 0 });
                data = {
                    "default_group_items": group1Selected,
                }
            }
        let newNotes = JSON.stringify(data)
        if (notes) {
            var items = glContext.ActiveOrder.order_lines.items;
            items[props.data.id].note = newNotes;
            items[props.data.id].price = amount;
            items[props.data.id].amount = amount;
            var ord = glContext.ActiveOrder;
            ord.order_lines.items = items;
            glContext.setActiveOrder(ord);
            saveOrder(ord);
            refreshNotes(newNotes);
        } else {
            crateOrderLine(newNotes);
        }

    }

    const crateOrderLine = async (data) => {
        let url = process.env.REACT_APP_SINGLE_ORDERLINE + glContext.RestaurantId + '&id=' + glContext.ActiveOrder.id;
        let error;
        var bodyFormData = new FormData();
        bodyFormData.append('restaurant_id', glContext.RestaurantId);
        bodyFormData.append('id', glContext.ActiveOrder.id);
        bodyFormData.append('item_id', selectedSetMeal.id);
        bodyFormData.append('sub_type', "set_meal");
        bodyFormData.append('quantity', 1);
        bodyFormData.append('operation', "add");
        try {
            const response = await axios.post(url, bodyFormData, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });

            if (response.status === 200) {
                logger.info('Save Success');
                logger.info(JSON.stringify(response));
                if (response.data.result.order) {
                    var newItems = response.data.result.order.order_lines.items;
                    var newline = {};//.find( i=> i.value.note==null && i.value.item_sub_type=="set_meal");
                    for (const [key, value] of Object.entries(newItems)) {
                        if (value.note == null && value.item_sub_type == "set_meal") {
                            newline = value;
                            break;
                        }
                    }
                    if (newline && newline.id) {
                        var items = response.data.result.order.order_lines.items;
                        items[newline.id].note = data;
                        items[newline.id].price = amount;
                        items[newline.id].amount = amount;
                        var ord = response.data.result.order;
                        ord.order_lines.items = items;
                        glContext.setActiveOrder(ord);
                        saveOrder(ord);
                    }
                }
            } else {
                logger.info('Save Failed');
            }
        } catch (err) {
            error = err;
            logger.info('error');
            logger.info(error);
        }
    }
    const saveOrder = async (ord) => {
        let url = process.env.REACT_APP_SAVE_ORDER + glContext.RestaurantId + '&id=' + glContext.ActiveOrder.id;
        let error;
        try {
            const response = await axios.patch(url, JSON.stringify(ord.order_lines), {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });

            if (response.status === 200) {
                logger.info('Save Success');
                var neword =response.data.result.order;
                if(neword){
                    glContext.setActiveOrder(neword);
                }
                
                handleClose();

            } else {
                logger.info('Save Failed');
            }
        } catch (err) {
            error = err;
            logger.info('error');
            logger.info(error);
        }
    }

    return (
      
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Select Items</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                {selectedSetMeal &&
                    <div className="modal-body">
                        <div className="row-fluid margin-bottom-10">
                            <div  className="col-12 fixed-min-height sm-group-items-sel well">
                                {
                                    selectedSetMeal && Object.entries(selectedSetMeal.default_group).map(([key, value]) =>
                                        <button key={key} className="btn btn-primary disabled setmeal-remove-item-button-disabled settingsBtn margin-bottom-10">{value.name}</button>
                                    )
                                }
                                {
                                    group1Selected && Object.entries(group1Selected).map(([key, value]) =>
                                        <button type="button" key={key} onClick={(e) => handleGroup1Remove(e)} className="btn btn-primary setmeal-remove-item-button-disabled settingsBtn margin-bottom-10" value={value.name}>{value.name} X</button>
                                    )
                                } 
                                {
                                    group2Selected && Object.entries(group2Selected).map(([key, value]) =>
                                        <button type="button" key={key} onClick={(e) => handleGroup2Remove(e)} className="btn btn-primary setmeal-remove-item-button-disabled settingsBtn margin-bottom-10" value={value.name}>{value.name} X</button>
                                    )
                                }
                                {
                                    group3Selected && Object.entries(group3Selected).map(([key, value]) =>
                                        <button type="button" key={key} onClick={(e) => handleGroup3Remove(e)} className="btn btn-primary setmeal-remove-item-button-disabled settingsBtn margin-bottom-10" value={value.name}  >{value.name} X</button>                                    
                                    )
                                }
                                {
                                    group4Selected && Object.entries(group4Selected).map(([key, value]) =>
                                        <button type="button" key={key} onClick={(e) => handleGroup4Remove(e)} className="btn btn-primary setmeal-remove-item-button-disabled settingsBtn margin-bottom-10" value={value.name} >{value.name} X</button>
                                    )
                                }
                                {
                                    group5Selected && Object.entries(group5Selected).map(([key, value]) =>
                                        <button type="button" key={key} onClick={(e) => handleGroup5Remove(e)} className="btn btn-primary setmeal-remove-item-button-disabled settingsBtn margin-bottom-10" value={value.name} >{value.name} X</button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row-fluid margin-bottom-10">
                            <div id="group1checkboxes" className="sm-group-items" data-sm="{}">
                                {(selectedSetMeal&& selectedSetMeal.group1 && Object.entries(selectedSetMeal.group1).length > 0 && (group1Required > 0 || nullChoices1)) && <div className="col-12 margin-bottom-10">
                                    <h5 className="">{selectedSetMeal.group1_name} {!nullChoices1 && <span className="text-warning">(Tap to choose {group1Required} more)</span>}</h5>
                                    {
                                        selectedSetMeal && selectedSetMeal.group1 && Object.entries(selectedSetMeal.group1).map(([key, value]) => {
                                            return <> 
                                            {parseFloat(value.extra_price)>0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup1Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name + " (£"+value.extra_price+")"} />}
                                            {parseFloat(value.extra_price)==0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup1Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name } />}
                                            </>
                                        })
                                    }
                                </div>
                                }
                                {(selectedSetMeal && selectedSetMeal.group2 && Object.entries(selectedSetMeal.group2).length > 0 && (group2Required > 0|| nullChoices2)) && <div className="col-12 margin-bottom-10">
                                    <h5 className="" >{selectedSetMeal.group2_name}  {!nullChoices2 && <span className="text-warning">(Tap to choose {group2Required} more)</span>}</h5>
                                    {
                                        selectedSetMeal && selectedSetMeal.group2 && Object.entries(selectedSetMeal.group2).map(([key, value]) => {
                                            return <> 
                                            {parseFloat(value.extra_price)>0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup2Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name + " (£"+value.extra_price+")"} />}
                                            {parseFloat(value.extra_price)==0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup2Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name } />}
                                            </>
                                           
                                        })
                                    }
                                </div>
                                }
                                {(selectedSetMeal  && selectedSetMeal.group3 &&  Object.entries(selectedSetMeal.group3).length > 0 && (group3Required > 0|| nullChoices3)) && <div className="col-12 margin-bottom-10">
                                    <h5 className="">{selectedSetMeal.group3_name}  {!nullChoices3 && <span className="text-warning">(Tap to choose {group3Required} more)</span>}</h5>
                                    {
                                        selectedSetMeal && selectedSetMeal.group3 && Object.entries(selectedSetMeal.group3).map(([key, value]) => {
                                            return <> 
                                            {parseFloat(value.extra_price)>0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup3Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name + " (£"+value.extra_price+")"} />}
                                            {parseFloat(value.extra_price)==0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup3Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name } />}
                                            </>
                                            
                                        })
                                    }
                                </div>
                                }
                                {(selectedSetMeal &&  selectedSetMeal.group4 && Object.entries(selectedSetMeal.group4).length > 0 && (group4Required > 0|| nullChoices4)) && <div className="col-12 margin-bottom-10">
                                    <h5 className="" >{selectedSetMeal.group4_name}  {!nullChoices4 && <span className="text-warning">(Tap to choose {group4Required} more)</span>}</h5>
                                    {
                                        selectedSetMeal && selectedSetMeal.group4 && Object.entries(selectedSetMeal.group4).map(([key, value]) => {
                                            return <> 
                                            {parseFloat(value.extra_price)>0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup4Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name + " (£"+value.extra_price+")"} />}
                                            {parseFloat(value.extra_price)==0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup4Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name } />}
                                            </>
                                        })
                                    }
                                </div>
                                }
                                {(selectedSetMeal && selectedSetMeal.group5 && Object.entries(selectedSetMeal.group5).length > 0 && (group5Required > 0|| nullChoices5)) && <div className="col-12 margin-bottom-10">
                                    <h5 className="" >{selectedSetMeal.group5_name}  {!nullChoices5 && <span className="text-warning">(Tap to choose {group5Required} more)</span>}</h5>
                                    {
                                       selectedSetMeal && selectedSetMeal.group5 &&  Object.entries(selectedSetMeal.group5).map(([key, value]) => {
                                        return <> 
                                        {parseFloat(value.extra_price)>0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup5Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name + " (£"+value.extra_price+")"} />}
                                        {parseFloat(value.extra_price)==0 && <input type="button" key={key} id={value.name.replace(' ', '_')} onClick={(e) => handleGroup5Click(e)} className="setmeal-add-item-button btn group1 btn-light settingsBtn" value={value.name } />}
                                        </>
                                        })
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row-fluid margin-top-10">
                            <div className="col-12 btn-group">
                                <button onClick={handleClose} data-dismiss="modal" aria-label="Close" className="form-control btn btn-light" >Cancel</button>
                                <button disabled={submitDisabled} onClick={handlesubmit} className="setmeal-confirm form-control btn btn-primary">{submitButtonText}</button>
                            </div>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}
