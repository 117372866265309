export const tutorialData = [
    {
        id: 1,
        title: 'Check the caller ID device is connected to the correct phone line.',
        tutorial_url: 'https://www.youtube.com/watch?v=chNtTlAXvXM',
        video_title: 'How check the caller ID device is connected?',
        description: 'Check the caller ID device is connected to the correct phone line.'
    },
    {
        id: 2,
        title: 'Check the caller ID is connected to the USB port in EPOS.',
        tutorial_url: 'https://www.youtube.com/watch?v=chNtTlAXvXM',
        video_title: 'USB port in EPOS?',
        description: 'Check the caller ID device is connected to the correct phone line.'
    },
    {
        id: 3,
        title: 'Check if the green light on caller ID lights up when a call is received.',
        tutorial_url: 'https://chres.ams3.digitaloceanspaces.com/marketing/advert_video_1.mp4',
        video_title: 'green light on caller ID',
        description: 'Check the caller ID device is connected to the correct phone line.'
    },
    {
        id: 4,
        title: 'If everything above is correct, check restart the computer and wait 1 minute before starting the EPOS software.',
        tutorial_url: 'https://chres.ams3.digitaloceanspaces.com/marketing/advert_video_1.mp4',
        video_title: 'restart the computer and wait 1 minute',
        description: 'Check the caller ID device is connected to the correct phone line.'
    },
    {
        id: 5,
        title: 'If everything above is correct, check with your phone provider if caller ID is enabled for your line.',
        tutorial_url: 'https://youtu.be/6CmnA0EaJzw',
        video_title: 'check with your phone provider',
        description: 'Check the caller ID device is connected to the correct phone line.'
    },
]