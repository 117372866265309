import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppContext from '../Data/AppContext'
import { useHistory } from "react-router-dom";
import useGetUsers from "../../services/useGetUsers";
export default function NewCallDialog(props) {
    const history = useHistory();
    const [open] = React.useState(true);
    const {usersList,doFetch} = useGetUsers([]);
    const glContext = useContext(AppContext);
    const handleRedirect = () => {
        console.log(props.callingNumber);
        if(props.callingNumber && props.callingNumber.length > 8){
            doFetch(props.callingNumber);
          //  if(usersList && usersList.length > 0){
                props.setNewCall(false);
                console.log(usersList);
                history.push("/neworder"); 
          //  }
        }
       
     
    };
   
    const handleClose=()=>{
        props.setNewCall(false);
        glContext.setCustomer('');
    }
    

  
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} id='NotesDialog'>
            <div className="row dialogHeader">
                <span className="col-10">New Call Received</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <div className="row">
                    <div className="col-md-12 text-center margin-bottom-10">
                    {
                       props.callingNumber
                    } 
                    </div>
                </div>
                <div className="row margin-bottom-10" >
                    <div className="col-md-12 btn-group">
                        <button className='btn btn-light btn-lg form-control'  onClick={handleRedirect} >Accept</button>                        
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
