import React from "react";
import './page.css'
import "bootstrap/dist/css/bootstrap.css"
import OrderForm from '../Forms/OrderForm'

require('dotenv').config();

export default function CreateOrders(props) {
  props.setNewCall(false);
  return (
    <div className="container-fluid background-white">
      <OrderForm newOrder={true}/>
    </div>
  );
}
