import React,{useContext} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import axios from "axios";
import AppContext from '../Data/AppContext';
import './FormDialog.css'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import useLogErrortoAPI from "../../services/useLogErrortoAPI";

export default function AddTimeDialog(props) {
    AddTimeDialog.propTypes = {
        confirmText: PropTypes.string
    };
    const glContext = useContext(AppContext);
    const {postData}=useLogErrortoAPI();
    //const confirmText = props.confirmText;
    const { setShowAddTimeDialog } = props;
    const [open] = React.useState(true);
    // const { } = useForm();
    const handleClose = () => {
        setShowAddTimeDialog(false);
    };

    const handleSave = async (time) => {
        let url = process.env.REACT_APP_UPDATE_SPECIAL+ glContext.RestaurantId+ '&id='+ glContext.ActiveOrder.id;
        try {
            const response =  await axios.patch(url, {'add_time': time}, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
                }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                postData('An error occurred. Error code #ATD-101' + err.toString() , 'AddTimeDialog.js', 'handleSave');
                throw err;
            });
    
            if(response.status===200)
            {
                var ord = glContext.ActiveOrder;
                let deliveryTime = new Date(ord.order_time);
                let newdate= new Date();
                if (ord.delivery_time != null)
                {
                    deliveryTime = new Date(ord.delivery_time);
                }
                else if (ord.delivery_time_est != null)
                {
                    deliveryTime = new Date(ord.delivery_time_est);
                }
                newdate.setTime(deliveryTime.getTime() + (time * 60 * 1000));
                var hours = newdate.getHours();
                var minutes = newdate.getMinutes();
                var seconds = newdate.getSeconds();
                if (hours < 10) {
                    hours = '0' + hours;
                  }
                  if (minutes < 10) {
                    minutes = '0' + minutes;
                  }
                  if (seconds < 10) {
                    seconds = '0' + seconds;
                  }
                  var month = deliveryTime.getMonth();
                  if (month < 10) {
                    month = '0' + (month+1);
                  }
                  var date = deliveryTime.getDate();
                  if (date < 10) {
                    date = '0' + date;
                  }
                
                time = deliveryTime.getFullYear() +  "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
                ord.delivery_time =time;
                glContext.setActiveOrder(ord);
                // TODO - why is dirty and then clean again?
                glContext.setDirty(true);
                glContext.setDirty(false);
                setShowAddTimeDialog(false);
            }else{
                NotificationManager.error('Something went wrong while trying to add to delivery time. Error code #ATDJ-001', 
                'Time update failed', 5000, () => {});
            }
        } catch (err) {
            NotificationManager.error('Something went wrong while trying to add to delivery time. Error code #ATDJ-002', 
            'Time update failed', 5000, () => {});
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Add Time</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent >
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-lg btn-light margin-bottom-10 form-control" onClick={()=>handleSave(10)}>+ 10 Minutes</button>
                        <button className="btn btn-lg btn-success margin-bottom-10 form-control" onClick={()=>handleSave(20)}>+ 20 Minutes</button>
                        <button className="btn btn-lg btn-primary margin-bottom-10 form-control" onClick={()=>handleSave(30)}>+ 30 Minutes</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-lg btn-warning margin-bottom-10 form-control " onClick={()=>handleSave(45)}>+ 45 Minutes</button>
                        <button className="btn btn-lg btn-danger  margin-bottom-10 form-control" onClick={()=>handleSave(60)}>+ 60 Minutes</button>
                    </div>
                </div>
                <p></p>
                <div className="row buttonrow">
                    <div className="col-md-12 btn-group">
                        <button className="btn btn-light btn-lg form-control" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
