import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Orders from './components/Orders/Orders';
import Bookings from './components/Bookings/Bookings';
import OrdersDetails from './components/OrderDetails/OrderDetails';
import CollectionForm from './components/Forms/CollectionForm';
import ErrorFallback from './components/Error/ErrorFallback';
//import BluetoothService from './components/BLE/BluetoothService';
import { useHistory } from "react-router-dom";
import CreateOrders from './components/Pages/CreateOrders';
import useGetOnlineOrdersOrders from './services/useGetOnlineOrders';
import Manage from './components/Pages/Manage';
import AppContext from './components/Data/AppContext';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import audio from './Sound/bellring.wav';
import bookingNotification from './Sound/booking-notification.wav';
import { React, useEffect, useState, useCallback } from 'react';
import {ErrorBoundary } from 'react-error-boundary'
//import AuthProvider from './AuthProvider';
import axios from "axios"
function App(props) {
  // Define as many global variables as your app needs, and hooks 
  // to set the state of the variable.

  var rest = null;
  var restId  = null;
  try {
    rest = localStorage.getItem('restaurant');
    rest = JSON.parse(rest);
    if (rest && rest.id) {
      restId = rest.id  
    }
  } catch (e) {
    console.log('no selected restaurant in localStorage');
  }

  var tmpUser;
  try {
    tmpUser = localStorage.getItem('user');
    tmpUser = JSON.parse(tmpUser);
  } catch (e) {
    console.log('no tmpuser ');
  }

  var isLoggedIn = false;
  if (tmpUser) {
    isLoggedIn = true;
  }
  const history = useHistory();
  const [url, setUrl]= useState("");
  const [currentScreen, setCurrentScreen] = useState('Home');
  const [loggedin, setLoggedin] = useState(isLoggedIn);
  const [user, setUser] = useState(tmpUser);
  const [restaurant, setRestaurant] = useState(rest);
  const [restaurantId, setRestaurantId] = useState(restId);
  const [numOfNewBookingToday, setNumOfNewBookingToday ] = useState(null)
  const [dirty, setDirty] = useState(false);
  const [orders, setOrders] = useState([]);
  const [bookings, setBookings] = useState([]);
  const { onlineOrdersList, doFetch } = useGetOnlineOrdersOrders(url);
  const [activeOrders, setActiveOrder] = useState({});
  const [callingCustomer, setCallingCustomer] = useState({});
  const [activeBooking, setActiveBooking] = useState({});
  const [connection, setConnection] = useState(null);
  const [newCall, setNewCall] = useState(false);

  const [info, setInfo] = useState(null);
  const [open, setOpen] = useState(false);

  const [setMeal, setSetMeal] = useState(null);
  const global = {
    currentScreenName: currentScreen,
    RestaurantId: restaurantId,
    Restaurant: restaurant,
    Orders: orders,
    Bookings: bookings,
    ActiveOrder: activeOrders,
    ActiveBooking: activeBooking,
    Dirty: dirty,
    User: user,
    Loggedin: loggedin,
    OnlineOrdersList:onlineOrdersList,
    CallingCustomer:callingCustomer,
    SetMeal:setMeal,
    setUser,
    setCurrentScreen,
    setRestaurant,
    setOrders,
    setBookings,
    setActiveOrder,
    setActiveBooking,
    setDirty,
    setRestaurantId,
    setLoggedin,
    setCallingCustomer,
    info,
    setInfo,
    open,
    setOpen,
    setSetMeal,
  };

  const refreshRestaurant = (e) => {
    // TODO - why is the if
    if(!e){
      setRestaurantId('');
      setUrl("");
      doFetch();
    }
    else{
      rest = localStorage.getItem('restaurant');      
      rest = JSON.parse(rest);
      if (rest !== null) {
        setRestaurantId(rest.id);
        setUrl(process.env.REACT_APP_ONLINE_ORDER + rest.id);
        doFetch(process.env.REACT_APP_ONLINE_ORDER + rest.id, e);
      }   
    }
    
  }

  var sound = new Audio(audio);
  function playSound() {
    sound.play();
    console.log('Playing Sound');
  }

  const refetchOnlineOrders=()=>{
    if(loggedin 
      && restaurantId 
      && (typeof restaurant === 'object' && restaurant.o_o_enabled === true)) {
      //NotificationManager.success('Finding New Orders', null, 500);
      let url = process.env.REACT_APP_ONLINE_ORDER + restaurantId;
      let r = Math.random().toString(36).substring(7);
      doFetch(url, r);
    }
  }

  const myErrorHandler = (e) => {
    alert("OOPS, something went wrong. " + e)
    window.location.href = process.env.REACT_APP_OAUTH_REDIRECT_URI;
  }
  

  useEffect(() => {
    if(loggedin && restaurantId ) {
      const connect = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_CALLERID)
        .withAutomaticReconnect()
        .build();
      setConnection(connect);
    }
  }, [loggedin, restaurantId]);

  useEffect(() => {
    if (loggedin && restaurantId && connection) {
      connection
        .start()
        .then(() => {
          connection.on("ReceiveMessage", (message) => {
            console.log("Received call from : " + message);
            getUser(message);
            setNewCall(true);
          });
        })
        .catch((error) => {
          NotificationManager.warning('Caller ID failed', null, 3000);
        });
    }
  }, [connection, loggedin, restaurantId]);

  const getUser =async (mobile)=>{
    let url =process.env.REACT_APP_SEARCH_USERS + restaurant.id+'&mobile='+mobile.trim();
    const response = await axios.get(url, {
      headers: {
          'Access-Control-Allow-Origin': true,
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
      },
    });
    if (response.status === 200) {
      var data = {};
      if (response.data.result.items.length > 0) {
       data =    {
              mobile: response.data.result.items[0].mobile.replace("+44", "0"),
              country: response.data.result.items[0].country,
              // county:  response.data.result.items[i].county,
              // door_no: response.data.result.items[i].door_no,
              // email:  response.data.resultdoor_no.items[i].email,
              // house_name: response.data.result.items[i].house_name,
              line1: response.data.result.items[0].line1,
              line2: response.data.result.items[0].line2,
              line3: response.data.result.items[0].line3,
              line4: response.data.result.items[0].line4,
              name: response.data.result.items[0].name,
              postcode: response.data.result.items[0].postcode
          }
      }else{
        data =    {
          mobile: mobile.trim(),
        }
      }

      if(data && data.mobile && data.mobile.length> 8){
        setNewCall(true);
        setCallingCustomer(data);
      }
    } else {      
      NotificationManager.error('Failed to get user data', null, 5000);
    }
  }

  useEffect(() => {
    try {
      if (rest !== null) {
        setRestaurantId(rest.id);
      }
      
      if(onlineOrdersList && onlineOrdersList.length){
        playSound();
      }

      const interval = setInterval(() => {
        refetchOnlineOrders();
      }, 60000);

      return () => clearInterval(interval);
    } catch {

    }
  }, [onlineOrdersList]);


  var notification = new Audio(bookingNotification);
  const BookingNotification = useCallback(() => {
    notification.play();
  }, [])

  const getNoOfBookings = useCallback(async () => {
    let URL = `${process.env.REACT_APP_GET_BOOKINGS_LENGTH}${restaurantId}`;
    const response = await axios.get(URL, {
      headers: {
        'Access-Control-Allow-Origin': true,
        'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
      }
    })
    const data = response.data;
    const today = new Date().toLocaleString("en-GB", {timeZone: "Europe/London"}).slice(0, 10);

    let numOfNewBooking = JSON.parse(localStorage.getItem('numOfNewBooking'));
    if (null === numOfNewBooking || !numOfNewBooking[ today ]) {
      numOfNewBooking = {};
      numOfNewBooking[ today ] = 0;
      localStorage.setItem('numOfNewBooking', JSON.stringify(numOfNewBooking));
    }

    if(data.success && data.result) {
      if (Number(data.result) > Number(numOfNewBooking[ today ])){
        numOfNewBooking[ today ] = Number(data.result);
        localStorage.setItem('numOfNewBooking', JSON.stringify(numOfNewBooking));
        BookingNotification();
        setNumOfNewBookingToday(numOfNewBooking[ today ]);
      }else{
        console.log('No Changes in Booking number')
      }
    }

  }, [BookingNotification, restaurantId])
  
  
  useEffect(()=>{
    const interval = setInterval(() => {
      getNoOfBookings();
    }, 120000);

    return () => clearInterval(interval);

  },[getNoOfBookings]);


  return (
    <AppContext.Provider value={global}>
      <div className="App">
        <>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
          <NotificationContainer />
          <Router history={history}>
            <Navbar restId={restaurantId} newCall={newCall} numOfNewBookingToday={numOfNewBookingToday}  loggedin={global.Loggedin} />
            <div className="main-container">
              <Route path='/' exact render={() => <Orders onlineOrdersList={onlineOrdersList} refetchOnlineOrders={refetchOnlineOrders}  txt='1123' restId={restaurantId} />} />
              <Route path='/Home' render={() => <Orders onlineOrdersList={onlineOrdersList} refetchOnlineOrders={refetchOnlineOrders}  txt='1123' restId={restaurantId} />} />
              <Route path='/neworder' render={() => <CreateOrders newOrder={true} setNewCall={setNewCall} />} />
              <Route path='/createOrders' render={() => <CreateOrders newOrder={true} setNewCall={setNewCall} />} />
              <Route path='/collection' component={CollectionForm} />
              <Route path='/bookings' render={() => <Bookings restId={restaurantId} numOfNewBookingToday={numOfNewBookingToday} />} />              
              <Route path='/manage' render={() => <Manage refreshRestaurant={refreshRestaurant} restId={restaurantId} />} />
              <Route path='/orderDetails' render={() => <OrdersDetails dirty={dirty} />} />
            </div>
          </Router>
          </ErrorBoundary>
        </>
      </div>
    </AppContext.Provider>
  );  
}

export default App;
