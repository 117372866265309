import { useState,useEffect,useContext } from "react";
import logger from "../lib/util";
import AppContext from '../components/Data/AppContext';
import useLogErrortoAPI from "./useLogErrortoAPI";
import axios from "axios"
require('dotenv').config();

const useGetBookings = (initialUrl)=>{
const [loading,setLoading]=useState(true);
const [bookingsList,setBookings]=useState({});
const [bookingsUrl,setBookingsUrl]= useState(initialUrl);
const [random,setRandom]= useState(0);
const {postData}=useLogErrortoAPI();
const glContext = useContext(AppContext);
    
 useEffect(()=>{
      const fetchData = async()=> {
          try {
            setLoading(true);
            logger.info('Set loading true');
            logger.info('Calling get function');
            const response = await axios.get(bookingsUrl,  {
               headers: {
                  'Access-Control-Allow-Origin': true,
                  'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
               },
               });
              if(response.status===200)
              {
                 logger.info('response 200');
                 logger.info(response.data.result.items);
                 setBookings(response.data.result.items);
                 if(glContext){
                  glContext.setBookings(response.data.result.items);
                 }
                 
              }else{
               postData(Response, 'useGetBookings', 'GetBookings');
                logger.info(response.status);
                setBookings([]);
              }
          } catch (error) {
            postData(error, 'useGetBookings', 'GetBookings');
            logger.error(`Error: ${error}`);
            setBookings([]);
          }
          finally{
              setLoading(false);
          }
      }
      if(bookingsUrl.length>0){
         fetchData();
      }else{
         setBookings([]);
      }
      
   
 },[random]);
 const doFetch=(url,r)=>{
    if(url){
      setBookingsUrl(url);
      setRandom(r);
    }else{
      setBookings([]);
    }
    
 }
 return {loading, bookingsList, doFetch};
};
export default useGetBookings;