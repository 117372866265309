import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

const ClosedDayDatePiker = ({ closedDayRange, finalData,  setClosedDayRange, setMessage }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // inject finalData to closedDayRange 
    useEffect(() =>{
      const existingRange = [];
      if(finalData.length){
        finalData.forEach((item, i) =>{
          existingRange.push({id: `closed_day_${i + 1}`, start: item.start, end: item.end, isClose: true})
        })
      }
      setClosedDayRange(existingRange);
    }, [finalData, setClosedDayRange])

    const handleDate = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      
      handleDateFormat(start, end);
    };

    function handleDateFormat(start, end) {
      if(start && end) {
        let startYear = start.getFullYear();
        let startMonth = start.getMonth() + 1;
        let startDay = start.getDate();
        if (startMonth.toString().length < 2){
          startMonth = '0' + startMonth;
        }
        if (startDay.toString().length < 2){
          startDay = '0' + startDay;
        }
        // get start date
        let startDate = startYear + '-' + startMonth + '-' + startDay;
  
        let EndYear = end.getFullYear();
        let EndMonth = end.getMonth() + 1;
        let EndDay = end.getDate();
        if (EndMonth.toString().length < 2){
          EndMonth = '0' + EndMonth;
        }
        if (EndDay.toString().length < 2){
          EndDay = '0' + EndDay;
        }
        // get end date
        let endDate = EndYear + '-' + EndMonth + '-' + EndDay;

        // check if start date is before end date
        const allDates = [];
        closedDayRange.forEach(date => {
          // get all dates in range
          const start = new Date(date.start);
          const end = new Date(date.end);
          const dates = [];
          let currentDate = start;
          while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
          }
          allDates.push(...dates);
        })

        // check if date is in range
        const isExistingRange = allDates.some(date => {
          return date >= start && date <= end;
        });

        // set data if not in range
        if(!isExistingRange) {
          setClosedDayRange([...closedDayRange, {id: `closed_day_${closedDayRange.length + 1}`, start: startDate, end: endDate, isClose: true}]);
          setMessage('');
        }else{
          setStartDate(null);
          setEndDate(null);
          setMessage('Invalid date range');
        }

        // clear date
        setStartDate(null);
        setEndDate(null);
      }
    }

    const excludedDates = [];
    closedDayRange.forEach(date => {
      // get all dates between start and end
      const start = new Date(date.start);
      const end = new Date(date.end);
      const dates = [];
      let currentDate = start;
      while (currentDate <= end) {
        dates.push(new Date(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
      excludedDates.push(...dates);
    })

    return (
      <DatePicker
        className="form-control"
        minDate={new Date()} 
        dateFormat="yyyy/MM/dd"
        startDate={startDate}
        endDate={endDate}
        onChange={handleDate}
        selectsRange
        placeholderText="Tap to select a range"
        withPortal
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={startDate}
        excludeDates={excludedDates}
      />
    )
}

export default React.memo(ClosedDayDatePiker);
