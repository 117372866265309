import { useContext } from "react";
import AppContext from '../components/Data/AppContext'
const useHelperService = (ordertype) => {
    const glContext = useContext(AppContext);

    function stringtoDate(t_sdate) {
        const s = '01-01-1901 ' +t_sdate+ ':00';
        const d = new Date(s);
        return d;
    }

    var ooStartTime = stringtoDate(glContext.Restaurant.o_o_start_time);
    var ooEndTime = stringtoDate(glContext.Restaurant.o_o_end_time);
    var leadtime = glContext.Restaurant.o_o_delivery_lead_time;
    if(ordertype === 'collection'){
        leadtime = glContext.Restaurant.o_o_collection_lead_time;
    }
    if(ordertype === 'waiting'){
        leadtime = glContext.Restaurant.o_o_waiting_lead_time;
    }
    if(ordertype === 'booking'){
        leadtime = 30;
    }
    
    var now = new Date();
    var from = new Date('01-01-1901 00:01:00');
    var newStartDateTime = new Date('01-01-1901 ' + now.getHours() + ':' + now.getMinutes() + ':00');
    if(ordertype === 'booking'){
        newStartDateTime = new Date('01-01-1901 ' + glContext.Restaurant.o_o_start_time + ':00');
    }

    if (ordertype === 'booking'){
        from.setTime(newStartDateTime.getTime());
    }else if ((ooStartTime.getHours() > newStartDateTime.getHours())
        || (ooStartTime.getHours() === newStartDateTime.getHours() 
            && ooStartTime.getMinutes() > newStartDateTime.getMinutes())) {
        // when o_o_start_time is later in the day
        from.setTime(ooStartTime.getTime() + (leadtime * 60 * 1000));
    } else {
        // when the restaurant is already open 
        from.setTime(newStartDateTime.getTime() + (leadtime * 60 * 1000));
    }

    // setting from time to the next 15th minute
    if (from.getMinutes() < 15) {
        let tmp = new Date('01-01-1901 ' + from.getHours() + ':15:00');
        from.setTime(tmp.getTime());
    } 
    if (from.getMinutes() > 15 && from.getMinutes() < 30) {
        let tmp = new Date('01-01-1901 ' + from.getHours() + ':30:00');
        from.setTime(tmp.getTime());
    }
    if (from.getMinutes() > 30 && from.getMinutes() < 45) {
        let tmp = new Date('01-01-1901 ' + from.getHours() + ':45:00');
        from.setTime(tmp.getTime());
    }
    if (from.getMinutes() > 45 && from.getMinutes() <=59 ) {
        let nextHour = from.getHours()+ 1;
        let tmp = new Date('01-01-1901 ' + nextHour + ':00:00');
        from.setTime(tmp.getTime());
    }
      
    var optionsist =[];
    let interval = 15;
    while(from < ooEndTime){ 
        /// this loop is to create list of times with 15 minutes interval
        var hours= from.getHours();
        var minutes= from.getMinutes();
        if(hours <10){
            hours='0'+hours;
        }
        if(minutes <10){
            minutes= '0'+ minutes;
        }
        var obj = {
            label: hours+':'+minutes,
            value: hours+':'+minutes
        }
        optionsist.push(obj);
        from.setTime(from.getTime() + (interval * 60 * 1000));
    }  

    return optionsist;
};
export default useHelperService;