import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../Data/AppContext';
//import 'linqjs'; 

const PrintBody = (props) => {
    const glContext = useContext(AppContext);
    const currency_symbol = glContext.ActiveOrder.currency_symbol;
    let deliveryTime = new Date(glContext.ActiveOrder.order_time);

    const restaurant = glContext.Restaurant;
    const [sortedItems, setSortedItems] = useState([]);
    const onlineOrder = (glContext.ActiveOrder.origin === 'online');

    var lmenu = restaurant.menu;
    if (glContext.ActiveOrder.order_type === 'eat-in'
        && restaurant.menu_eatin.categories
        && restaurant.menu_eatin.categories.items
        && Object.keys(restaurant.menu_eatin.categories.items).length > 0) {
        lmenu = restaurant.menu_eatin;
    }

    useEffect(() => {
        var printitems = glContext.ActiveOrder.order_lines.items;
        for (var key in printitems) {
            // check if the property/key is defined in the object itself, not in parent
            if (printitems.hasOwnProperty(key)) {
                if (lmenu.categories.items[printitems[key].category_id]) {
                    printitems[key].priority = lmenu.categories.items[printitems[key].category_id].priority;
                }
            }
        }
        Object.entries(printitems).sort(dynamicSort("priority"));
        var orderedByName = Object.entries(printitems).sort(function (a, b) {
            return a[1].priority - b[1].priority;// ? 1 : -1;
        });
        Object.entries(printitems).forEach(([key, value]) => {
            let noteIsObj = false;
            value.isSetMeals = false;
            value.items = [];
            if (value.item_sub_type === "set_meal") {
                try {
                    noteIsObj = JSON.parse(value.note);
                } catch (error) {}

                if (noteIsObj && noteIsObj !== false) {
                    value.isSetMeals = true;
                    Object.entries(noteIsObj).forEach(([key, v]) => {
                        value.items.push(v);
                    });
                }
            }

        });
        setSortedItems(orderedByName);
    }, [glContext.ActiveOrder.order_lines.items]);

    if (glContext.ActiveOrder.delivery_time !== null) {
        deliveryTime = new Date(glContext.ActiveOrder.delivery_time);
    }
    else if (glContext.ActiveOrder.delivery_time_est !== null) {
        deliveryTime = new Date(glContext.ActiveOrder.delivery_time_est);
    }
    let deliveryTimeStr = deliveryTime.toLocaleString([], {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });


    let ps = glContext.ActiveOrder.payment_status;
    var paymentString = '';
    if (onlineOrder) {
        if (ps === 'submitted_for_settlement' || ps === 'settling') {
            paymentString = 'PAID - Online';
        } else {
            paymentString = 'Unpaid';
        }
    } else if (ps === null || ps === 'unpaid') {
        if (glContext.ActiveOrder.order_type === 'eat-in') {
            paymentString = ''; // Unpaid , but print nothing
        } else {
            paymentString = 'UNPAID'; // Unpaid , but print nothing
        }
    } else if (ps === 'paid') {
        // ps === 'paid
        paymentString = 'PAID - ' + glContext.ActiveOrder.payment_method
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const parseNote = (notes, order) => {
        let modifiedNotes = notes;
        let noteIsObj = false;
        try {
            noteIsObj = JSON.parse(notes);
        } catch (error) { }

        if (noteIsObj !== false) {
            let noteString = '';
            for (const group in noteIsObj) {
                let items = noteIsObj[group];
                items.forEach(item => noteString
                    += '-' + item['name']
                    + (item['extra_price'] > 0 ? order.currency_symbol + parseFloat(item['extra_price']).toFixed(2) : ''));
            }
            modifiedNotes = noteString;
        }
        return modifiedNotes;
    };

    return (

        <div id="printContent">
            <p>
                <span style={{ fontSize: 20, fontWeight: 'bold' }}>{glContext.Restaurant.name}</span><br />
                <span style={{ fontSize: 14 }}>{glContext.Restaurant.door_no > 0 ? glContext.Restaurant.door_no : ''} {glContext.Restaurant.road}, {glContext.Restaurant.postcode}</span><br />                
                <span style={{ fontSize: 12 }}>#{glContext.ActiveOrder.id} ({glContext.ActiveOrder.origin}) {glContext.ActiveOrder.order_time}</span><br />
                {(glContext.Restaurant.VAT_number && glContext.Restaurant.VAT_number.length > 0) && <span style={{ fontSize: 12 }}>VAT Number: {glContext.Restaurant.VAT_number}<br /></span>}
                <span style={{ fontSize: 14, textAlign: 'center' }}>- - - - - - - - - - - - - - - - - - - - - - - - - - - -</span>
            </p>
            <table style={{ tableLayout: 'auto', borderCollapse: 'separate', borderSpacing: '0 .5em', width: '250px', borderWidth: '0px' }}>
                <tbody style={{ fontSize: 18 }}>
                    {
                        sortedItems
                            .map(([key, value]) => {
                                return (   value.isSetMeals &&  
                                    <tr key={key} style={{}}>
                                    <td style={{ verticalAlign: 'top', width: '10%' }}>{value.quantity}x</td>
                                    <td style={{ verticalAlign: 'top', width: '70%' }} width="70%">
                                        <span>{value.name}{(!onlineOrder && value.item_sub_type !== 'set_meal' ? ' (' + value.item_sub_type + ')' : '')}</span><br />
                                        <span style={{ fontSize: 14, fontStyle: 'italic', textAlign: 'left' }}>
                                        {value.items.map((e)=> 
                                         
                                          <div style={{ verticalAlign: 'left' }}>{e.map(y=> 
                                           <div>- {y.name} {y.extra_price ? '(' + currency_symbol + parseFloat(y.extra_price).toFixed(2) + ')' : ''}</div>
                                             )} </div>
                                          
                                        )}
                                        </span>
                                    </td>
                                    <td style={{ fontSize: 14, verticalAlign: 'top', width: '20%', textAlign: 'right' }}>{currency_symbol}{value.amount}</td>
                                </tr>)
                            }
                            )

                    }

                    {
                        sortedItems
                            .map(([key, value]) =>
                                !value.isSetMeals && <tr key={key} style={{}}>
                                    <td style={{ verticalAlign: 'top', width: '10%' }}>{value.quantity}x</td>
                                    <td style={{ verticalAlign: 'top', width: '70%' }} width="70%">
                                        <span>{value.name}{(!onlineOrder && value.item_sub_type ? ' (' + value.item_sub_type + ')' : '')}</span><br />
                                        <span style={{ fontSize: 14, fontStyle: 'italic', textAlign: 'center' }}>{parseNote(value.note, glContext.ActiveOrder)}</span>
                                    </td>
                                    <td style={{ fontSize: 14, verticalAlign: 'top', width: '20%', textAlign: 'right' }}>{currency_symbol}{value.amount}</td>
                                </tr>
                            )

                    }
                </tbody>
                <tfoot><tr><td colSpan="3" style={{ verticalAlign: 'top', textAlign: 'right' }}>- - - - - - - - - - - - - - - - - -</td></tr></tfoot>
            </table>
            <table style={{ fontSize: 14, tableLayout: 'auto', borderCollapse: 'collapse', width: '250px', borderWidth: '0px' }}>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Subtotal</td>
                        <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.subtotal_amount}</td>
                    </tr>
                    {glContext.ActiveOrder.total_discount > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Discount</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.total_discount}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.ch_loyaly_discount > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Loyalty Discounts</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.ch_loyaly_discount}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.service_charge > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Service Charge</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.service_charge}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.delivery_cost > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Delivery Charge</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.delivery_cost}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.drinks_charge > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Drinks</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.drinks_charge}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.dessert_charge > 0 ?
                        <tr className="printP2">
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right' }}>Dessert</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right' }}>{currency_symbol}{glContext.ActiveOrder.dessert_charge}</td>
                        </tr> : ''}
                    {glContext.ActiveOrder.total_amount > 0 ?
                        <tr>
                            <td style={{ verticalAlign: 'top', width: '70%', textAlign: 'right', fontSize: 18, fontWeight: 'bold' }}>Total</td>
                            <td style={{ verticalAlign: 'top', width: '30%', textAlign: 'right', fontSize: 18, fontWeight: 'bold' }}>{currency_symbol}{glContext.ActiveOrder.total_amount}</td>
                        </tr> : ''}
                    {paymentString.length > 1 &&
                        <tr>
                            <td colSpan="3" style={{ textAlign: 'right', fontFamily: 'Courier New', fontSize: 26, fontWeight: 'bold' }}>
                                {paymentString}
                            </td>
                        </tr>}
                </tbody>
                <tfoot><tr><td colSpan="3" style={{ fontSize: 14, textAlign: 'right' }}><span>- - - - - - - - - - - - - - - - - - - - - - - - - -</span></td></tr></tfoot>
            </table>
            <table style={{ tableLayout: 'auto', borderCollapse: 'collapse', width: '250px', borderWidth: '0px' }}>
                <tbody>
                    {glContext.ActiveOrder.instructions.length > 0 &&
                        <tr><td colSpan="2" style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <h4>{glContext.ActiveOrder.instructions}</h4>
                            <span>- - - - - - - - * * * * *- - - - - - - - - -</span>
                        </td></tr>}
                    <tr>
                        <td width="100%" style={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'left' }}>
                            <h3>{glContext.ActiveOrder.order_type}</h3>
                        </td>
                    </tr>
                    <tr>
                        <td width="70%" style={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'left' }}>
                            <h4>{deliveryTimeStr}</h4>
                        </td>
                    </tr>
                    {glContext.ActiveOrder.order_type === 'eat-in' &&
                        <tr>
                            <td colSpan="2"><h4>Table-{glContext.ActiveOrder.inhouse_table},  Guests-{glContext.ActiveOrder.inhouse_guests}</h4></td>
                        </tr>}

                    {glContext.ActiveOrder.order_type === 'waiting' &&
                        <tr>
                            <td colSpan="2">{glContext.ActiveOrder.name}</td>
                        </tr>}

                    {glContext.ActiveOrder.order_type === 'collection' &&
                        <tr>
                            <td colSpan="2">{glContext.ActiveOrder.name}</td>
                        </tr>}
                    {glContext.ActiveOrder.order_type === 'collection' &&
                        <tr>
                            <td colSpan="2">{glContext.ActiveOrder.mobile}</td>
                        </tr>}                    
                    {glContext.ActiveOrder.order_type === 'delivery' && 
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.name}</td>
                    </tr>}
                    {glContext.ActiveOrder.order_type === 'delivery' && 
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.mobile}</td>
                    </tr>}
                    {(glContext.ActiveOrder.order_type === 'delivery' && glContext.ActiveOrder.line1.length > 0) &&   
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.line1}</td>
                    </tr>}
                    {(glContext.ActiveOrder.order_type === 'delivery' && glContext.ActiveOrder.line2.length > 0) &&  
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.line2}</td>
                    </tr>}
                    {(glContext.ActiveOrder.order_type === 'delivery' && glContext.ActiveOrder.line3.length > 0) && 
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.line3}</td>
                    </tr>}
                    {(glContext.ActiveOrder.order_type === 'delivery' && glContext.ActiveOrder.line4.length > 0) &&
                    <tr>
                        <td colSpan="2">{glContext.ActiveOrder.line4}</td>
                    </tr>}
                    {(glContext.ActiveOrder.order_type === 'delivery' && glContext.ActiveOrder.postcode.length > 0) &&  
                    <tr>
                        <td colSpan="2"><span style={{textTransform: 'uppercase', fontWeight: 'bold', fontSize: 16}}>{glContext.ActiveOrder.postcode}</span></td>
                    </tr>}
            </tbody>
        </table>
        </div>
    );
}

export default PrintBody;