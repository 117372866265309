import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css"
import AppContext from '../Data/AppContext'
import axios from "axios";
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
import { FaClock, FaShuttleVan, FaPercentage } from 'react-icons/fa';
import { MdPrint } from 'react-icons/md';
import { CgSandClock } from 'react-icons/cg';
import DiscountSettingDialog from  '../Dialog/DiscountSettingDialog';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ConfirmTimeSettingsDialog from '../Dialog/ConfirmTimeSettingsDialog';
import ClosedDays from "./ClosedDays/ClosedDays";
import ServiceCharge from "./ServiceCharge/ServiceCharge";
import { MdBorderColor } from 'react-icons/md';
import { Button } from '@material-ui/core';
require('dotenv').config();

const Settings = () => {
    const glContext = useContext(AppContext);
    
    const {  Restaurant: { currency_symbol, service_charges } } = glContext;
    const { o_o_closed_days } = glContext.Restaurant;
    const {postData}=useLogErrortoAPI();
    //var reg = /^-?\d+\.?\d*$/;
    const [allDiscount,setAllDiscount]=useState(glContext.Restaurant.o_o_discount);
    const [collectionDiscount,setCollectionDiscount]=useState(glContext.Restaurant.o_o_collection_discount);
    const [cashCollectionDiscount,setCashCollectionDiscount]=useState(glContext.Restaurant.o_o_cash_collection_discount);
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const [showConfirmDialog, setShowConfirmDialog]=useState(false);
    const [json,setJson]=useState({});
    const [confirmText,SetConfirmText] =useState("");
    const[ioPrintTimesActive1,setIoPrintTimesActive1]= useState(false);
    const[ioPrintTimesActive2,setIoPrintTimesActive2]= useState(false);
    const[ioPrintTimesActive3,setIoPrintTimesActive3]= useState(false);
    const[del30Active,setDel30Active]= useState(false);
    const[del45Active,setDel45Active]= useState(false);
    const[del60Active,setDel60Active]= useState(false);
    const[del70Active,setDel70Active]= useState(false);
    const[del80Active,setDel80Active]= useState(false);
    const[del90Active,setDel90Active]= useState(false);
    const[del100Active,setDel100Active]= useState(false);
    const[del110Active,setDel110Active]= useState(false);
    const[del120Active,setDel120Active]= useState(false);
    const[col10Active,setCol10Active]= useState(false);
    const[col20Active,setCol20Active]= useState(false);
    const[col30Active,setCol30Active]= useState(false);
    const[col40Active,setCol40Active]= useState(false);
    const[col50Active,setCol50Active]= useState(false);
    const[col60Active,setCol60Active]= useState(false);
    const[wait10Active,setWait10Active]= useState(false);
    const[wait20Active,setWait20Active]= useState(false);
    const[wait30Active,setWait30Active]= useState(false);
    const[wait40Active,setWait40Active]= useState(false);
    const[wait50Active,setWait50Active]= useState(false);
    const[wait60Active,setWait60Active]= useState(false);
    const [loading, setLoading] = React.useState(false);
  
    useEffect(()=>{
       setDeliverytime(glContext.Restaurant.o_o_delivery_lead_time);
       setCollectiontime(glContext.Restaurant.o_o_collection_lead_time);
       setWaitingtime(glContext.Restaurant.o_o_waiting_lead_time);
       setIoPrintTimes(glContext.Restaurant.i_o_print_times);
    })
  
    
    const handleDeliverytimeClick=(time)=>{
        setJson({"o_o_delivery_lead_time" : time });
        SetConfirmText("Please confirm " + time + " mins as the Delivery lead time");
        setShowConfirmDialog(true); 
    }

    function setDeliverytime(time){
        setDel30Active(false);
        setDel45Active(false);
        setDel60Active(false);
        setDel70Active(false);
        setDel80Active(false);
        setDel90Active(false);
        setDel100Active(false);
        setDel110Active(false);
        setDel120Active(false);
        if(time==='30'){
            setDel30Active(true);
        }
        if(time==='45'){
            setDel45Active(true);
        }
        if(time==='60'){
            setDel60Active(true);
        }
        if(time==='70'){
            setDel70Active(true);
        }
        if(time==='80'){
            setDel80Active(true);
        }
        if(time==='90'){
            setDel90Active(true);
        }
        if(time==='100'){
            setDel100Active(true);
        }
        if(time==='110'){
            setDel110Active(true);
        }
        if(time==='120'){
            setDel120Active(true);
        }
       
    }

    function setCollectiontime(time){
        setCol10Active(false);
        setCol20Active(false);
        setCol30Active(false);
        setCol40Active(false);
        setCol50Active(false);
        setCol60Active(false);
        if(time==='10'){
            setCol10Active(true);
        }
        if(time==='20'){
            setCol20Active(true);
        }
        if(time==='30'){
            setCol30Active(true);
        }
        if(time==='40'){
            setCol40Active(true);
        }
        if(time==='50'){
            setCol50Active(true);
        }
        if(time==='60'){
            setCol60Active(true);
        }
    }

    function setIoPrintTimes(times){
        setIoPrintTimesActive1(times === 1);
        setIoPrintTimesActive2(times === 2);
        setIoPrintTimesActive3(times === 3);
    }

    function setWaitingtime(time){
        setWait10Active(false);
        setWait20Active(false);
        setWait30Active(false);
        setWait40Active(false);
        setWait50Active(false);
        setWait60Active(false);
        if(time==='10'){
            setWait10Active(true);
         }
        if(time==='20'){
            setWait20Active(true);
         }
         if(time==='30'){
            setWait30Active(true);
          }
          if(time==='40'){
            setWait40Active(true);
          }
          if(time==='50'){
            setWait50Active(true);
          }
          if(time==='60'){
            setWait60Active(true);
          }
         
     }

    const discounts = {
        AllDiscount:allDiscount,
        CollectionDiscount:collectionDiscount,
        CashCollectionDiscount:cashCollectionDiscount,
        DiscountType:discountType,
        setAllDiscount,
        setCollectionDiscount,
        setCashCollectionDiscount,
        setShowDiscountDialog,
    }

    const handleCollectiontimeClick=(time)=>{
        setJson({"o_o_collection_lead_time" : time });
       SetConfirmText("Please confirm " + time + " mins as the Collection lead time");
        setShowConfirmDialog(true);
    }

    const handleWaitingtimeClick=(time)=>{
        setJson({"o_o_waiting_lead_time" : time });
        SetConfirmText("Please confirm " + time + " mins as the Waiting lead time");
        setShowConfirmDialog(true);
    }

    const handleIOPrintTimesClick=(times)=>{
        setJson({"i_o_print_times" : times });
        SetConfirmText("Please confirm you want to print " + times + " every time you press print.");
        setShowConfirmDialog(true);
    }
    
    const handleEditableItemClick = (type) => {
        setDiscountType(type);
        setShowDiscountDialog(true);
    };  

    const  savetoAPI= async ()=>{
        let url = process.env.REACT_APP_UPDATE_RESTAURANT+ glContext.RestaurantId;
        setLoading(true);
        try {
            const response =  await axios.patch(url,json,{
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });
    
            if(response.status===200)
            {
                NotificationManager.success('Saved Successfully');
                localStorage.removeItem("restaurant");
                localStorage.setItem('restaurant', JSON.stringify(response.data.result));
                glContext.setRestaurant(response.data.result);
                setShowConfirmDialog(false);
            }else{
                NotificationManager.error('Failed to save setting');
            }
        } catch (err) {
            // TODO - send to api error log
            NotificationManager.error('Failed to save setting');
            postData(err.toString() + 'An error occurred. Error code #SJ-101', 'Settings', 'savetoAPI');
        }
        setLoading(false);
    }

    return (
        <div className="col-md-12 text-center pt-2 pb-3">
            <NotificationContainer />
            {glContext.Restaurant && <h3 className="text-left">{glContext.Restaurant.name} Settings<hr/></h3>}
            {showConfirmDialog && <ConfirmTimeSettingsDialog loading={loading} confirmText={confirmText} showConfirmDialog={showConfirmDialog} savetoAPI={savetoAPI} setShowConfirmDialog={setShowConfirmDialog} />}  
            {showDiscountDialog && <DiscountSettingDialog discounts ={discounts} setShowDiscountDialog={setShowDiscountDialog} />}

            <div className="margin-bottom-40">
                <h3>Lead Times</h3>
                <div className="row">
                     <div className="col-4 border">
                        <h5 className='d-flex align-items-center justify-content-center mb-3 mt-2'><FaShuttleVan fontSize="18px" className="mr-2" /> Delivery Lead Time</h5>
                        <hr />

                        <div className={del30Active ? 'btn btn-lg btn-primary settingsBtn m-1 ': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(30)} >30 Mins</div>
                        <div className={del45Active ? 'btn btn-lg btn-primary settingsBtn m-1 ': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(45)} >45 Mins</div>
                        <div className={del60Active ? 'btn btn-lg btn-primary settingsBtn m-1 ': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleDeliverytimeClick(60)}  >1 Hour</div>
                        <div className={del70Active ? 'btn btn-lg btn-primary settingsBtn m-1 ': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(70)}  >1 Hr 10 Mins</div>
                        <div className={del80Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(80)}  >1 Hr 20 Mins</div>
                        <div className={del90Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(90)}  >1 Hr 30 Mins</div>
                        <div className={del100Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleDeliverytimeClick(100)} >1 Hr 40 Mins</div>
                        <div className={del110Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleDeliverytimeClick(110)} >1 Hr 50 Mins</div>
                        <div className={del120Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleDeliverytimeClick(120)} >2 Hours</div>
                    </div>
                    <div className="col-4 border">
                        <h5 className='d-flex align-items-center justify-content-center mb-3 mt-2'><CgSandClock fontSize="18px" className="mr-2" /> Collection Lead Time</h5>
                        <hr />
                        
                        <button className={col10Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleCollectiontimeClick(10)} >10 Mins</button>
                        <button className={col20Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleCollectiontimeClick(20)} >20 Mins</button>
                        <button className={col30Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleCollectiontimeClick(30)} >30 Mins</button>
                        <button className={col40Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleCollectiontimeClick(40)}  >40 Mins</button>
                        <button className={col50Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleCollectiontimeClick(50)} >50 Mins</button>
                        <button className={col60Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleCollectiontimeClick(60)} >1 Hour</button>
                    </div>
                    <div className="col-4 border">
                        <h5 className='d-flex align-items-center justify-content-center mb-3 mt-2'><FaClock fontSize="18px" className="mr-2" /> Waiting Lead Time</h5>
                        <hr />
                    
                        <button className={wait10Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleWaitingtimeClick(10)} >10 Mins</button>
                        <button className={wait20Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}onClick={e=>handleWaitingtimeClick(20)} >20 Mins</button>
                        <button className={wait30Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleWaitingtimeClick(30)} >30 Mins</button>
                        <button className={wait40Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleWaitingtimeClick(40)} >40 Mins</button>
                        <button className={wait50Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleWaitingtimeClick(50)} >50 Mins</button>
                        <button className={wait60Active ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'} onClick={e=>handleWaitingtimeClick(60)} >1 Hour</button>
                    </div>
                </div>
            </div>

          {o_o_closed_days &&  <ClosedDays />} 

          {service_charges && <ServiceCharge />}  

            <div className="margin-bottom-40">
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <h3>Discounts</h3>
                </div>
                <div className="row">
                    <div className="col-4 border">
                        <DiscountSection handleEditableItemClick={handleEditableItemClick} showData={allDiscount} title={'All Order Discount'} clickEvent={'all'} />
                    </div>
                    <div className="col-4 border">
                        <DiscountSection handleEditableItemClick={handleEditableItemClick} showData={collectionDiscount} title={'Collection Discount'} clickEvent={'collection'} />
                    </div>
                    <div className="col-4 border">
                        <DiscountSection handleEditableItemClick={handleEditableItemClick} showData={cashCollectionDiscount} title={'Cash + Collection Discount'} clickEvent={'cashcollection'} />
                    </div>
                </div>
            </div>
            
            <div className="margin-bottom-40">
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <h3>Number of print copies</h3>
                </div>
                <div className="row">                    
                    <div className="col-12 border">
                        <button className={ioPrintTimesActive1 ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleIOPrintTimesClick(1)} >1 Copy</button>
                        <button className={ioPrintTimesActive2 ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleIOPrintTimesClick(2)} >2 Copies</button>
                        <button className={ioPrintTimesActive3 ? 'btn btn-lg btn-primary settingsBtn m-1': 'btn btn-lg btn-light settingsBtn m-1'}  onClick={e=>handleIOPrintTimesClick(3)} >3 Copies</button>
                    </div>
                </div>
            </div>
                    
        </div>
    );
}

const DiscountSection = ({handleEditableItemClick, showData, title, clickEvent}) => {
    return(
        <div className="col-12 ">
            <p><span className='font-weight-bold'>{title}:</span> {showData}% 
                <Button variant="outlined" className="ml-2 mt-3 font-weight-bold text-center" onClick={() => {handleEditableItemClick(`${clickEvent}`)}}>
                    <MdBorderColor fontSize="25px"  className='ml-2' />
                </Button>
            </p>
        </div>
    )
}

export default React.memo(Settings);