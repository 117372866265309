import React, { useContext } from "react";
import { Tab, Col, Nav,  } from 'react-bootstrap'
import './page.css'
import RefreshButton from "../Manage/RefreshButton";
import LogoutButton from "../Manage/LogoutButton";
import Settings from "../Manage/Settings";
import Instructions from "../Manage/Instructions";
import ManageAuth from "../Manage/ManageAuth";
//import BluetoothService from "../BLE/BluetoothService";
import AppContext from '../Data/AppContext';
require('dotenv').config();

// export default function Manage(props) {
//   const [showSettings, setShowSettings] = useState(false);
//   const [showManageAuth, setShowManageAuth] = useState(true);
//   const [showInstructions, setShowInstructions] = useState(false);
//   const glContext = useContext(AppContext);

//   const showSettingsPage = () => {
//     setShowSettings(true);
//     setShowInstructions(false);
//     setShowManageAuth(false);
//   }

//   const showManageAuthPage = () => {
//     setShowSettings(false);
//     setShowInstructions(false);
//     setShowManageAuth(true);
//   }

//   const showInstructionsPage = () => {
//     setShowSettings(false);
//     setShowInstructions(true);
//     setShowManageAuth(false);
//   }
//   return (
//     <div className="row background-white">
//       <div className="col-md-12 btn-group margin-bottom-10">
//         <button className={"btn form-control " + (showManageAuth ? 'btn-dark' : 'btn-light')} onClick={showManageAuthPage} >User</button>
//         <button className={"btn form-control " + (showInstructions ? 'btn-dark' : 'btn-light')} onClick={showInstructionsPage} >Help</button>
//         {(glContext.Loggedin && glContext.Restaurant && glContext.RestaurantId) && 
//         <button className={"btn form-control " + (showSettings ? 'btn-dark' : 'btn-light')} onClick={showSettingsPage} >Settings</button>} 
//       </div>
//       <div className="col-md-12">
//       {(showSettings && glContext.Loggedin && glContext.Restaurant) &&
//           <Settings/>
//       }
//       {showManageAuth  &&
//         <div className="col-md-12">
//           <ManageAuth refreshRestaurant={props.refreshRestaurant} />
//         </div>
//       }
//       {(glContext.Loggedin && showManageAuth) && 
//         <div className="col-md-12 text-center">
//           <div className="margin-bottom-10">
//             <LogoutButton refreshRestaurant={props.refreshRestaurant}/>
//             <RefreshButton/>
//             <AddToHomeScreenButton/>
//           </div>
//         </div>
//       }
//       {showInstructions &&
//         <div className="col-md-12">
//          <Instructions/>
//         </div>
//       }
//       </div>
//     </div>
//   );
// }

export default function Manage(props) {
  const glContext = useContext(AppContext);

  return (
      <Tab.Container id="users-setting-tab" defaultActiveKey="user">
        <Nav variant="pills" className="flex text-center">
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <Nav.Item>
              <Nav.Link eventKey="user" style={{borderRadius: "0"}}>User</Nav.Link>
            </Nav.Item>
          </Col>
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <Nav.Item>
              <Nav.Link eventKey="help" style={{borderRadius: "0"}}>Help</Nav.Link>
            </Nav.Item>
          </Col>

          {(glContext.Loggedin && glContext.Restaurant && glContext.RestaurantId) && 
            <Col sm={4} style={{ margin: 0, padding: 0 }}>
              <Nav.Item>
                <Nav.Link eventKey="settings" style={{borderRadius: "0"}}>Settings</Nav.Link>
              </Nav.Item>
            </Col>
          }
        </Nav>
        
        <Tab.Content>
          <Tab.Pane eventKey="user">
            <ManageAuth refreshRestaurant={props.refreshRestaurant} />
            {glContext.Loggedin && 
              <div className="text-center">
                <div className="pb-3">
                  <RefreshButton/>
                  <LogoutButton refreshRestaurant={props.refreshRestaurant}/>
                </div>
              </div>
            }
          </Tab.Pane>

          <Tab.Pane eventKey="help">
            <Instructions/>
          </Tab.Pane>

          {( glContext.Loggedin && glContext.Restaurant) &&
            <Tab.Pane eventKey="settings">
              <Settings/>
            </Tab.Pane>
          }
        </Tab.Content>
      </Tab.Container>
  );
}