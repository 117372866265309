export const days_of_week = [
    {
        id: "day_1",
        day: "Saturday",
        isClose: false,
    },
    {
        id: "day_2",
        day: "Sunday",
        isClose: false,
    },
    {
        id: "day_3",
        day: "Monday",
        isClose: false,
    },
    {
        id: "day_4",
        day: "Tuesday",
        isClose: false,
    },
    {
        id: "day_5",
        day: "Wednesday",
        isClose: false,
    },
    {
        id: "day_6",
        day: "Thursday",
        isClose: false,
    },
    {
        id: "day_7",
        day: "Friday",
        isClose: false,
    },
]

export const regular_closed_days = [
    {
        id: "close_day_1",
        date: "25-12",
        isClose: false,
    },
    {
        id: "close_day_2",
        date: "26-12",
        isClose: false,
    },
    {
        id: "close_day_3",
        date: "03-02",
        isClose: false,
    }
]

export const special_close_days = []

export const closed_date_range = []