import React, { useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppContext from '../Data/AppContext'
import axios from "axios";
import logger from '../../lib/util';
import { Input } from '@material-ui/core';
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
require('dotenv').config();
export default function AddressDialog(props) {
    const glContext = useContext(AppContext);
    const {postData}=useLogErrortoAPI();
    const { setShowAddressDialog } = props;
    const globalOrder = props.globalOrder;
    const [open] = React.useState(true);
    const [postcode, setPostCode] = React.useState(props.postcode);
    const [address, setAddress]=React.useState([]);

    let url = process.env.REACT_APP_ADDRESS_API+ glContext.RestaurantId + '&postcode=' + postcode;
    useEffect(async ()=>{
        try {
            const response = await axios.get(url,  {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            });
            if(response.status===200)
            {
                logger.info('response 200');
                setAddress(response.data.result);
            }else{
                logger.info(response);
            }
         } catch (error) {
            logger.error(`Error: ${error}`);
            postData('An error occurred. Error code #ADJ-101' + error.toString() , 'AddressDialog.js', 'GetUsers');
         }
         finally{
             
         }

    },[postcode]);
    
    const handleClose = () => {
        setShowAddressDialog(false);
    };
   
    const handleAddressClick=(value)=>{
      var line1= value[0];
      var line2=  value[1];
      var line3=  value[2];
      var line4=  value[3];
      globalOrder.setLine1(line1);
      globalOrder.setLine2(line2);
      globalOrder.setLine3(line3);
      globalOrder.setLine4(line4);
      setShowAddressDialog(false);
    }

    const handlePostcodeChange = (value) => {
        if ((value.length <= 11 && !value.indexOf('.') > -1) || value === '') {
            setPostCode(value);
        }
      }
  
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} id='NotesDialog'>
            <div className="row dialogHeader">
                <span className="col-10">Select Address</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <div className="row dialogHeader">
                <Input className="col-md-10" value={postcode}  onChange={e => handlePostcodeChange(e.target.value)}/>
            </div>
            <DialogContent>
                <div className="row">
                    <div className="col-md-12 text-center margin-bottom-10">
                    {
                       address
                            .map((value,i) =>
                            <button key={i} className="btn notesBtn btn-success btn-lg" onClick={() => handleAddressClick(value)}> {value[0]}{value[1].length>0 ? ', ' + value[1]: ''}{value[2].length>0 ? ', ' + value[2]: ''}{value[3].length>0 ? ', ' + value[3]: ''}{value[4].length>0 ? ', ' + value[4] : ''}</button>
                        )
                    } 
                    </div>
                </div>
                <div className="row margin-bottom-10" >
                    <div className="col-md-12 btn-group">
                        <button className='btn btn-light btn-lg form-control'  onClick={handleClose} >Close</button>                        
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
