import {  React, useState, useEffect } from 'react';
import "./OrderDetailsHead.css"
import * as AiIcons from 'react-icons/io';
import ChargeDialog from '../../Dialog/ChargeDialog'
import DiscountDialog from '../../Dialog/DiscountDialog'
const OrdersDetailsHeadEatin = (props) => {
const order = props.activeOrder;
const {setActiveOrder} = props;  
const [showChargeDialog, setShowChargeDialog]=useState(false);
const [showDiscountDialog, setShowDiscountDialog]=useState(false);
const [showDirtyValue, setShowDirtyValue]=useState(false);
const [chargeType, setChargeType]=useState('Drinks');
const [discount,setDiscount] =useState(order.total_discount);
     

const refreshDiscount=(val)=>{
  setDiscount(val);
 }

  const handleChargeClick = (type) => {
    setChargeType(type);
    setShowChargeDialog(true);
   
  };
 useEffect(()=>{
  const dirty= props.dirty;
  setShowDirtyValue(!dirty);
 });

 const handleDiscountClick =()=>{
  setShowDiscountDialog(true);
 }

  return (
      <div className="row orderDetailsHeader">
       {showChargeDialog && <ChargeDialog type={chargeType}  setActiveOrder={setActiveOrder} order={order} setShowChargeDialog={setShowChargeDialog}/>} 
       {showDiscountDialog && <DiscountDialog type={chargeType}  setDiscount={setDiscount}  setShowDiscountDialog={setShowDiscountDialog} subtotal={order.subtotal_amount} discount={discount} setActiveOrder={setActiveOrder}  refreshDiscount={refreshDiscount}/>}  
          <div className="col-sm-6 text-left ">
            <div className="strong detailsLine">
              <h5>Eat-in T-{order.inhouse_table} (#{order.id})</h5>
            </div>
            <div className="detailsLine">Guests: {order.inhouse_guests}</div>
            <div className="detailsLine notesColumn">Notes: {order.instructions}</div>
          </div>
          {showDirtyValue && <div className="col-sm-6 text-right">
            <div className="strong detailsLine"><h5>Total: {order.currency_symbol}{order.total_amount}</h5></div>
            <div className="detailsLine clickable" name='Drinks' onClick={()=>{handleChargeClick('Drinks')}} ><AiIcons.IoIosAddCircleOutline className="float-left"/> Drinks: {order.currency_symbol}{order.drinks_charge} </div>
            <div className="detailsLine clickable" name='Dessert' onClick={()=>{handleChargeClick('Dessert')}}><AiIcons.IoIosAddCircleOutline className="float-left"/> Dessert: {order.currency_symbol}{order.dessert_charge}</div>
            <div className="detailsLine clickable"  name='Service' onClick={()=>{handleChargeClick('Service')}}><AiIcons.IoIosAddCircleOutline className="float-left"/> Service: {order.currency_symbol}{order.service_charge}</div>
            <div className="detailsLine clickable" onClick={handleDiscountClick}><AiIcons.IoIosAddCircleOutline className="float-left"/> Discount: {order.currency_symbol}{order.total_discount}</div>
            <div className="detailsLine">Subtotal: {order.currency_symbol}{order.subtotal_amount}</div>
          </div>}
        </div>
  );
}

export default OrdersDetailsHeadEatin;
