import React, { useContext, useState } from "react";
// import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css"
import AppContext from '../Data/AppContext'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import useGetUsers from "../../services/useGetUsers";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import useHelperService from "../../services/useHelperService";
//import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import AddressDialog from "../Dialog/AddressDialog";
import  './form.css';
require('dotenv').config();

export default function DeliveryForm(props) {
  const newOrder = props.newOrder;
  const { handleClose } = props;
  const { handleClick } = props;
  const globalOrder = props.globalOrder;
  const {usersList,doFetch} = useGetUsers([]);
  var rx = new RegExp(/^\d+$/);
  const [showAddressDialog, setShowAddressDialog]=useState(false);
  // const { formState: { errors } } = useForm();
  const glContext = useContext(AppContext);
  const [showUsers, setShowUsers]= useState();

  const handleOnSelect = (item) => {
    globalOrder.setMobile(item.mobile);
    globalOrder.setName(item.name);
    globalOrder.setLine1(item.line1); 
    globalOrder.setLine2(item.line2);
    globalOrder.setLine3(item.line3);
    globalOrder.setLine4(item.line4);
    
    globalOrder.setPostcode(item.postcode);
    setShowUsers(false);
  }
  const handleMobileChange = (value) => {
    if ((value.length <= 11 && rx.test(value) && !value.indexOf('.') > -1) || value === '') {
      globalOrder.setMobile(value);
      if (value.length > 4) {
         doFetch(value);
         setShowUsers(true);
      }      
    }
  }
  
  glContext.setCurrentScreen("Delivery");
  //let url = process.env.REACT_APP_CREATE_ORDER + glContext.RestaurantId;
  const [disablePostcodeLookup, setDisablePostcodeLookup]=useState(true);
  const timeoptions = useHelperService('delivery');
  
  let defaultOption='';
  if(timeoptions && timeoptions.length>0)
  {
    defaultOption = timeoptions[0].value;
  }
  
  var found = null;  
  if (glContext.ActiveOrder !== null && !newOrder) {
    var d = new Date();
    let month = d.getMonth()+1;
    let day =d.getDate();
    if(month <10){
      month = '0'+ month;
    }
    if(day <10){
      day = '0'+ day;
    }
  
    let tempTime = new Date(globalOrder.deliveryTime);
    tempTime = (tempTime.getHours() <10 ? ('0'+tempTime.getHours()) : tempTime.getHours())
      + ":" + (tempTime.getMinutes() <10 ? ('0'+tempTime.getMinutes()) : tempTime.getMinutes());
      found = timeoptions.filter(function(e) {
      return e.value === tempTime;
    });
    if (found.length>0) {
      defaultOption = found[0].value;
    } else {
      timeoptions.unshift({"label": tempTime, "value": tempTime});
      defaultOption = tempTime;
    }
    var deltime= d.getFullYear()+"-" + month+"-" + day+" "  + defaultOption + ":00";
    globalOrder.setDeliveryTime(deltime);
  } else {
    if(timeoptions[0]!== undefined){
      var d = new Date();
      var deltime= d.getFullYear()+"-" + (d.getMonth() + 1) +"-" + d.getDate()+" "  + timeoptions[0].value + ":00";
      globalOrder.setDeliveryTime(deltime);
    }    
  }
  
  const onTimeSelect = (e) => {
    var d = new Date();
    let month = d.getMonth()+1;
    let day =d.getDate();
    if(month <10){
      month = '0'+ month;
    }
    if(day <10){
      day = '0'+ day;
    }
  
    var deltime= d.getFullYear()+"-" +month+"-" +day+" "  + e.value + ":00";
    globalOrder.setDeliveryTime(deltime);
  }

  const handeSubmitClick = () => {
    var required = [];
    if (globalOrder.mobile.length < 10) {
      required.push('Mobile Number');
    }
    if (globalOrder.name < 1) {
      required.push('Customer Name');
    }
    if(globalOrder.deliveryTime < 1){
      required.push('Delivery Time');
    } 
    if (globalOrder.postcode < 5) {
      required.push('Postcode');
    }
    if (globalOrder.line1 < 1) {
      required.push('Address Line 1');
    }
    
    if (required.length > 0) {
      NotificationManager.error(required.join(', '), 'Information required', 5000, () => {

      });
    }
    else {
      glContext.setActiveOrder(globalOrder);
      handleClick('delivery');
    }
  }

  const handleInstructionChange = (value) => {
    globalOrder.setInstructions(value);
  }
  const handleNameChange = (value) => {
    globalOrder.setName(value);
  }

  const handlePostcodeChange = (value) => {
    if ((value.length <= 11 && !value.indexOf('.') > -1) || value === '') {
      globalOrder.setPostcode(value);
    }
    if (value.length>5) {
      setDisablePostcodeLookup(false);
    }else{
      setDisablePostcodeLookup(true);
    }
  }

  const handleLine1Change = (value) => {
    globalOrder.setLine1(value);
  }
  const handleLine2Change = (value) => {
    globalOrder.setLine2(value);
  }
  const handleLine3Change = (value) => {
    globalOrder.setLine3(value);
  }
  const handleLine4Change = (value) => {
    globalOrder.setLine4(value);
  }
  if (!newOrder && glContext.ActiveOrder != null) {

  }

  const handleAddressDialog=()=>{
    setShowAddressDialog(true);
  }

  return (
    <div className="eatinFormContainer">
       {showAddressDialog && <AddressDialog showAddressDialog={showAddressDialog}  postcode={globalOrder.postcode} globalOrder={globalOrder} setShowAddressDialog={setShowAddressDialog}/>}  
      <NotificationContainer />
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="mobile">Phone Number</label>
          <div>
          <input id="mobile" autoComplete="off" autoFocus="autofocus" tabIndex="0" className="form-control" value={globalOrder.mobile} onChange={e => handleMobileChange(e.target.value)} />
            <div className="matchedCustomers ">
              {
                showUsers && <p>{usersList.length} customers found, tap to select</p>
              }
              
              {(usersList && showUsers) &&
                Object.entries(usersList)
                  .map(([key, value]) =>
                    <div className="row singleCustomerRowInForm clickable" key={key} onClick={e => handleOnSelect(value)} >
                      <div className="col-md-6">{value.mobile}</div>
                      <div className="col-md-6">{value.name}</div>
                    </div>
                  )
              }
            </div>
          </div>
          <label htmlFor="name">Customer Name</label>
          <input id="name" autoComplete="off" className="form-control" tabIndex="0" type="text" value={globalOrder.name} onChange={e => handleNameChange(e.target.value)} />
          <label htmlFor="delivery_time">Delivery Time</label>
          <Dropdown options={timeoptions} onChange={onTimeSelect} value={defaultOption} placeholder="Select an option" />
          <label htmlFor="instructions">Notes</label>
          <textarea className="form-control" tabIndex="0" rows="2" value={globalOrder.instructions} onChange={e => handleInstructionChange(e.target.value)} />
        </div>
        <div className="col-sm-6">
          <label htmlFor="postcode" >Postcode</label>
          <div className="row">
            <input id="postcode" autoComplete="off" value={globalOrder.postcode} tabIndex="0" type="text" step=".1" onChange={e => handlePostcodeChange(e.target.value)} />
            &nbsp;<button className="btn btn-success" id="AddressLooupButton" onClick={handleAddressDialog} disabled={disablePostcodeLookup} >Look Up</button>
          </div>
          <label htmlFor="line1">Address Line 1</label>
          <input id="line1" autoComplete="off" className="form-control" value={globalOrder.line1} tabIndex="0" type="text" onChange={e => handleLine1Change(e.target.value)} />
          <label htmlFor="line2">Address Line 2</label>
          <input id="Line2" autoComplete="off" className="form-control" value={globalOrder.line2} tabIndex="0" type="text" onChange={e => handleLine2Change(e.target.value)} />
          <label htmlFor="line3">Address Line 3</label>
          <input id="Line3" autoComplete="off" className="form-control" value={globalOrder.line3} tabIndex="0" type="text" onChange={e => handleLine3Change(e.target.value)} />
          <label htmlFor="line4">Address Line 4</label>
          <input id="Line4" autoComplete="off" className="form-control" value={globalOrder.line4} tabIndex="0" type="text" onChange={e => handleLine4Change(e.target.value)} />
        </div>
      </div>
      <div className="row">
        <div className="formErrors"></div>
      </div>
      <div className="row">
        <div className="col-md-12 btn-group">
          {(!newOrder) &&
            <button tabIndex="0" className="btn btn-light form-control" onClick={handleClose} >Cancel</button>
          }
          <button className="btn btn-primary form-control" onClick={handeSubmitClick}> Save</button>
        </div>
      </div>
    </div>
  );
}
