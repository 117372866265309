import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent';
import AppContext from '../Data/AppContext';
import styles from './TutorialDialog.module.css'

const TutorialDialog = () => {
    const [ loading, setLoading ] = useState(true);
    const glContext = useContext(AppContext);
    const {open, setOpen, info, setInfo} = glContext;

    const url = `https://www.youtube.com/embed/${info.tutorial_url}?rel=0&amp;showinfo=0;autoplay=1`;

    return (
        <Dialog
            fullWidth
            open={info !== null && open}
            maxWidth="lg"
            onClose={() => setOpen(false)}
            id="tutorial-dialog"
        >
            <div className="row dialogHeader">
                <span className="col-10">{info?.id}. {info?.title || 'Tutorial'}</span>
                <span className="text-right col-2">
                    <button
                        className="btn"
                        onClick={() => {
                            setOpen(false);
                            setInfo(null);
                        }}
                    >X</button>
                </span>
            </div>
            <DialogContent>
                <div className={styles.player}>
                    <iframe 
                        width="100%" 
                        height="100%"
                        src={url}
                        title="YouTube video player"
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        onLoad={() => {
                            setLoading(false);
                        }}
                    >
                    </iframe>
                    {loading && <div className={styles.loading}></div>}
                </div>

                <div className="row px-5 mt-2 margin-bottom-10" >
                    <div className="col-md-12 btn-group">
                        <Button variant="contained" 
                            className="d-flex align-items-center justify-content-center bg-primary form-control text-light"
                            size="large"
                            onClick={() => {
                                setOpen(false)
                                setInfo(null)
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TutorialDialog
