import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import axios from "axios";
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
import {NotificationManager} from 'react-notifications';
import AppContext from '../Data/AppContext'

export default function ChargeDialog(props) {
    const glContext = useContext(AppContext);
    const order = props.order;
    const {setActiveOrder} = props;  
    const { setShowChargeDialog } = props;
    const type = props.type;
    const [open] = React.useState(true);
    const [newCharge, setNewCharge] = React.useState(null);
    let charge = 0;
    const {postData}=useLogErrortoAPI();
    var reg = /^-?\d+\.?\d*$/;
    // const {  } = useForm();
    if (type === 'Drinks') {
        charge = order.drinks_charge;
    }
    if (type === 'Dessert') {
        charge = order.dessert_charge;
    }
    if (type === 'Service') {
        charge = order.service_charge;
    }
    if (type === 'Delivery') {
        charge = order.delivery_cost;
    }
    const handleClose = () => {
        setShowChargeDialog(false);
    };

    const addCharge = () => {
        if (newCharge < 0) {
            NotificationManager.error('Charge can not be less than 0','Invalid Information', 3000, () => {});
            return;
        }
        if (newCharge !== null && newCharge.length > 0) {
            charge = (parseFloat(charge) + parseFloat(newCharge));
            if (charge < 0) {
                NotificationManager.error('Charge can not be less than 0','Invalid Information', 3000, () => {});
                return;
            }

            let ord = order;
            if (type === 'Drinks') {
                ord.drinks_charge = charge;
            }
            if (type === 'Dessert') {
                ord.dessert_charge = charge;
            }
            if (type === 'Service') {
                ord.service_charge = charge;
            }
            if (type === 'Delivery') {
                ord.delivery_cost = charge;
            }
            setActiveOrder(ord);
            glContext.setActiveOrder(order);
            if (type === 'Drinks') {
                SaveChargetoAPi({ drinks_charge: charge });
            }
            if (type === 'Dessert') {
                SaveChargetoAPi({ dessert_charge: charge });
            }
            if (type === 'Service') {
                SaveChargetoAPi({ service_charge: charge });
            }
            if (type === 'Delivery') {
                SaveChargetoAPi({ delivery_cost: charge });
            }
        }
    };

    const processChargeChange = (value) => {
        if (value.length === 0 || reg.test(value) === true) {
            setNewCharge(value)
        }
        if (value.length === 1 && value === '.') {
            value = '0' + value;
            setNewCharge(value)
        }
    }

    const SaveChargetoAPi = async (value) => {
        let url = process.env.REACT_APP_DELETE_ORDER + glContext.RestaurantId + '&id=' + glContext.ActiveOrder.id;

        let error;
        try {
            const response = await axios.patch(url, value, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });

            if (response.status === 200) {
                console.log('Save SUccess');
                glContext.setActiveOrder(response.data.result.order);
                setShowChargeDialog(false);
            } else {
                console.log(response);
                postData(error, 'Charge Dialog', 'SaveChargetoAPi');
            }
        } catch (err) {
            error = err;
            console.log(err);
            postData(error, 'Charge Dialog', 'SaveChargetoAPi');
        }
    }

    const minusCharge = () => {
        if (newCharge < 0) {
            NotificationManager.error('Charge can not be less than 0','Invalid Information', 3000, () => {});
            return;
        }

        if (newCharge !== null && newCharge.length > 0) {
            charge = (parseFloat(charge) - parseFloat(newCharge));
            if (charge < 0) {
                NotificationManager.error('Charge can not be less than 0','Invalid Information', 3000, () => {});
                return;
            }
            let ord = order;
            if (type === 'Drinks') {
                ord.drinks_charge = charge;
                SaveChargetoAPi({ drinks_charge: charge });
            }
            if (type === 'Dessert') {
                ord.dessert_charge = charge;
                SaveChargetoAPi({ dessert_charge: charge });
            }
            if (type === 'Service') {
                ord.service_charge = charge;
                SaveChargetoAPi({ service_charge: charge });
            }
            if (type === 'Delivery') {
                ord.delivery_cost = charge;
                SaveChargetoAPi({ delivery_cost: charge });
            }
            setActiveOrder(ord);
            glContext.setActiveOrder(order);
            setShowChargeDialog(false);
        }

    };
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">{type} Charge {order.currency_symbol}{charge}</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <p>Add or subtract amount from {type} charge.</p>
                <div className="row">
                    <div className="col-md-4">
                        <button className="btn btn-light btn-lg form-control" onClick={minusCharge} >-</button>
                    </div>
                    <div className="col-md-4">
                        <input className="form-control" placeholder="0" value={newCharge} onChange={e => processChargeChange(e.target.value)} type="number" min="0" step=".5" />
                    </div>
                    <div className="col-md-4">
                        <button className="btn btn-primary btn-lg form-control" onClick={addCharge}>+</button>
                    </div>
                </div>
                <p></p>
            </DialogContent>
        </Dialog>
    );
}
