import React, { useContext } from "react";
import {NotificationManager, NotificationContainer} from 'react-notifications';
import "bootstrap/dist/css/bootstrap.css"
import AppContext from '../Data/AppContext'
import Dropdown from 'react-dropdown';
//import { useHistory } from "react-router-dom";
import useHelperService from "../../services/useHelperService";
require('dotenv').config();

export default function WaitingForm(props) {
  const newOrder =props.newOrder;
  const { handleClose } = props;
  const { handleClick } = props;
  const globalOrder= props.globalOrder;
  const glContext = useContext(AppContext);
  glContext.setCurrentScreen("waiting");
  const timeoptions = useHelperService('waiting');
  let defaultOption='';
  if(timeoptions && timeoptions.length>0)
  {
    defaultOption = timeoptions[0].value;
  }
  
  var found = null;
  if (glContext.ActiveOrder !== null && !newOrder) {
    var d = new Date();
    let month = d.getMonth()+1;
    let day =d.getDate();
    if(month <10){
      month = '0'+ month;
    }
    if(day <10){
      day = '0'+ day;
    }
  
    let tempTime = new Date(globalOrder.deliveryTime);
    tempTime = (tempTime.getHours() <10 ? ('0'+tempTime.getHours()) : tempTime.getHours())
      + ":" + (tempTime.getMinutes() <10 ? ('0'+tempTime.getMinutes()) : tempTime.getMinutes());    
    found = timeoptions.filter(function(e) {
      return e.value === tempTime;
    });
    if (found.length>0) {
      defaultOption = found[0].value;
    } else {
      timeoptions.unshift({"label": tempTime, "value": tempTime});
      defaultOption = tempTime;
    }
    var deltime= d.getFullYear()+"-" + month+"-" + day+" "  + defaultOption + ":00";
    globalOrder.setDeliveryTime(deltime);
  } else {
    if(timeoptions[0]!== undefined){
      var d = new Date();
      var deltime= d.getFullYear()+"-" + (d.getMonth() + 1 ) +"-" + d.getDate()+" "  + timeoptions[0].value + ":00";
      globalOrder.setDeliveryTime(deltime);
    }    
  }
  
  const onTimeSelect = (e) => {
    var d = new Date();
    let month = d.getMonth()+1;
    let day =d.getDate();
    if(month <10){
      month = '0'+ month;
    }
    if(day <10){
      day = '0'+ day;
    }
  
    var deltime= d.getFullYear()+"-" +month+"-" +day+" "  + e.value + ":00";
    globalOrder.setDeliveryTime(deltime);
  }
 
  const handeSubmitClick=()=>{
    var required=[];
    if(globalOrder.name<1){
      required.push('Customer Name');
    }
    if(globalOrder.deliveryTime < 1){
      required.push('Collection Time');
    }
     
    if(required.length>0){
      NotificationManager.error(required.join(', '),'Information required', 5000, () => {});
    }
    else{
      glContext.setActiveOrder(globalOrder);
      handleClick('waiting');
    }
}

const handleInstructionChange=(value)=>{
 globalOrder.setInstructions(value);
}
const handleNameChange=(value)=>{
 globalOrder.setName(value);
}

  return (
    <div className="eatinFormContainer">
       <NotificationContainer/>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="name">Customer Name</label>
            <input id="name" autoComplete="off" className="form-control" tabIndex="0" type="text"  value={globalOrder.name}   onChange={e=>handleNameChange(e.target.value)} />
            <label htmlFor="delivery_time">Collection Time</label>
            <Dropdown options={timeoptions} onChange={onTimeSelect} value={defaultOption} placeholder="Select an option" />
            <label htmlFor="instructions">Notes</label>
            <textarea className="form-control" tabIndex="0" rows="2"   value={globalOrder.instructions}   onChange={e=>handleInstructionChange(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="formErrors"></div>
        </div>
        <div className="row">
          <div className="col-md-12 btn-group">
            {(!newOrder) &&
              <button tabIndex="0" className="btn btn-light form-control" onClick={handleClose} >Cancel</button>
            }
            <button className="btn btn-primary form-control" onClick={handeSubmitClick}> Save</button>
          </div>
        </div>
    </div>
  );
}
