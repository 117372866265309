import React, { useState, useEffect, useContext } from 'react';
import { MdBorderColor } from 'react-icons/md';
import { Button } from '@material-ui/core';
import AppContext from '../../Data/AppContext';
import ServiceChargeDialog from '../../Dialog/ServiceChargeDialog/ServiceChargeDialog';

const ServiceCharge = () => {
    const glContext = useContext(AppContext);
    const {  Restaurant: { currency_symbol, service_charges } } = glContext;

    // fetchData from local storage
   
    const restaurant_service_charge = JSON.parse(service_charges);
    if(!restaurant_service_charge?.online_orders){
        restaurant_service_charge.online_orders=[];
    }
    const { online_orders, epos_takeaway, epos_eatin_pb, epos_eatin_pp, epos_eatin_perc  } = restaurant_service_charge;

    const [isOpen, setIsOpen] = useState(false);
    const [serviceCharges, setServiceCharges] = useState({
        online_orders: online_orders || 0,
        epos_takeaway: epos_takeaway || 0,
        epos_eatin_pb: epos_eatin_pb || 0,
        epos_eatin_pp: epos_eatin_pp || 0,
        epos_eatin_perc: epos_eatin_perc || 0
    });

    const settingData = (data) => {
        const { online_orders, epos_takeaway, epos_eatin_pb, epos_eatin_pp, epos_eatin_perc } = JSON.parse(data);
        setServiceCharges({
            online_orders: online_orders || 0,
            epos_takeaway: epos_takeaway || 0,
            epos_eatin_pb: epos_eatin_pb || 0,
            epos_eatin_pp: epos_eatin_pp || 0,
            epos_eatin_perc: epos_eatin_perc || 0
        });
    }

    // useEffect(() => {
    //     setServiceCharges({
    //         online_orders: online_orders || 0,
    //         epos_takeaway: epos_takeaway || 0,
    //         epos_eatin_pb: epos_eatin_pb || 0,
    //         epos_eatin_pp: epos_eatin_pp || 0,
    //         epos_eatin_perc: epos_eatin_perc || 0
    //     });
    // }, [epos_eatin_pb, epos_eatin_perc, epos_eatin_pp, epos_takeaway, online_orders])

    const handleOpen = () => {
        setIsOpen(true);
    }

    return (
        <div className="margin-bottom-40">
            <ServiceChargeDialog serviceCharges={serviceCharges} settingData={settingData} setIsOpen={setIsOpen} setServiceCharges={setServiceCharges} open={isOpen} />
            <h3>Service Charges
                <Button variant="outlined" className="ml-2 font-weight-bold text-center"  size="medium" onClick={handleOpen}>
                    <MdBorderColor fontSize="25px" />
                </Button>
            </h3>
            <div className="my-3">
                <div className="d-flex align-items-center justify-content-center">
                    
                </div>

                <div className="row mt-3">
                    <div className="col-md-4 pb-3 border">
                        <h5 className="mt-2">All Online Orders</h5>
                        <hr />

                        {serviceCharges?.online_orders  && <ShowData title={'Online Orders'} data={`${currency_symbol}${serviceCharges.online_orders}`}  />}
                    </div>
                    <div className="col-md-4 pb-3 border">
                        <h5 className="mt-2">Inhouse Takeaway Orders</h5>
                        <hr />

                        {serviceCharges.epos_takeaway  && <ShowData title={'Takeaway Orders'} data={`${currency_symbol}${serviceCharges.epos_takeaway}`}  />}
                    </div>
                    <div className="col-md-4 pb-3 border">
                        <h5 className="mt-2">Inhouse Eat-in Orders</h5>
                        <hr />
                        
                        
                        {serviceCharges.epos_eatin_pp > 0 && <ShowData title={'Per Person'} data={`${currency_symbol}${serviceCharges.epos_eatin_pp}`}  />}
                        {serviceCharges.epos_eatin_pb > 0 && <ShowData title={'Per Order'} data={`${currency_symbol}${serviceCharges.epos_eatin_pb}`}  />}
                        {serviceCharges.epos_eatin_perc > 0  && <ShowData title={'Percent of Subtotal'} data={`${serviceCharges.epos_eatin_perc}%`}  />}
                    </div>
                </div>
            </div>
        </div>
    )
};

const ShowData = ({title, data}) => {
    return (
        <p className="m-0 p-0">
            <span className='font-weight-bold'>{title}:</span>
            <span className="ml-2">{data}</span>
        </p>
    )
}

export default ServiceCharge;

