import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import axios from "axios";
import AppContext from '../Data/AppContext'
import logger from '../../lib/util';

export default function NotesDialog(props) {
    //const item_id = props.item_id;
    const glContext = useContext(AppContext);
    const [order, setOrder] =React.useState(glContext.ActiveOrder);
    const lineItems = order.order_lines.items;
    const itemKey= props.itemKey;
    const [open] = React.useState(true);
    const { setShowNotesDialog } = props;
    const {refreshNotes} = props;
    const [notes, setNotes]=React.useState(props.notes);
    var menuHints = [];
    for (const [, category] of Object.entries(glContext.Restaurant.menu.categories.items)) {
        if(lineItems[itemKey] && category.id === lineItems[itemKey].category_id){
            if (category.note_hints !== null && Object.keys(category.note_hints).length !== 0) {
                menuHints = category.note_hints;
            }
        }       
    }

    if(menuHints === null || menuHints === undefined || menuHints.length < 1)
    {
        menuHints = {
            0: "No Coriander", 
            1: "Spicy", 
            2: "Nut Allergy", 
            3: "Mild", 
            4: "Vegan", 
            5: "Vegetarian", 
            6: "Madras Hot", 
            7: "Very Hot"
        };
    }
       
    const handleClose = () => {
        setShowNotesDialog(false);
    };

    const handleSubmit=()=>{
        var items = order.order_lines.items;
        items[itemKey].note=notes;
        var ord = order;
        ord.order_lines.items = items;
        setOrder(ord);
        glContext.setActiveOrder(order);
        saveOrder();
        refreshNotes(notes);
        setShowNotesDialog(false);
    }
   
    const  saveOrder= async ()=>{
        let url = process.env.REACT_APP_SAVE_ORDER+ glContext.RestaurantId+ '&id='+ glContext.ActiveOrder.id;
        let error;
        try {
        const response =  await axios.patch(url,JSON.stringify(glContext.ActiveOrder.order_lines),{
            headers: {
                'Access-Control-Allow-Origin': true,
                "Content-Type": "multipart/form-data",
                'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
            },
            }).catch(err => {
            if (err.response.status === 404) {
                throw new Error(`${err.config.url} not found`);
            }
            throw err;
        });
    
        if(response.status===200)
        {
            logger.info('Save Success');
            logger.info(response);
            glContext.setActiveOrder(response.data.result.order);
            glContext.setDirty(false);
            
        }else{
            logger.info('Save Failed');
        }
        } catch (err) {
            error = err;
            logger.info('error');
            logger.info(error);
        }
    }

    const onNotesInputChange=(value)=>{
        setNotes(value)
    }
    const handleHintClick=(value)=>{
        let s=notes;
        if(s !== null && s !== ''){
            s= notes +", "+ value;
        }else{
            s= value;
        }
     setNotes(s)
    }
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} id='NotesDialog'>
            <div className="row dialogHeader">
                <span className="col-10">Add Notes</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <div className="row">
                    <div className="col-md-12 text-center margin-bottom-10">
                    {
                    Object.entries(menuHints)
                        .map(([index, value]) =>
                            <button className="btn notesBtn btn-primary" key={index} onClick={() => handleHintClick(value)}> {value}</button>
                        )
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 margin-bottom-10">
                        <textarea  className='form-control' type='text' onChange={e => onNotesInputChange(e.target.value)} value={(notes === null ? "" : notes)} />
                    </div>
                </div>
                <div className="row margin-bottom-10" >
                    <div className="col-md-12 btn-group">
                        <button className='btn btn-light btn-lg form-control'  onClick={handleClose} >Cancel</button>
                        <button className='btn btn-primary btn-lg form-control' onClick={handleSubmit}  >Submit</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
