import React, {  useContext } from 'react';
import './OnlineCard.css'
import '../Orders/Orders.css'
import * as AiIcons from 'react-icons/io';
import AppContext from '../Data/AppContext';
import { NotificationManager} from 'react-notifications';
import axios from "axios";
import logger from "../../lib/util";
import { useHistory } from "react-router-dom";


export default function OnlineCard(props) {
  const glContext = useContext(AppContext);
  const{setShowRejectReasonDialog} =props
  const {setRejectId} = props;
  // const {refreshOrders} = props;
  const history = useHistory();
  const handleReject=()=>{
    setRejectId(props.data.id);
    setShowRejectReasonDialog(true);
  }

 const acceptOrder = async (id)=>{
    let url = process.env.REACT_APP_UPDATE_SPECIAL+ glContext.RestaurantId+ '&id='+ id;
    try {
      const response =  await axios.patch(url,{accept:true},{
        headers: {
          'Access-Control-Allow-Origin': true,
          "Content-Type": "multipart/form-data",
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
        },
        }).catch(err => {
        if (err.response.status === 404) {
          throw new Error(`${err.config.url} not found`);
        }
        throw err;
      });

      if(response.status===200)
      {
        var order = glContext.OnlineOrdersList.find(x => x.id === id);
        glContext.setActiveOrder(order);
        glContext.setDirty(false);
        history.push("/orderDetails"); 
        // TODO - dont redirect - rather change this order card with other type of order card
        // because redirecting to the home page will unnecessaryly create another page load
        // we need to avoid this page load and API request
       // history.push("/Home"); 
      //  let r = Math.random().toString(36).substring(7);
       // refreshOrders(r);
      }else{
        logger.info(response);
        NotificationManager.error(JSON.stringify(response.warning),'Failed to Save Order', 50000, () => {
          //history.push("/"); 
        });
      }
    } catch (error) {
      logger.info(error);
      NotificationManager.error(error, 'Go to Home', 50000, () => { 
      });
    }
 }

 let deliveryTime = props.data.order_time;
 if (props.data.delivery_time_est) {
  deliveryTime = props.data.delivery_time_est
 }
 if (props.data.delivery_time) {
   deliveryTime = props.data.delivery_time
 }
 let dateTime  = new Date(deliveryTime.replace(" ","T"));  
 let timeString = dateTime.getHours() + ":" + (dateTime.getMinutes()<10?'0':'') + dateTime.getMinutes();
 
 let leftBorder ='10px solid green'; // eatin
 if(props.data.status === 'rejected')
 {
   leftBorder ='10px solid red';
 }
 if(props.data.order_type === 'waiting')
 {
   leftBorder ='10px solid blue';
 }
 if(props.data.order_type === 'delivery')
 {
   leftBorder ='10px solid #fdfd4e9c';
 }
 if(props.data.order_type === 'collection')
 {
   leftBorder ='10px solid darkBlue';
 }

    return (      
      <div id={props.data.id} style={{borderLeft: leftBorder}}  className="orderCard"  >
        <div className="row orderCardRow" >
            <div className="col-8 col-md-8 text-left">
            <span className="">{timeString}</span>
            <span className="small orderCardNameLine">
            {(props.data.order_type === 'delivery' ? props.data.line1 + ', ' + props.data.postcode 
            : (props.data.order_type === 'eat-in' ? 'Table ' + props.data.inhouse_table + ', Guests ' + props.data.inhouse_guests
            : props.data.name))}          
            {<AiIcons.IoMdGlobe color={'darkGrey'}/>} 
            </span>
            </div>
            <div className="col-4 col-md-4 text-right">
            {props.data.currency_symbol}{props.data.total_amount}
            </div>
        </div>
      
        <div className="row ">
          <div className="col-md-12 btn-group accept-reject-row">
            <button className="col-md-4 btn btn-warning" onClick={handleReject}>Reject</button>
            <button className="col-md-8 btn btn-primary"  onClick={()=>{acceptOrder(props.data.id)}}>Accept</button>
          </div>
        </div>
      </div>
    );
}

