import {React,useContext}from "react";
import "./Order.css"
import * as AiIcons from 'react-icons/io';
import AppContext from '../Data/AppContext'
import { useHistory } from "react-router-dom";
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
import axios from "axios";
import {NotificationManager} from 'react-notifications';


export default function Order(props) {
  const history = useHistory();
  const {postData}=useLogErrortoAPI();
  const glContext = useContext(AppContext);
  //const {refetchOrders} = props.refetchOrders;
  const viewHiddenOrders = props.viewHiddenOrders;
  const hideShowOrder =async ()=>{
    let url = process.env.REACT_APP_ORDER_SHOWHIDE + glContext.RestaurantId + '&id='+ props.data.id;
    try {
      let value = 0; 
      if(!props.data.epos_show){
        value = 1; 
      }
      const response =  await axios.patch(url,{epos_show:value},{
        headers: {
          'Access-Control-Allow-Origin': true,
          "Content-Type": "multipart/form-data",
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
        },
        }).catch(err => {
        if (err.response.status === 404) {
            // TODO - API error log send response. Error code #OJ-101
            postData('An error occurred. Error code #OJ-101' + err.toString() , 'Order.js', 'hideShowOrder');
          throw new Error(`${err.config.url} not found`);
          
        }
        throw err;
      });
      
      if(response.status===200)
      {
        NotificationManager.success('Succesfully updated the order');
      //  let r = Math.random().toString(36).substring(7);
        props.refetchOrders();
        // TODO - why refresh oders - why not just hide the order in question from list?
      }else{
        postData('An unknown error occurred. Error code #OFJ-103'  , 'Order.js', 'hideShowOrder');
        NotificationManager.error('An unknown error occurred. Error code #OFJ-103', 'Failed to show/hide order', 50000, () => {});
       
      }
    } catch (err) {
      if (err.response !== undefined 
        && err.response.data !== undefined
        && err.response.data.errors !== undefined) {
          postData(err.response.data.errors.join(', ') +'An  error occurred. Error code #OFJ-103'  , 'Order.js', 'hideShowOrder');
        NotificationManager.error(err.response.data.errors.join(', '), 'Failed to show/hide order', 50000, () => {
          history.push("/"); 
        });
      } else {
        NotificationManager.error('An unknown error occurred. Error code #OJ-102', 'Failed to show/hide order', 50000, () => {
          history.push("/"); 
        });
        postData('An unknown error occurred. Error code #OJ-102'  , 'Order.js', 'hideShowOrder');
      }
    }
  }

  const handleClick = async function(id) {
    return fetch(process.env.REACT_APP_DELETE_ORDER + glContext.RestaurantId + '&id=' + id , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
        'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
      },
    })
    .then(res => res.json())
    .then((data) => {
      if (data.success === true && data.result.order !== undefined) {
        glContext.setActiveOrder(data.result.order);
        glContext.setDirty(false);
        history.push("/orderDetails"); 
      } else {
        NotificationManager.error(data.errors, 'Failed to get Order', 50000, () => {
          history.push("/Home"); 
        });
      }
    }).catch(err => {
      if (err.response !== undefined 
        && err.response.data !== undefined
        && err.response.data.errors !== undefined) {
        NotificationManager.error(JSON.stringify(err.response.data.errors), 'Failed to get Order', 50000, () => {
          history.push("/Home");        
        });
        postData('Error code #OJ-104' +JSON.stringify(err.response.data.errors)  , 'Order.js', 'handleClick');
      } else {
        NotificationManager.error('An unknown error occurred. Error code #OJ-104', 'Failed to open order', 50000, () => {
          history.push("/"); 
        });
        // TODO - API error log send response. Error code #OJ-104
        postData('An unknown error occurred. Error code #OJ-104'  , 'Order.js', 'handleClick');
      }
    })
  }

  let deliveryTime = new Date(props.data.order_time);
  if (props.data.delivery_time !== null) {
    deliveryTime = new Date(props.data.delivery_time);
  }
  let timeString = deliveryTime.toLocaleString([], { 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true 
  });
  
  let textStriked = '';
  let leftBorder ='10px solid green'; // eatin
  
  if(props.data.order_type === 'waiting')
  {
    leftBorder ='10px solid blue';
  }
  if(props.data.order_type === 'delivery')
  {
    leftBorder ='10px solid #fdfd4e9c';
  }
  if(props.data.order_type === 'collection')
  {
    leftBorder ='10px solid darkBlue';
  }
  if(props.data.status === 'rejected' || props.data.status === 'cancelled')
  {
    leftBorder ='10px solid red';
    textStriked = 'line-through';
  }
  // let printed = false;
  // if (props.data.print_status === 'confirmed'){
  //   printed = true;
  // }
  let online = false;
  if(props.data.origin === 'online')
  {
    online = true;
  }
  let hideSHow = 'block';
  if (!props.data.epos_show && !viewHiddenOrders ) {
    hideSHow = 'none';
  }
  return (
    <div id={props.data.id} style={{textDecoration: textStriked,borderLeft: leftBorder, display:hideSHow}}  className="orderCard"  >
      <div className="row orderCardRow">
        <div className="col-8 col-md-8 text-left" onClick={() => handleClick(props.data.id)}>
          <span className="">{timeString}</span>
          <span className="small orderCardNameLine">
          {(props.data.order_type === 'delivery' ? props.data.line1 + ', ' + props.data.postcode 
          : (props.data.order_type === 'eat-in' ? 'Table ' + props.data.inhouse_table + ', Guests ' + props.data.inhouse_guests
          : props.data.name))}          
          {(online) && <AiIcons.IoMdGlobe color={'darkGrey'}/>} 
          </span>
        </div>
        <div className="col-2 col-md-2 text-right" onClick={() => handleClick(props.data.id)}>
          {props.data.currency_symbol}{props.data.total_amount}
        </div>
        <div className="col-2 d-none d-sm-block col-md-2 text-right">
        {(props.data.epos_show) && <button className="btn btn-light"><AiIcons.IoMdEyeOff color={'darkblue'} onClick={hideShowOrder} /></button>}
        {(!props.data.epos_show) && <button className="btn btn-light"><AiIcons.IoMdEye color={'darkGrey'} onClick={hideShowOrder} /></button>}
        </div>
      </div>
    </div>
  );
}