import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import { useForm } from "react-hook-form";
import axios from "axios";
import AppContext from '../Data/AppContext'
import useLogErrortoAPI from "../../services/useLogErrortoAPI";
require('dotenv').config();

export default function AddMenuDialog(props) {
    const {postData}=useLogErrortoAPI();
    const glContext = useContext(AppContext);
    const newMenuUrl = props.newMenuUrl;
    const { setShowMenuDialog } = props;
    const categoryId=props.categoryId;
    const {refreshMenu} = props;
    const [open] = React.useState(true);
    const [menuName, setMenuName] = React.useState('');
    const [menuPrice, setMenuPrice] = React.useState(0);
    const [validationMessage, setValidationMessage] = useState('');
    var reg = /^-?\d+\.?\d*$/;
    // const {} = useForm();
  
    const processPriceChange = (value) => {
        if (value.length === 0 || reg.test(value) === true) {
            setMenuPrice(value)
        }
        if (value.length === 1 && value === '.') {
            value = '0' + value;
            setMenuPrice(value)
        }
    }

    const processNameChange = (value) => {
        setMenuName(value)
    }
    const handleClose = () => {
        setShowMenuDialog(false);
    };

    const SaveChargetoAPi = async () => {
        if (menuName.length < 1) {
            setValidationMessage('Item name is required');
            return;
        }
        if (menuPrice < 0.1){
           setValidationMessage('Item price is required');
           return;
       }

        var bodyFormData = new FormData();
        bodyFormData.append('category_id', categoryId);
        bodyFormData.append('name', menuName);
        bodyFormData.append('description', '');
        bodyFormData.append('price', menuPrice);
        try {
            const response = await axios.post(newMenuUrl, bodyFormData, {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Basic ${process.env.REACT_APP_BASIC_STR}`
                },
            }
            ).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                postData('An error occurred. Error code #AMD-101' + err.toString() , 'AddMenuDialog.js', 'SaveChargetoAPi');
                throw err;
            });

            if (response.status === 200) {
                var newItem={
                    category_id: response.data.result.category_id,
                    description: response.data.result.description,
                    id: response.data.result.id,
                    name: response.data.result.name,
                    price: response.data.result.price,
                    recommended: response.data.result.recommended,
                    recommended_by_me: response.data.result.recommended_by_me,
                    spice_level: response.data.result.spice_level,
                }
                var restaurant=  glContext.Restaurant;
                if(glContext.currentScreenName==='eat-in'){
                    restaurant.menu_eatin.categories.items[categoryId].food_items.items.push(newItem);
                }else {
                    restaurant.menu.categories.items[categoryId].food_items.items.push(newItem);
                }
              
                glContext.setRestaurant(restaurant);
                localStorage.removeItem("restaurant");
                localStorage.setItem('restaurant',JSON.stringify(restaurant));
                refreshMenu(restaurant)
                setShowMenuDialog(false);
            } else {
                alert(JSON.stringify(response));
            }
        } catch (err) {
            postData('An error occurred. Error code #AMD-102' + err.toString() , 'AddMenuDialog.js', 'SaveChargetoAPi');
        }
    }
    
    return (
        <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} >
            <div className="row dialogHeader">
                <span className="col-10">Add a new item</span>
                <span className="col-2 text-right">
                    <button className="btn" onClick={handleClose} >X</button>
                </span>
            </div>
            <DialogContent>
                <div className="row">
                    <p>Add a new item under the seleted category. Please note that if you have selected from a Takeaway menu category this item will be available on the website immediately.</p>
                    <div className="col-md-6">
                        <label>Item Name</label>
                        <input autoFocus="true" required onChange={e => processNameChange(e.target.value)} className="form-control" value={menuName}  type="text" placeholder="eg. Mushroom Rice"/>
                    </div>
                    <div className="col-md-6">
                        <label>Unit Price</label>
                        <input className="form-control" required type="number" onChange={e => processPriceChange(e.target.value)} placeholder="eg. 1.30" min="0" step=".5" value={menuPrice}/>
                    </div>
                </div>
                <div className="row buttonrow">
                    <p className="col-12 text-center alert-warning">{validationMessage}</p>
                    <div className="col-md-12 btn-group">
                        <button className="btn btn-light btn-lg form-control" onClick={handleClose}>Cancel</button>
                        <button className="btn btn-primary btn-lg form-control" onClick={SaveChargetoAPi}>Save</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
