import React from "react";
import "bootstrap/dist/css/bootstrap.css"
import Login from '../../components/Pages/Login'
require('dotenv').config();
export default function ManageAuth(props) {
  var restaurantList = localStorage.getItem('allRestaurants');
  var user = localStorage.getItem('user');
  var JSONRestaurantList =[];
  var JSONuser={};
  if(restaurantList && restaurantList.length>0){
    try{
      JSONRestaurantList = JSON.parse(restaurantList);
      JSONuser = JSON.parse(user);
    }catch{}
  }

  return (
    <div className="text-center">
      <div className="col-md-12 pt-2" >
        { <Login user={JSONuser}  refreshRestaurant={props.refreshRestaurant}  JSONRestaurantList={JSONRestaurantList}/>}
      </div>
    </div>

  );
}