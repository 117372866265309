import React from 'react';

const ClosedDay = ({data, setData, isEdit, onHandler}) => {
    return (
        <>
            {
                data && data.length > 0 && data.map((item, i) => (
                    <React.Fragment key={item.id || i}>
                        {
                            !isEdit ? (
                                <div className="my-3">
                                    <div className="form-check">
                                        <input className="form-check-input text-success" type="checkbox" id={item.id} checked readOnly />
                                        <label className="form-check-label" htmlFor={item.id}>
                                            {item}
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                <div className="my-3">
                                    <div className="form-check">
                                        <input className="form-check-input" style={{cursor: "pointer"}} type="checkbox" name={item.id} id={item.id} checked={item.isClose} onChange={(e) => onHandler(e, data, setData)}  />
                                        <label className="form-check-label" style={{cursor: "pointer"}} htmlFor={item.id}>
                                            {item.day ? item.day : item.date}
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                    </React.Fragment>
                ))
            }
        </>
    )
}

export default React.memo(ClosedDay);
