import { React, useContext, useEffect, useState } from 'react';
import "./OrderDetails.css"
import PrintBody from './PrintBody';
import OrdersDetailsHeadEatin from './OrderDetailsHead/OrdersDetailsHeadEatin';
import OrdersDetailsHeadWaiting from './OrderDetailsHead/OrdersDetailsHeadWaiting';
import OrdersDetailsHeadDelivery from './OrderDetailsHead/OrdersDetailsHeadDelivery';
import OrdersDetailsHeadCollection from './OrderDetailsHead/OrdersDetailsHeadCollection';
import Menu from '../Menu/Menu';
import { useHistory } from "react-router-dom";
import OrdersDetailsItems from './OrderDetailsItems/OrderDetailsItems'
import AppContext from '../Data/AppContext'
import OrdersDetailsFooter from './OrderDetailsFooter/OrderDetailsFooter'
const OrdersDetails = (props) => {
    const glContext = useContext(AppContext);
    var orditems=[];
    const history = useHistory();
    if(!glContext){
       // history.push("/"); 
    }
    if(!glContext.ActiveOrder){
      //  history.push("/"); 
    }
    if(!glContext.ActiveOrder.order_lines)
    {
        glContext.ActiveOrder.order_lines = {};
        glContext.ActiveOrder.order_lines.items = [];
        // history.push("/");
    }else{
        orditems= glContext.ActiveOrder.order_lines.items;
    }

    const [activeOrder, setActiveOrder]=useState(glContext.ActiveOrder);    
    if(!activeOrder || !activeOrder.order_lines || !activeOrder.order_lines.items)
    {
        history.push("/");
    }
    const[refresh, setRefresh] = useState(false);
    const [items, setItems] = useState(glContext.ActiveOrder.order_lines.items);
    const [totalItems, settotalItems] = useState(0);
    const dirty = props.dirty;
    useEffect(()=>{
        setItems(glContext.ActiveOrder.order_lines.items);
        if(glContext && glContext.ActiveOrder && glContext.ActiveOrder.order_lines){
            setActiveOrder(glContext.ActiveOrder);
        }
    },[refresh, glContext.ActiveOrder]);
    return (
            <div className="row">
                <div className="col-md-5 orderDetailsLeft">
                    <OrdersDetailsFooter activeOrder={activeOrder} setActiveOrder={setActiveOrder} dirty={dirty} />
                    <div className="leftcontainer">
                        <div className="orderDetailsHead">
                           {(activeOrder.order_type==='eat-in') && <OrdersDetailsHeadEatin activeOrder={activeOrder} setActiveOrder={setActiveOrder} dirty={dirty}/>}
                           {(activeOrder.order_type==='waiting') && <OrdersDetailsHeadWaiting activeOrder={activeOrder} setActiveOrder={setActiveOrder}  dirty={dirty}/>}
                           {(activeOrder.order_type==='delivery') && <OrdersDetailsHeadDelivery activeOrder={activeOrder} setActiveOrder={setActiveOrder}  dirty={dirty}/>}
                           {(activeOrder.order_type==='collection') && <OrdersDetailsHeadCollection activeOrder={activeOrder} setActiveOrder={setActiveOrder} dirty={dirty}/>}
                        </div>
                        <div className="orderDetailsItems">
                            {(items)&&
                               Object.entries(items)
                                    .map(([key, value]) =>
                                    //{
                                        //.log(key);
                                        <OrdersDetailsItems key={key} refresh={refresh} setRefresh={setRefresh}  setItems={setItems} activeOrder={activeOrder} setActiveOrder={setActiveOrder}  itemKey={key} /*key={value.item_id}*/ data={value} settotalItems={settotalItems} currency={activeOrder.currency_symbol} />
                                   // }  
                                    )
                            }
                        </div>
                    </div>
                    {glContext &&glContext.ActiveOrder && glContext.ActiveOrder.order_lines && <PrintBody/>}
                    <iframe id="ifmcontentstoprint" style={{height: '0px', width: '0px', position: 'absolute'}}></iframe>
                </div>
                <div className="col-md-7 d-none d-md-block orderDetailsRight">
                {(activeOrder.origin !== 'online') && <Menu dirty={dirty} refresh={refresh} setRefresh={setRefresh} activeOrder={activeOrder} setActiveOrder={setActiveOrder}  setItems={setItems} settotalItems={settotalItems}/>}
                </div>
            </div>
    );
}

export default OrdersDetails;
